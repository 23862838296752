import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import Button from "react-bootstrap/Button";
import LanguageSelect from "~/components/LanguageSelect/LanguageSelect";
import styles from "./styles.module.scss";
import {FormattedMessage} from "react-intl";
import MatchingStrategySelect from "~/containers/components/MatchingStrategySelect";
import config from "~/config";

export default class MatchBlock extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        language: PropTypes.string.isRequired,
        allStrategies: PropTypes.object.isRequired,
        matchingStrategy: PropTypes.string,
        matchRequestHasChanged: PropTypes.bool.isRequired,
        matchActionLabel: PropTypes.string.isRequired,
        onLanguageChange: PropTypes.func.isRequired,
        onMatchingStrategyChange: PropTypes.func.isRequired,
        onMatch: PropTypes.func.isRequired,
    };

    render() {
        const {
            className,
            language,
            allStrategies,
            matchingStrategy,
            matchRequestHasChanged,
            matchActionLabel,
            onLanguageChange,
            onMatch,
            onMatchingStrategyChange,
        } = this.props;

        return (
            <div className={c(className, styles.main)} onClick={stopPropagation}>
                <LanguageSelect
                    value={language}
                    onChange={onLanguageChange}
                    variant="small"
                    className={styles.languageSelect}
                />
                {config("ui.enableMatchingStrategySelection") &&
                    Object.keys(allStrategies).length > 0 && (
                        <MatchingStrategySelect
                            selected={matchingStrategy}
                            allowEmpty={true}
                            onChange={onMatchingStrategyChange}
                            variant="small"
                            className={styles.matchingStrategySelect}
                        />
                    )}
                <Button
                    variant={matchRequestHasChanged ? "success" : "secondary"}
                    size="sm"
                    onClick={onMatch}
                    className={styles.matchButton}
                >
                    <FormattedMessage id={matchActionLabel} />
                </Button>
            </div>
        );
    }
}

function stopPropagation(e) {
    e.stopPropagation();
}
