import React from "react";
import PropTypes from "prop-types";
import ValidatedInput from "~/components/ValidatedInput";

export default class IntegerInput extends React.PureComponent {
    static propTypes = {
        value: PropTypes.number,
        defaultValue: PropTypes.number,
        className: PropTypes.string,
        onChange: PropTypes.func.isRequired,
    };

    render() {
        const {value, defaultValue, className, onChange} = this.props;

        return (
            <ValidatedInput
                value={value}
                defaultValue={defaultValue}
                valueToInput={valueToInput}
                inputToValue={inputToValue}
                isValidAsInput={isValidAsInput}
                isValidForConversion={isValidForConversion}
                className={className}
                onChange={onChange}
            />
        );
    }
}

const INPUT_REGEX = new RegExp(
    "^[0-9]*$"
);

const VALIDATION_REGEX = INPUT_REGEX;

function isValidAsInput(input) {
    return INPUT_REGEX.test(input);
}

function isValidForConversion(input) {
    return input !== "" && VALIDATION_REGEX.test(input);
}

function valueToInput(value) {
    return value !== undefined ? String(value) : "";
}

function inputToValue(input) {
    return parseFloat(input);
}
