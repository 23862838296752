import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import Table from "react-bootstrap/Table";
import styles from "./styles.module.scss";
import config from "~/config";
import ProgressBar from "~/components/ProgressBar";
import {FormattedMessage} from "react-intl";
import {RANGE_UNIT_TRANSLATIONS} from "~/util/ui-translations";
import {EnabledAspectsBlackWhiteList} from "~/util/enabled-aspects";
import {
    JOB_FUNCTIONS_GREEN,
    getAspectValueMatchedTo,
    MatchedTo,
    HARD_SKILLS_GREEN,
    getMatchedToMessageId,
} from "~/util/match";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export default class MatchQualityTable extends React.PureComponent {
    static propTypes = {
        details: PropTypes.object,
        otherAspects: PropTypes.object.isRequired,
        forwardLabel: PropTypes.node.isRequired,
        forwardSubLabel: PropTypes.node,
        reverseLabel: PropTypes.node.isRequired,
        hideForwardColumn: PropTypes.bool.isRequired,
        onForwardLabelClick: PropTypes.func,
        className: PropTypes.string,
    };

    static defaultProps = {
        otherAspects: {},
    };

    render() {
        const {
            forwardLabel,
            forwardSubLabel,
            reverseLabel,
            hideForwardColumn,
            onForwardLabelClick,
            className,
        } = this.props;

        return (
            <Table size="sm" className={c(className, styles.table)}>
                <thead>
                    <tr className={styles.noTopBorder}>
                        <th className={styles.aspectColumn}>
                            <FormattedMessage id="matchQuality.aspectColumn" />
                        </th>
                        {/*<th>*/}
                        {/*    <FormattedMessage id="matchQuality.matchProfile" />*/}
                        {/*</th>*/}

                        {!hideForwardColumn && (
                            <th
                                className={c({[styles.clickable]: onForwardLabelClick})}
                                onClick={onForwardLabelClick}
                            >
                                {forwardSubLabel && (
                                    <div className={styles.normalWeight}>{forwardSubLabel}</div>
                                )}
                                <div className={styles.ellipsis}>{forwardLabel}</div>
                            </th>
                        )}
                        <th>{reverseLabel}</th>
                    </tr>
                </thead>
                <tbody>{this.renderDetails()}</tbody>
            </Table>
        );
    }

    renderDetails() {
        return config("ui.aspectOrder")
            .filter(aspect => EnabledAspectsBlackWhiteList.allows(aspect))
            .map(aspect => this.renderDetailsForAspect(aspect));
    }

    renderDetailsForAspect(aspect) {
        const {details, otherAspects} = this.props;
        const itemsByScoreType = splitByScoreType(details[aspect]);
        itemsByScoreType.FORWARD = itemsByScoreType.FORWARD || [];
        itemsByScoreType.REVERSE = itemsByScoreType.REVERSE || [];

        if (itemsByScoreType.REVERSE.length === 0) {
            itemsByScoreType.REVERSE = convertAspectToItems(otherAspects[aspect]);
        }

        const rowCount = Math.max(
            itemsByScoreType.FORWARD.length,
            itemsByScoreType.REVERSE.length,
            1
        );
        const rows = [];

        for (let i = 0; i < rowCount; i++) {
            rows.push(
                this.renderItemRow(
                    i,
                    itemsByScoreType.FORWARD[i],
                    itemsByScoreType.REVERSE[i],
                    aspect,
                    i === 0
                )
            );
        }

        return rows;
    }

    renderItemRow(index, forwardItem, reverseItem, aspect, firstRow) {
        const {hideForwardColumn} = this.props;

        return (
            <tr key={`${aspect}:${index}`}>
                <td className={c({[styles.noTopBorder]: !firstRow})}>
                    {firstRow && (<FormattedMessage id={`aspect.${aspect}`} /> || aspect)}
                </td>
                {!hideForwardColumn && (
                    <td className={c({[styles.noTopBorder]: !firstRow})}>
                        {forwardItem && this.renderItem(aspect, forwardItem)}
                        {!forwardItem && firstRow && this.renderNotAvailable()}
                    </td>
                )}
                <td className={c({[styles.noTopBorder]: !firstRow})}>
                    {reverseItem && this.renderItem(aspect, reverseItem)}
                    {!reverseItem && firstRow && this.renderNotAvailable()}
                </td>
            </tr>
        );
    }

    renderNotAvailable() {
        return (
            <div className={c(styles.detailsItemRow, styles.notAvailable)}>
                <i className="far fa-question-circle" />
                <div className={styles.detailsItemLabel}>
                    <FormattedMessage id="matchQuality.notAvailable" />
                </div>
            </div>
        );
    }

    renderItem(aspect, item) {
        const staticOrUnmatchedReverse =
            item.isStatic || (!item.isStatic && !item.found && item.scoreType === "REVERSE");

        let classes = {
            "fa-check-circle": !item.isStatic && item.found,
            "fa-times-circle": !item.isStatic && !item.found && item.scoreType === "FORWARD",
            "fa-circle": staticOrUnmatchedReverse,
        };

        let matchedTo = undefined;

        if (aspect === "jobFunctions" && item.scoreType === "FORWARD") {
            matchedTo = getAspectValueMatchedTo(item, aspect);

            classes = {
                "fa-check-circle": !item.isStatic && JOB_FUNCTIONS_GREEN.includes(matchedTo),
                "fa-question-circle":
                    !item.isStatic &&
                    ![...JOB_FUNCTIONS_GREEN, MatchedTo.NOTHING].includes(matchedTo),
                "fa-times-circle": !item.isStatic && matchedTo === MatchedTo.NOTHING,
                "fa-circle": item.isStatic,
            };
        } else if (aspect === "hardSkills" && item.scoreType === "FORWARD") {
            matchedTo = getAspectValueMatchedTo(item, aspect);

            classes = {
                "fa-check-circle": !item.isStatic && HARD_SKILLS_GREEN.includes(matchedTo),
                "fa-question-circle":
                    !item.isStatic &&
                    ![...HARD_SKILLS_GREEN, MatchedTo.NOTHING].includes(matchedTo),
                "fa-times-circle": !item.isStatic && matchedTo === MatchedTo.NOTHING,
                "fa-circle": item.isStatic,
            };
        }

        return (
            <div key={item.label} className={styles.detailsItemRow}>
                {this.renderItemIcon(classes, aspect, matchedTo)}
                <div className={styles.detailsItemLabel}>
                    {TRANSLATED_ASPECTS.includes(aspect) ? (
                        <FormattedMessage
                            id={`${aspect}.${item.label}`}
                            defaultMessage={item.label}
                        />
                    ) : (
                        item.label
                    )}
                </div>
                {!staticOrUnmatchedReverse && (
                    <ProgressBar
                        className={styles.detailsProgress}
                        variant="success"
                        now={item.score}
                        min={0}
                        max={1}
                    />
                )}
            </div>
        );
    }

    renderItemIcon(classes, aspect, matchedTo) {
        if (!matchedTo || matchedTo === MatchedTo.NOTHING || matchedTo === MatchedTo.UNKNOWN) {
            return <i className={c("far", classes)} />;
        } else {
            const messageId = getMatchedToMessageId(matchedTo, aspect);

            return (
                <OverlayTrigger
                    placement="right"
                    delay={{show: 250, hide: 400}}
                    overlay={props => (
                        <Tooltip {...props} show={props.show.toString()}>
                            <FormattedMessage id={messageId} />
                        </Tooltip>
                    )}
                >
                    <i className={c("far", classes)} />
                </OverlayTrigger>
            );
        }
    }
}

const TRANSLATED_ASPECTS = ["contractTypes", "workingHours"];

function splitByScoreType(items) {
    items = items || [];
    const result = {};

    for (const item of items) {
        if (result[item.scoreType] === undefined) {
            result[item.scoreType] = [];
        }

        result[item.scoreType].push(item);
    }

    return result;
}

function convertAspectToItems(aspect) {
    if (!aspect) return [];

    switch (aspect.type) {
        case "concepts":
        case "keywords":
            return aspect.value.map(value => ({
                isStatic: true,
                label: value.label,
            }));

        case "locations":
            return aspect.value.map(value => ({
                isStatic: true,
                label: `${value.label} ≤ ${value.range}${RANGE_UNIT_TRANSLATIONS[value.rangeUnit] ||
                    ""}`,
            }));

        case "range":
            return aspect.value
                ? [
                      {
                          isStatic: true,
                          label: `${aspect.value.from} – ${aspect.value.to}`,
                      },
                  ]
                : [];
        case "float":
        case "integer":
            return aspect.value
                ? [
                      {
                          isStatic: true,
                          label: `${aspect.value.value}`,
                      },
                  ]
                : [];

        default:
            return [];
    }
}
