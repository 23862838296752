import Api from "~/api";
import config from "~/config";
import applyJobParseResults from "~/util/apply-job-parse-results";

export const UPDATE_JOB = "j2c-match/UPDATE_JOB";
export const UPDATE_JOB_PARSE_STATE = "j2c-match/UPDATE_JOB_PARSE_STATE";
export const SET_FURTHEST_STEP = "j2c-match/SET_FURTHEST_STEP";

export function parseJob(job) {
    return dispatch => {
        dispatch(updateJobParseState(config("enums.apiCallState.pending")));

        return Api.parseJob(job)
            .then(({response}) => {
                dispatch(
                    updateJob({
                        ...job,
                        matchProfile: applyJobParseResults(
                            job.matchProfile,
                            response.parsingAspects
                        ),
                    })
                );
                dispatch(updateJobParseState(config("enums.apiCallState.idle")));
            })
            .catch(e => {
                console.error(e);
                dispatch(updateJobParseState(config("enums.apiCallState.idle")));
            });
    };
}

export function updateJob(job) {
    return {
        type: UPDATE_JOB,
        job,
    };
}

function updateJobParseState(state) {
    return {
        type: UPDATE_JOB_PARSE_STATE,
        jobParseState: state,
    };
}

export function setFurthestStep(furthestStep) {
    return {
        type: SET_FURTHEST_STEP,
        furthestStep,
    };
}
