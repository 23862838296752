import React from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import SimplePagination from "~/components/SimplePagination";
import Candidate from "./Candidate";
import styles from "./candidate-list.module.scss";
import SimplePage from "~/components/SimplePage";
import {FormattedMessage} from "react-intl";
import config from "~/config";

/**
 * This class expects to be remounted when the ID parameter in match changes!
 * It only loads data once.
 */
export default class CandidateList extends React.PureComponent {
    static propTypes = {
        allCandidates: PropTypes.objectOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                description: PropTypes.string.isRequired,
            })
        ).isRequired,
        candidateOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
        page: PropTypes.number.isRequired,
        pageSize: PropTypes.number.isRequired,
        count: PropTypes.number,
        isFetching: PropTypes.bool.isRequired,
        history: PropTypes.object.isRequired,
        fetchCandidates: PropTypes.func.isRequired,
        setPage: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const {count, page, pageSize, fetchCandidates} = this.props;

        if (count === null) {
            fetchCandidates(page, pageSize);
        }
    }

    render() {
        const {page, pageSize, count} = this.props;

        return (
            <SimplePage fullWidth>
                <Row>
                    <Col>
                        <Table size="sm" className={styles.table}>
                            <thead>
                            <tr>
                                <th className={styles.jobFunctionColumn}>
                                    <FormattedMessage id="candidateList.nameColumn" />
                                </th>
                                <th className={styles.jobFunctionColumn}>
                                    <FormattedMessage id="candidateList.jobFunctionColumn" />
                                </th>
                                <th className={styles.locationsColumn}>
                                    <FormattedMessage id="candidateList.locationColumn" />
                                </th>
                                <th className={styles.actionsColumn}/>
                            </tr>
                            </thead>
                            <tbody>{this.renderCandidates()}</tbody>
                        </Table>
                        <SimplePagination
                            page={page}
                            totalItems={count || 0}
                            itemsPerPage={pageSize}
                            onChange={this.handlePageChange}
                        />
                    </Col>
                </Row>
            </SimplePage>
        );
    }

    renderCandidates() {
        const {allCandidates, candidateOrder, pageSize} = this.props;

        const result = [];
        const candidateIndex = config("features.candidateList.candidateIndex");

        for (let i = 0; i < pageSize; i++) {
            const candidate = allCandidates[candidateOrder[i]];

            if (candidate === undefined) {
                break;
            }

            result.push(<Candidate key={`candidate.${candidate.id}`} indexName={candidateIndex} candidate={candidate} />);
        }

        return result;
    }

    handlePageChange = page => {
        const {pageSize, fetchCandidates, setPage} = this.props;
        fetchCandidates(page * pageSize, pageSize);
        setPage(page);
    };
}
