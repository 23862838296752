import React from "react";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import c from "classnames";
import config from "~/config";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import styles from "./menu.module.scss";
import {auth0RedirectUri} from "~/util/auth0-provider";

export default class Menu extends React.PureComponent {
    static propTypes = {
        auth0Context: PropTypes.object,
        className: PropTypes.string,
    };

    render() {
        const {className} = this.props;

        return (
            <Navbar className={c(className, styles.menu)}>
                <Navbar.Brand>
                    <div className={styles.logoContainer}>
                        <div className={styles.logo} />
                    </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="menu-navbar-nav" />
                <Navbar.Collapse id="menu-navbar-nav">
                    {this.renderMainNav()}
                    {config("features.auth0.enabled", false) && (
                        <Nav className="ml-auto">
                            <Nav.Link onClick={this.logout}>Log out</Nav.Link>
                        </Nav>
                    )}
                </Navbar.Collapse>
            </Navbar>
        );
    }

    renderMainNav() {
        return (
            <Nav>
                {config("features.profileToJobs.enabled", false) && (
                    <NavLink to="/profile-to-jobs" activeClassName="active" className="nav-link">
                        <FormattedMessage id="menu.profileToJobs" />
                    </NavLink>
                )}
                {config("features.profileToCandidates.enabled", false) && (
                    <NavLink
                        to="/profile-to-candidates"
                        activeClassName="active"
                        className="nav-link"
                    >
                        <FormattedMessage id="menu.profileToCandidates" />
                    </NavLink>
                )}
                {config("features.jobToCandidates.enabled", false) && (
                    <NavLink to="/job-to-candidates" activeClassName="active" className="nav-link">
                        <FormattedMessage id="menu.jobToCandidates" />
                    </NavLink>
                )}
                {config("features.textMining.enabled", false) && (
                    <NavLink to="/text-mining" activeClassName="active" className="nav-link">
                        <FormattedMessage id="menu.textMining" />
                    </NavLink>
                )}
                {config("features.jobList.enabled", false) && (
                    <NavLink to="/jobs/list" activeClassName="active" className="nav-link">
                        <FormattedMessage id="menu.jobList" />
                    </NavLink>
                )}
                {config("features.candidateList.enabled", false) && (
                    <NavLink to="/candidates/list" activeClassName="active" className="nav-link">
                        <FormattedMessage id="menu.candidateList" />
                    </NavLink>
                )}
                {config("features.matchingStrategy.enabled", false) && (
                    <NavLink to="/matching-strategy" activeClassName="active" className="nav-link">
                        <FormattedMessage id="menu.matchingStrategy" />
                    </NavLink>
                )}
                {config("features.monitoring.enabled", false) && (
                    <NavLink to="/matching-test" activeClassName="active" className="nav-link">
                        <FormattedMessage id="menu.matchingTest" />
                    </NavLink>
                )}
            </Nav>
        );
    }

    logout = () => {
        this.props.auth0Context.logout({returnTo: auth0RedirectUri});
    };
}
