import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import styles from "./styles.module.scss";
import Spinner from "react-bootstrap/Spinner";

export default class Tab extends React.PureComponent {
    static propTypes = {
        title: PropTypes.node.isRequired,
        subtitle: PropTypes.node,
        processingMessage: PropTypes.node,
        isActive: PropTypes.bool.isRequired,
        isProcessing: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired,
    };

    render() {
        const {title, isActive, onClick} = this.props;

        return (
            <div className={c(styles.tab, {isActive})} onClick={onClick}>
                {title}
                {this.renderSubtitle()}
                {this.renderSpinner()}
            </div>
        );
    }

    renderSpinner() {
        const {processingMessage, isProcessing} = this.props;

        if (!isProcessing) {
            return null;
        }

        if (processingMessage) {
            return (
                <span className="ml-2 text-secondary">
                    <Spinner animation="grow" role="status" size="sm" /> {processingMessage}
                </span>
            );
        } else {
            return (
                <span className="ml-2 text-secondary">
                    <Spinner animation="grow" role="status" size="sm" />
                </span>
            );
        }
    }

    renderSubtitle() {
        const {subtitle} = this.props;

        if (!subtitle) {
            return null;
        }

        return <span className="ml-2 text-secondary">{subtitle}</span>;
    }
}
