import uuid from "uuid/v4";
import TEXT_MINING_INPUT from "!raw-loader!~/data/text-mining-input.txt"; // eslint-disable-line
import TEXT_MINING_DUMMY_RESULTS from "~/data/text-mining-dummy-results.json";
import {
    UPDATE_FETCHING,
    UPDATE_INPUT,
    UPDATE_RESULTS,
    UPDATE_NGRAMS_RESULTS,
} from "~/actions/text-mining";

const INITIAL_STATE = {
    input: TEXT_MINING_INPUT,
    results: TEXT_MINING_DUMMY_RESULTS,
    oneGrams: {},
    nGrams: {},
    fetching: false,
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case UPDATE_INPUT:
            return updateInput(state, action.input);

        case UPDATE_FETCHING:
            return updateFetching(state, action.fetching);

        case UPDATE_RESULTS:
            return updateResults(state, action.input, action.results);

        case UPDATE_NGRAMS_RESULTS:
            return updateNGramsResults(state, action.results);

        default:
            return state;
    }
}

function updateInput(state, input) {
    return {...state, input};
}

function updateFetching(state, fetching) {
    return {...state, fetching};
}

function updateResults(state, input, rawResults) {
    let foundComments = [];
    const results = {
        feedback: [],
        unmatched: [],
    };

    for (const result of rawResults) {
        const aspects = result.parsingAspects.map(parseAspect);
        results.feedback = results.feedback.concat(aspects);
        foundComments = foundComments.concat(takeAllComments(aspects));
    }

    results.unmatched = input.split(/\r?\n/).filter(comment => !foundComments.includes(comment));

    return {...state, results};
}

function updateNGramsResults(state, results) {
    const oneGrams = {};
    const nGrams = {};

    for (const collection of results) {
        for (const ngram of collection.nGrams) {
            if (collection.nrOfTokens === 1) {
                oneGrams[ngram.label] = ngram.freq;
            } else {
                nGrams[ngram.label] = ngram.freq;
            }
        }
    }

    return {...state, oneGrams, nGrams};
}

function parseAspect(aspect) {
    return {
        id: uuid(),
        aspectType: aspect.aspectType,
        label: aspect.label,
        competences: aspect.nestedAspects.map(parseCompetence),
    };
}

function parseCompetence(competence) {
    return {
        id: uuid(),
        aspectType: competence.aspectType,
        label: competence.label,
        subcategories: competence.nestedAspects.map(parseSubcategory),
    };
}

function parseSubcategory(subcategory) {
    return {
        id: uuid(),
        aspectType: subcategory.aspectType,
        label: subcategory.label,
        matches: subcategory.mentions.map(parseMatch),
    };
}

function parseMatch(match) {
    return {
        id: uuid(),
        sentence: match.matchedText,
        context: match.originalComment,
    };
}

function takeAllComments(aspects) {
    const comments = [];

    for (const aspect of aspects) {
        for (const competence of aspect.competences) {
            for (const subcategory of competence.subcategories) {
                for (const match of subcategory.matches) {
                    comments.push(match.context);
                }
            }
        }
    }

    return comments;
}
