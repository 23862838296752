export default function updateById(values, id, value) {
    const nextValues = [...values];

    for (let i = 0; i < nextValues.length; i++) {
        if (nextValues[i].id === id) {
            nextValues[i] = {
                ...nextValues[i],
                ...value,
            };
        }
    }

    return nextValues;
}