import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import styles from "./styles.module.scss";

export default class CollapsibleSection extends React.PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            expanded: true,
        };
    }

    render() {
        const {expanded} = this.state;
        const {children} = this.props;

        return (
            <div className={styles.main}>
                {this.renderHeader()}
                {expanded && (
                    <div className={styles.content}>
                        {children}
                    </div>
                )}
            </div>
        );
    }

    renderHeader() {
        const {title} = this.props;
        const {expanded} = this.state;

        return (
            <div className={c(styles.header, {expanded})} onClick={this.handleToggleExanded}>
                <i className={c("fas", {"fa-caret-right": !expanded, "fa-caret-down": expanded})} />
                {title}
            </div>
        );
    }

    handleToggleExanded = () => {
        this.setState(state => ({
            expanded: !state.expanded,
        }));
    };
}