import React from "react";
import PropTypes from "prop-types";
import config from "~/config";
import {Auth0Context} from "~/util/auth0-provider";
import Menu from "~/components/Menu";
import styles from "./styles.module.scss";
import BasicSpinner from "./BasicSpinner";

export default class BasicPageLayout extends React.PureComponent {
    static propTypes = {
        children: PropTypes.any,
        isLoading: PropTypes.bool.isRequired,
        additionalMenuContent: PropTypes.node,
    };

    static defaultProps = {
        isLoading: false,
    };

    render() {
        const {isLoading, children, additionalMenuContent} = this.props;
        const hideMenu = config("ui.hideMenu", false);

        return (
            <div className={styles.page}>
                {!hideMenu && (
                    <div className={styles.menu}>
                        <Auth0Context.Consumer>
                            {auth0Context => <Menu auth0Context={auth0Context} />}
                        </Auth0Context.Consumer>
                        {additionalMenuContent}
                    </div>
                )}
                {!hideMenu && (
                    <div className={styles.fixedMenu}>
                        <Auth0Context.Consumer>
                            {auth0Context => <Menu auth0Context={auth0Context} />}
                        </Auth0Context.Consumer>
                        {additionalMenuContent}
                    </div>
                )}
                <div className={styles.content}>{isLoading ? <BasicSpinner /> : children}</div>
            </div>
        );
    }
}
