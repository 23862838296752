import React from "react";
import Tab from "./Tab";
import styles from "./styles.module.scss";

export default class TabBar extends React.PureComponent {
    render() {
        return <div className={styles.tabBar}>{this.props.children}</div>;
    }
}

TabBar.Tab = Tab;
