import {connect} from "react-redux";
import ErrorMessages from "~/components/ErrorMessages";
import {closeFirstErrorMessage} from "~/actions/ui";

function mapStateToProps(state) {
    return {
        pendingErrorMessages: state.ui.pendingErrorMessages,
    };
}

const mapDispatchToProps = {
    closeFirstErrorMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorMessages);
