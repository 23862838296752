import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import styles from "./main-category.module.scss";

export default class Match extends React.PureComponent {
    static propTypes = {
        match: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            permanentContext: false,
            hoverContext: false,
        };
    }

    showingContext() {
        const {permanentContext, hoverContext} = this.state;
        return permanentContext || hoverContext;
    }

    render() {
        return (
            <React.Fragment>
                <td className={styles.matchColumn}>
                    {this.showingContext() ? this.renderSentenceInContext() : this.renderSentence()}
                </td>
                <td className={styles.contextToggleColumn}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                    onClick={this.handleToggleContext}
                >
                    {this.renderActions()}
                </td>
            </React.Fragment>
        );
    }

    renderSentence() {
        const {match} = this.props;

        return match.sentence;
    }

    renderSentenceInContext() {
        const {match} = this.props;
        let start = match.context.search(new RegExp(`(?:\\b)${escapeRegExp(match.sentence)}(?:\\b)`));
        if (start < 0) start = match.context.indexOf(match.sentence);

        return (
            <span>
                <span className={styles.context}>{match.context.substring(0, start)}</span>
                <span>{match.sentence}</span>
                <span className={styles.context}>
                    {match.context.substring(start + match.sentence.length)}
                </span>
            </span>
        );
    }

    renderActions() {
        return (
            <i
                className={c("fas", "fa-expand", styles.toggleIcon, {
                    active: this.showingContext(),
                })}
            />
        );
    }

    handleMouseEnter = () => {
        this.setState({hoverContext: true});
    };

    handleMouseLeave = () => {
        this.setState({hoverContext: false});
    };

    handleToggleContext = () => {
        this.setState(state => ({
            permanentContext: !state.permanentContext,
        }));
    };
}

function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}