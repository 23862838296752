import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import Table from "react-bootstrap/Table";
import styles from "./styles.module.scss";

export default class WorkExperiencesRow extends React.PureComponent {
    static propTypes = {
        value: PropTypes.object,
    };

    render() {
        const {value} = this.props;

        return (
            <Table size="sm" bordered>
                <tbody>
                    {(!!value.dateFrom || !!value.dateTo) && (
                        <tr>
                            <td className={styles.label}>
                                <FormattedMessage id="workExperience.dateFrom" />
                            </td>
                            <td className={styles.halfWidth}>{value.dateFrom || "—"}</td>
                            <td className={styles.label}>
                                <FormattedMessage id="workExperience.dateTo" />
                            </td>
                            <td className={styles.halfWidth}>{value.dateTo || "—"}</td>
                        </tr>
                    )}
                    {!!value.company && (
                        <tr>
                            <td className={styles.label}>
                                <FormattedMessage id="workExperience.company" />
                            </td>
                            <td colSpan={3} className={styles.fullWidth}>
                                {value.company}
                            </td>
                        </tr>
                    )}
                    {!!value.title && (
                        <tr>
                            <td className={styles.label}>
                                <FormattedMessage id="workExperience.title" />
                            </td>
                            <td colSpan={3} className={styles.fullWidth}>
                                {value.title}
                            </td>
                        </tr>
                    )}
                    {!!value.text && (
                        <tr>
                            <td colSpan={4}>{value.text}</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        );
    }
}
