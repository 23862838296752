import React from "react";
import PropTypes from "prop-types";
import config from "~/config";
import Select from "~/components/Select";
import {injectIntl} from "react-intl";

class LanguageSelect extends React.PureComponent {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        value: PropTypes.string,
        variant: PropTypes.oneOf(["normal", "small"]),
        className: PropTypes.string,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        variant: "normal",
    };

    constructor(props) {
        super(props);

        this.options = config("languages").map(language => ({
            value: language,
            label: props.intl.formatMessage({id: `language.${language}`}),
        }));
    }

    render() {
        const {value, className, variant} = this.props;

        return (
            <Select
                selected={value}
                options={this.options}
                onChange={this.handleChange}
                className={className}
                variant={variant}
            />
        );
    }

    handleChange = nextSelected => {
        this.props.onChange(nextSelected.value);
    };
}

export default injectIntl(LanguageSelect, {forwardRef: true});
