import React from "react";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Select from "~/components/Select";
import Button from "react-bootstrap/Button";
import {FormattedMessage} from "react-intl";

class AddStrategyDialog extends React.PureComponent {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        defaultStrategies: PropTypes.object.isRequired,
        onAdd: PropTypes.func.isRequired,
        onHide: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            name: "",
            profileType: Object.keys(props.defaultStrategies)[0],
        };

        this.nameInputRef = React.createRef();
    }

    componentDidMount() {
        this.nameInputRef.current.focus();
    }

    render() {
        const {intl, onHide} = this.props;
        const {name, profileType} = this.state;

        return (
            <Modal show={true} onHide={onHide}>
                <Modal.Header closeButton={true}>
                    <Modal.Title>
                        <FormattedMessage id="matchingStrategy.addTitle" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="formStrategyName">
                        <Form.Label>
                            <FormattedMessage id="matchingStrategy.name" />
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={intl.formatMessage({
                                id: "matchingStrategy.name.placeholder",
                            })}
                            value={name}
                            onChange={this.handleNameChange}
                            ref={this.nameInputRef}
                        />
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlSelect2">
                        <Form.Label>
                            <FormattedMessage id="matchingStrategy.template" />
                        </Form.Label>
                        <Select
                            selected={profileType}
                            options={this.defaultStrategyOptions()}
                            onChange={this.handleSelectProfileType}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="success"
                        onClick={this.handleAddClick}
                        disabled={name.length === 0}
                    >
                        <FormattedMessage id="matchingStrategy.add" />
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    defaultStrategyOptions() {
        const {defaultStrategies} = this.props;

        return Object.keys(defaultStrategies).map(profileType => ({
            label: profileType,
            value: profileType,
        }));
    }

    handleNameChange = e => {
        this.setState({name: e.target.value});
    };

    handleSelectProfileType = option => {
        this.setState({profileType: option.value});
    };

    handleAddClick = () => {
        const {onAdd} = this.props;
        const {name, profileType} = this.state;
        onAdd(name, profileType);
    };
}

export default injectIntl(AddStrategyDialog, {forwardRef: true});
