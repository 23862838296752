import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import Select from "~/components/Select";
import FormControl from "react-bootstrap/FormControl";
import styles from "./styles.module.scss";

const COMPARISONS = {
    contains: {
        label: {id: "predicateEditor.contains"},
        hasInput: true,
        toFilter: (property, input) => ({match: {[property]: input}}),
    },
    doesntContain: {
        label: {id: "predicateEditor.doesntContain"},
        hasInput: true,
        toFilter: (property, input) => ({bool: {must_not: {match: {[property]: input}}}}),
    },
    containsPhrase: {
        label: {id: "predicateEditor.containsPhrase"},
        hasInput: true,
        toFilter: (property, input) => ({match_phrase: {[property]: input}}),
    },
    doesntContainPhrase: {
        label: {id: "predicateEditor.doesntContainPhrase"},
        hasInput: true,
        toFilter: (property, input) => ({bool: {must_not: {match_phrase: {[property]: input}}}}),
    },
    isEmpty: {
        label: {id: "predicateEditor.isEmpty"},
        hasInput: false,
        toFilter: property => ({bool: {must_not: {exists: {field: property}}}}),
    },
    isNotEmpty: {
        label: {id: "predicateEditor.isNotEmpty"},
        hasInput: false,
        toFilter: property => ({bool: {must: {exists: {field: property}}}}),
    },
};

export default class FullTextProperty extends React.PureComponent {
    static propTypes = {
        data: PropTypes.shape({
            comparison: PropTypes.oneOf(Object.keys(COMPARISONS)),
            input: PropTypes.string.isRequired,
        }).isRequired,
        onChange: PropTypes.func.isRequired,
    };

    static defaultValue() {
        return {
            comparison: "contains",
            input: "",
        };
    }

    static toFilter(property, data) {
        const comparisonInfo = COMPARISONS[data.comparison];

        if (data.input !== "" || !comparisonInfo.hasInput) {
            return comparisonInfo.toFilter(property, data.input);
        } else {
            return undefined;
        }
    }

    render() {
        const {data} = this.props;
        const comparisonInfo = COMPARISONS[data.comparison];

        return (
            <React.Fragment>
                <Select
                    className={c(styles.givePriority, styles.comparisonSelect)}
                    selected={data.comparison}
                    onChange={this.handleComparisonChange}
                    options={COMPARISON_OPTIONS}
                    variant="extra-small"
                />
                {comparisonInfo.hasInput && (
                    <FormControl
                        className={c(styles.givePriority, styles.input)}
                        value={data.input}
                        onChange={this.handleInputChange}
                        size="sm"
                    />
                )}
            </React.Fragment>
        );
    }

    handleComparisonChange = option => {
        const {data, onChange} = this.props;
        const nextData = {...data, comparison: option.value};
        onChange(nextData);
    };

    handleInputChange = e => {
        const {data, onChange} = this.props;
        const nextData = {...data, input: e.target.value};
        onChange(nextData);
    };
}

const COMPARISON_OPTIONS = Object.keys(COMPARISONS).map(key => ({
    label: COMPARISONS[key].label,
    value: key,
}));
