import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import ListGroup from "react-bootstrap/ListGroup";
import styles from "./styles.module.scss";
import Checkbox from "~/components/Checkbox";

export default class Filter extends React.PureComponent {
    static propTypes = {
        label: PropTypes.string.isRequired,
        value: PropTypes.bool.isRequired,
        disabled: PropTypes.bool.isRequired,
        className: PropTypes.string,
        childrenBelow: PropTypes.bool.isRequired,
        children: PropTypes.any,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        childrenBelow: false,
        disabled: false,
    };

    render() {
        const {value, disabled, label, className, childrenBelow, children} = this.props;

        return (
            <div className={c("card", styles.main, className)}>
                <ListGroup variant="flush">
                    <ListGroup.Item className={styles.item}>
                        <Checkbox checked={value} disabled={disabled} onChange={this.props.onChange} />
                        <div className={childrenBelow ? styles.childrenBelow : styles.childrenInline}>
                            <div className={styles.label}>{label}</div>
                            <div className={styles.children}>{children}</div>
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            </div>
        );
    }
}
