import React from "react";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import c from "classnames";
import config from "~/config";
import FilterValuePropType from "~/prop-types/filter-value";
import Filter from "../Filter";
import styles from "./styles.module.scss";
import {getValuesForFilter} from "~/util/filter-values";
import Select from "~/components/Select";
import FloatInput from "~/components/FloatInput";

class NumberComparisonFilter extends React.PureComponent {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        value: FilterValuePropType.isRequired,
        filterName: PropTypes.string.isRequired,
        className: PropTypes.string,
        onChange: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            values: [],
        };

        getValuesForFilter(props.filterName).then(values => {
            this.setState({values});
        });
    }

    render() {
        const {value, filterName, className} = this.props;
        const label = config(`filters.${filterName}.label`);

        return (
            <Filter
                label={label}
                value={value.enabled}
                disabled={!value.enabled && value.value.comparand === null}
                className={className}
                onChange={this.handleEnabledChange}
            >
                <div className={styles.wrapper}>
                    <Select
                        selected={value.value.comparison}
                        onChange={this.handleComparisonChange}
                        options={COMPARISON_OPTIONS}
                        className={c(styles.select, "form-control", "form-control-sm", "ml-2")}
                        unstyled
                    />
                    <FloatInput
                        value={value.value.comparand}
                        onChange={this.handleComparandChange}
                        className={c(styles.input, "form-control", "form-control-sm", "ml-2")}
                        allowNull
                    />
                </div>
            </Filter>
        );
    }

    handleEnabledChange = nextEnabled => {
        const {value, filterName, onChange} = this.props;

        onChange(filterName, {
            ...value,
            enabled: nextEnabled,
        });
    };

    handleComparisonChange = option => {
        const {value, filterName, onChange} = this.props;

        onChange(filterName, {
            ...value,
            value: {
                ...value.value,
                comparison: option.value,
            },
        });
    };

    handleComparandChange = nextComparand => {
        const {value, filterName, onChange} = this.props;

        onChange(filterName, {
            ...value,
            value: {
                ...value.value,
                comparand: nextComparand,
            },
            enabled: nextComparand !== null,
        });
    };
}

const COMPARISON_OPTIONS = [
    {value: "lt", label: "<"},
    {value: "lte", label: "<="},
    {value: "gte", label: ">="},
    {value: "gt", label: ">"},
];

export default injectIntl(NumberComparisonFilter, {forwardRef: true});
