export function childPath(path, key) {
    return path ? `${path}.${key}` : key;
}

export function betterTypeOf(value) {
    if (value === null) {
        return "null";
    } else {
        return typeof value;
    }
}