export function createEmptyForSchema(schema) {
    return completeForSchema(schema, undefined);
}

export function completeForSchema(schema, value) {
    return completeForSchemaInternal(schema, value);
}

function completeForSchemaInternal(schema, value) {
    if (schema.enum !== undefined) {
        let result = completeEnumForSchema(schema, value);
        if (result !== undefined) return result;
    }

    switch (schema.type) {
        case "Object":
            return completeObjectForSchema(schema, value);

        case "Array":
            return completeArrayForSchema(schema, value);

        case "float":
        case "double":
        case "int":
            return completeNumberForSchema(schema, value);

        case "boolean":
            return completeBooleanForSchema(schema, value);

        case "String":
            return completeStringForSchema(schema, value);

        default:
            console.error("Unknown schema type: " + schema.type);
            return value;
    }
}

function completeObjectForSchema(schema, value) {
    const completedValue = (value !== undefined ? {...value} : {});
    const properties = schema.properties || {};

    for (const propertyName in properties) {
        completedValue[propertyName] = completeForSchemaInternal(properties[propertyName], completedValue[propertyName]);
    }

    return completedValue;
}

function completeArrayForSchema(schema, value) {
    return value !== undefined ? value : [];
}

function completeNumberForSchema(schema, value) {
    return value !== undefined ? value : 0;
}

function completeBooleanForSchema(schema, value) {
    return value !== undefined ? value : false;
}

function completeStringForSchema(schema, value) {
    return value !== undefined ? value : "";
}

function completeEnumForSchema(schema, value) {
    return value !== undefined ? value : schema.enum[0];
}