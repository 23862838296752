import React from "react";
import PropTypes from "prop-types";
import config from "~/config";
import PredicateEditor from "~/components/PredicateEditor";
import FilterEditor from "~/components/FilterEditor";

export default class FiltersForIndex extends React.PureComponent {
    static propTypes = {
        index: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        onCustomFiltersPredicateChange: PropTypes.func.isRequired,
        onFiltersChange: PropTypes.func.isRequired,
    };

    render() {
        const {index, state} = this.props;
        const propertyDefinitions = config(`customFilters.${index.name}`, undefined);

        return (
            <React.Fragment>
                {index.customFiltersEnabled && propertyDefinitions && (
                    <PredicateEditor
                        value={state.nextRequest.customFiltersPredicate}
                        maximumDepth={config("ui.customFiltersMaximumDepth")}
                        propertyDefinitions={propertyDefinitions}
                        onChange={this.handleCustomFiltersPredicateChange}
                    />
                )}
                {index.filters.length > 0 && (
                    <FilterEditor
                        value={state.nextRequest.filters}
                        availableFilters={index.filters}
                        onChange={this.handleFiltersChange}
                    />
                )}
            </React.Fragment>
        );
    }

    handleCustomFiltersPredicateChange = nextPredicate => {
        const {index, onCustomFiltersPredicateChange} = this.props;
        onCustomFiltersPredicateChange(index, nextPredicate);
    };

    handleFiltersChange = nextFilters => {
        const {index, onFiltersChange} = this.props;
        onFiltersChange(index, nextFilters);
    };
}
