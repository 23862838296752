import PropTypes from "prop-types";
import config from "~/config";

export default PropTypes.shape({
    type: PropTypes.oneOf(["concepts", "keywords", "locations", "range", "float", "integer"])
        .isRequired,
    weight: PropTypes.number.isRequired,
    required: PropTypes.bool.isRequired,
    custom: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                weight: PropTypes.number.isRequired,
                required: PropTypes.bool.isRequired,
                label: PropTypes.string.isRequired,
            })
        ),
        PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                weight: PropTypes.number.isRequired,
                required: PropTypes.bool.isRequired,
                label: PropTypes.string.isRequired,
                range: PropTypes.number.isRequired,
                rangeUnit: PropTypes.oneOf(config("enums.rangeUnit")).isRequired,
            })
        ),
        PropTypes.shape({
            weight: PropTypes.number.isRequired,
            required: PropTypes.bool.isRequired,
            from: PropTypes.number.isRequired,
            to: PropTypes.number.isRequired,
        }),
        PropTypes.shape({
            weight: PropTypes.number.isRequired,
            required: PropTypes.bool.isRequired,
            value: PropTypes.number.isRequired,
        }),
    ]),
});
