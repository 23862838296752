import {createSelector} from "reselect";

export function createSelectors() {
    const matchesSelector = results => results.matches;
    const pagesSelector = results => results.pages;
    const displayedPageSelector = results => results.displayedPage;

    const displayedMatchIdsSelector = createSelector(
        pagesSelector,
        displayedPageSelector,
        (pages, displayedPage) => {
            return pages[displayedPage] ? pages[displayedPage].ids : [];
        }
    );

    const displayedMatchesSelector = createSelector(
        matchesSelector,
        displayedMatchIdsSelector,
        (matches, displayedMatchIds) => {
            return displayedMatchIds.map(id => matches[id]);
        }
    );

    return {
        displayedMatches: displayedMatchesSelector,
    };
}
