import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import uuid from "uuid/v4";
import config from "~/config";
import styles from "./styles.module.scss";
import Range from "~/components/Range";
import {FormattedMessage} from "react-intl";

export default class Weight extends React.PureComponent {
    static propTypes = {
        value: PropTypes.number.isRequired,
        isEditing: PropTypes.bool.isRequired,
        color: PropTypes.string,
        className: PropTypes.string,
        onChange: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.uuid = uuid();
    }

    render() {
        const {value, isEditing, className, color, onChange} = this.props;

        return (
            <div
                className={c("weight-and-required", styles.weightAndRequired, className)}
            >
                <Range
                    value={value}
                    color={color}
                    disabled={!isEditing}
                    min={config("ui.weight.min")}
                    max={config("ui.weight.max")}
                    step={config("ui.weight.step")}
                    labels={[
                        <FormattedMessage id="weight.notImportant" />,
                        undefined,
                        <FormattedMessage id="weight.important" />,
                    ]}
                    onChange={onChange}
                />
            </div>
        );
    }
}
