import * as immutable from "object-path-immutable";

import {
    APPLY_MATCH_DATA,
    SELECT_FEEDBACK_FROM_TYPE,
    SELECT_FEEDBACK_KEY,
    SELECT_FEEDBACK_TO_TYPE,
    SELECT_PROFILE,
    SET_ALL_STRATEGIES,
    SET_FEEDBACK_IDS,
    SET_MATCHING,
    SET_PROFILES,
    SET_STATUS,
    SET_FEEDBACK_DECISION,
    SELECT_MATCHING_STRATEGY,
} from "~/actions/monitoring";
import ProfileType from "~/enums/ProfileType";

export const Status = {
    UNINITIALIZED: "UNINITIALIZED",
    INITIALIZING: "INITIALIZING",
    INITIALIZED: "INITIALIZED",
};

const INITIAL_STATE = {
    status: Status.UNINITIALIZED,
    isMatching: false,
    feedbackIds: [],
    selection: {
        feedbackKey: null,
        feedbackFromType: ProfileType.JOB,
        feedbackToType: ProfileType.JOB,
        matchingStrategy: null,
        profile: null,
    },
    allStrategies: {},
    profilesOrder: [],
    allProfiles: {},
    matchResults: {},
    globalStats: {
        precision: undefined,
        recall: undefined,
    },
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_STATUS:
            return setStatus(state, action.status);

        case SET_MATCHING:
            return setMatching(state, action.isMatching);

        case SET_ALL_STRATEGIES:
            return setAllStrategies(state, action.allStrategies);

        case SET_PROFILES:
            return setProfiles(state, action.profiles);

        case SET_FEEDBACK_IDS:
            return setFeedbackIds(state, action.feedbackIds);

        case SELECT_FEEDBACK_FROM_TYPE:
            return selectFeedbackFromType(state, action.id);

        case SELECT_FEEDBACK_TO_TYPE:
            return selectFeedbackToType(state, action.id);

        case SELECT_FEEDBACK_KEY:
            return selectFeedbackKey(state, action.id);

        case SELECT_MATCHING_STRATEGY:
            return selectMatchingStrategy(state, action.id);

        case SELECT_PROFILE:
            return selectProfile(state, action.id);

        case SET_FEEDBACK_DECISION:
            return setFeedbackDecision(state, action.profileId, action.feedbackId, action.decision);

        case APPLY_MATCH_DATA:
            return applyMatchData(state, action.data);

        default:
            return state;
    }
}

function setStatus(state, status) {
    return {...state, status};
}

function setMatching(state, isMatching) {
    return {...state, isMatching};
}

function setAllStrategies(state, allStrategies) {
    const nextState = {...state, allStrategies, selection: {...state.selection}};

    if (
        nextState.selection.strategy !== null &&
        allStrategies[nextState.selection.strategy] === undefined
    ) {
        nextState.selection.strategy = null;
    }

    if (nextState.selection.strategy === null && Object.keys(allStrategies).length > 0) {
        nextState.selection.strategy = Object.keys(allStrategies)[0];
    }

    return nextState;
}

function setProfiles(state, profiles) {
    const profilesOrder = [];
    const allProfiles = {};

    for (const profile of profiles) {
        allProfiles[profile.id] = profile;
        profilesOrder.push(profile.id);
    }

    return {...state, profilesOrder, allProfiles, selection: {...state.selection, profile: null}};
}

function setFeedbackIds(state, feedbackIds) {
    const nextState = {...state, feedbackIds, selection: {...state.selection}};

    if (feedbackIds.length > 0) {
        nextState.selection.feedbackKey = feedbackIds[0].key;
    } else {
        nextState.selection.feedbackKey = null;
    }

    return nextState;
}

function selectFeedbackFromType(state, id) {
    return {...state, selection: {...state.selection, feedbackFromType: id}};
}

function selectFeedbackToType(state, id) {
    return {...state, selection: {...state.selection, feedbackToType: id}};
}

function selectFeedbackKey(state, id) {
    return {...state, selection: {...state.selection, feedbackKey: id}};
}

function selectMatchingStrategy(state, id) {
    return {...state, selection: {...state.selection, matchingStrategy: id}};
}

function selectProfile(state, id) {
    return {...state, selection: {...state.selection, profile: id}};
}

function setFeedbackDecision(state, profileId, feedbackId, decision) {
    const nextMatchFeedback = [...state.allProfiles[profileId].matchFeedback];
    const index = nextMatchFeedback.findIndex(feedback => feedback.matchedProfileId === feedbackId);

    if (index >= 0) {
        nextMatchFeedback[index] = {...nextMatchFeedback[index], decision};
    }

    return immutable.set(state, ["allProfiles", profileId, "matchFeedback"], nextMatchFeedback);
}

function applyMatchData(state, data) {
    const matchResults = {};

    for (let i = 0; i < data.matchResults.length; i++) {
        const id = state.profilesOrder[i];
        matchResults[id] = data.matchResults[i];
    }

    const useStats = data.matchResults.length > 0;
    const globalStats = {
        precision: useStats ? data.precision : undefined,
        recall: useStats ? data.recall : undefined,
    };

    return {...state, matchResults, globalStats};
}
