export const RANGE_UNIT_TRANSLATIONS = {
    KILOMETER: "km",
    METER: "m",
    MILE: "mi",
};

export function messageExists(intl, id) {
    return !!intl.messages[id];
}

export function formatMessageMaybe(intl, settings, parameters, defaultResult) {
    const exists = messageExists(intl, settings.id);
    return exists ? intl.formatMessage(settings, parameters) : defaultResult;
}
