import {connect} from "react-redux";
import MatchingStrategy from "~/pages/MatchingStrategy";
import {initialize, updateStrategy, removeStrategy, saveChanges} from "~/actions/matching-strategy";

function mapStateToProps(state) {
    return {
        status: state.matchingStrategy.status,
        saveStatus: state.matchingStrategy.saveStatus,
        schema: state.matchingStrategy.schema,
        allStrategies: state.matchingStrategy.allStrategies,
        defaultStrategies: state.matchingStrategy.defaultStrategies,
    };
}

const mapDispatchToProps = {
    initialize,
    updateStrategy,
    removeStrategy,
    saveChanges,
};

export default connect(mapStateToProps, mapDispatchToProps)(MatchingStrategy);
