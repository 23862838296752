import config from "~/config";

export function createEmptyAspectByType(type) {
    return {
        type,
        weight: config("ui.weight.default"),
        required: false,
        value: createEmptyValue(type),
    }
}

export function createEmptyAspectByName(name) {
    const aspectConfig = config(`aspects.${name}`);

    if (aspectConfig !== undefined) {
        return createEmptyAspectByType(aspectConfig.type);
    } else {
        return null;
    }
}

function createEmptyValue(type) {
    switch (type) {
        case "concepts":
        case "keywords":
        case "locations":
            return [];

        default:
        case "range":
            return null;
    }
}