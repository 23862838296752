import Api from "~/api";
import config from "~/config";

export const UPDATE_INPUT = "textMining/UPDATE_INPUT";
export const UPDATE_FETCHING = "textMining/UPDATE_FETCHING";
export const UPDATE_RESULTS = "textMining/UPDATE_RESULTS";
export const UPDATE_NGRAMS_RESULTS = "textMining/UPDATE_NGRAMS_RESULTS";

export function updateInput(input) {
    return {
        type: UPDATE_INPUT,
        input,
    };
}

export function textMiningParse(input) {
    return dispatch => {
        dispatch(updateFetching(true));

        const parser = config("features.textMining.parser");
        const inputType = config("features.textMining.inputType", "csv");

        Promise.all([Api.textMiningParse(parser, input, inputType), Api.textMiningNGrams(input)])
            .then(([results, nGramsResults]) => {
                dispatch(updateResults(input, results));
                dispatch(updateNGramsResults(input, nGramsResults));
                dispatch(updateFetching(false));
            })
            .catch(e => {
                console.error(e);
                dispatch(updateFetching(false));
            });
    };
}

function updateFetching(fetching) {
    return {
        type: UPDATE_FETCHING,
        fetching,
    };
}

export function updateResults(input, results) {
    return {
        type: UPDATE_RESULTS,
        input,
        results,
    };
}

export function updateNGramsResults(input, results) {
    return {
        type: UPDATE_NGRAMS_RESULTS,
        input,
        results,
    };
}
