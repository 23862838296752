import React from "react";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import {getProfileDisplayId, getProfileLabel} from "~/pages/MatchingTest/util";
import MatchDecisionTypeSwitch from "~/components/OkNokSwitch/MatchDecisionTypeSwitch";
import {percentageString} from "~/util/misc";
import ResultStatus from "~/enums/ResultStatus";

class Row extends React.PureComponent {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        value: PropTypes.object.isRequired,
        onDecisionChange: PropTypes.func.isRequired,
    };

    render() {
        const {value, intl} = this.props;

        return (
            <tr onClick={this.handleClick}>
                <td>
                    <div>{getProfileLabel(value.matchProfile)}</div>
                    <small className="text-muted d-block text-monospace">
                        {getProfileDisplayId(value.matchProfile)}
                    </small>
                </td>
                <td>
                    {value.resultStatus !== undefined
                        ? ResultStatus.translate(intl, value.resultStatus)
                        : "—"}
                </td>
                <td>{value.score !== undefined ? percentageString(value.score) : "—"}</td>
                <td>
                    <MatchDecisionTypeSwitch
                        value={value.decision}
                        onChange={this.handleDecisionChange}
                    />
                </td>
            </tr>
        );
    }

    handleDecisionChange = decision => {
        const {value, onDecisionChange} = this.props;
        onDecisionChange(value.matchedProfileId, decision);
    };
}

export default injectIntl(Row, {forwardRef: true});
