import {getFeedbackIds, getProfiles} from "~/actions/monitoring";

export const callGetProfiles = store => next => action => {
    const prevSelection = store.getState().monitoring.selection;
    const result = next(action);
    const nextSelection = store.getState().monitoring.selection;

    if (
        prevSelection.feedbackFromType !== nextSelection.feedbackFromType ||
        prevSelection.feedbackToType !== nextSelection.feedbackToType ||
        prevSelection.feedbackKey !== nextSelection.feedbackKey
    ) {
        store.dispatch(getProfiles());
    }

    return result;
};

export const callGetFeedbackIds = store => next => action => {
    const prevSelection = store.getState().monitoring.selection;
    const result = next(action);
    const nextSelection = store.getState().monitoring.selection;

    if (prevSelection.feedbackFromType !== nextSelection.feedbackFromType) {
        store.dispatch(getFeedbackIds());
    }

    return result;
};
