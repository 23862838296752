import React from "react";
import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";
import styles from "./styles.module.scss";
import Row from "./Row";
import {getProfileDisplayId, getProfileLabel} from "~/pages/MatchingTest/util";
import {FormattedMessage} from "react-intl";

export default class DetailsTable extends React.PureComponent {
    static propTypes = {
        profile: PropTypes.object.isRequired,
        onDecisionChange: PropTypes.func.isRequired,
    };

    render() {
        const {profile} = this.props;

        return (
            <Table bordered striped className={styles.table}>
                <thead>
                    <tr>
                        <th>
                            <div>{getProfileLabel(profile)}</div>
                            <small className="text-muted d-block text-monospace">
                                <b>{getProfileDisplayId(profile)}</b>
                            </small>
                        </th>
                        <th className={styles.detailStatusColumn}>
                            <FormattedMessage id="matchingTest.details.header.status" />
                        </th>
                        <th className={styles.fixedWidthColumn}>
                            <FormattedMessage id="matchingTest.details.header.score" />
                        </th>
                        <th className={styles.fixedWidthColumn}>
                            <FormattedMessage id="matchingTest.details.header.decision" />
                        </th>
                    </tr>
                </thead>
                <tbody>{this.renderRows()}</tbody>
            </Table>
        );
    }

    renderRows() {
        const {profile, onDecisionChange} = this.props;

        return profile.matchFeedback.map(feedback => (
            <Row
                key={feedback.matchedProfileId}
                value={feedback}
                onDecisionChange={onDecisionChange}
            />
        ));
    }
}
