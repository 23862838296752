import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import styles from "./styles.module.scss";

export default class OkNokSwitch extends React.PureComponent {
    static propTypes = {
        value: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            hovering: null,
        };
    }

    render() {
        const {value} = this.props;
        const {hovering} = this.state;

        return (
            <ButtonGroup>
                <Button
                    variant={value === true && hovering === false ? "outline-success" : "success"}
                    className={c({[styles.inactive]: value !== true})}
                    onMouseEnter={this.handleTrueEnter}
                    onMouseLeave={this.handleLeave}
                    onClick={this.handleTrueClick}
                >
                    OK
                </Button>
                <Button
                    variant={value === false && hovering === true ? "outline-danger" : "danger"}
                    className={c({[styles.inactive]: value !== false})}
                    onMouseEnter={this.handleFalseEnter}
                    onMouseLeave={this.handleLeave}
                    onClick={this.handleFalseClick}
                >
                    NOK
                </Button>
            </ButtonGroup>
        );
    }

    handleTrueEnter = () => {
        this.setState({hovering: true});
    };

    handleFalseEnter = () => {
        this.setState({hovering: false});
    };

    handleLeave = () => {
        this.setState({hovering: null});
    };

    handleTrueClick = e => {
        const {value, onChange} = this.props;
        onChange(value !== true ? true : null);
        e.target.blur();
    };

    handleFalseClick = e => {
        const {value, onChange} = this.props;
        onChange(value !== false ? false : null);
        e.target.blur();
    };
}
