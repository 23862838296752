import Validator from "~/config/validation/validators/Validator";
import ValidationError from "~/config/validation/ValidationError";
import {betterTypeOf} from "~/config/validation/validators/util";

export default class IntegerValidator extends Validator {
    /**
     * @param {any} value
     * @param {string|undefined} path
     * @return {number} an integer
     */
    validate(value, path = undefined) {
        if (value === undefined && this.defaultValue !== undefined) {
            return this.validate(this.defaultValue, path);
        } else if (Number.isInteger(value)) {
            return value;
        } else {
            throw new ValidationError(path, `Expected an integer, but got ${betterTypeOf(value)}`);
        }
    }
}
