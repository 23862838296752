import {removeUndefinedValues} from "~/util/misc";
import {convertMatchRequestToApi} from "~/util/convert-to-api";
import {convertCandidateFromApi, convertJobFromApi} from "~/util/convert-from-api";

export default function addMatchingCalls(Client) {
    Client.prototype.matchProfileToCandidates = function({
        candidateIndex,
        index,
        matchRequest,
        from,
        size,
        language,
        sortModeGroup,
        scoreType,
        expansionType,
        matchingStrategy,
        conversionPreProcessor,
        _source,
    }) {
        const theIndex = index || candidateIndex;

        return this.post(
            `/hrmatching/match2candidates/${theIndex}`,
            removeUndefinedValues({
                ...convertMatchRequestToApi(matchRequest, theIndex, language, sortModeGroup),
                from,
                size,
                _source,
                queryParams: {
                    scoreType,
                    expansionType,
                },
                userLanguage: language,
            }),
            removeUndefinedValues({
                strategy: matchingStrategy !== null ? matchingStrategy : undefined,
            })
        ).then(({response}) => {
            const metadata = {
                from: response.from,
                size: response.size,
                count: response.count,
            };

            const matches = response.matches.map(x =>
                convertCandidateApiMatch(conversionPreProcessor, x)
            );

            return {matches, metadata};
        });
    };

    Client.prototype.downloadProfileToCandidates = function({
        candidateIndex,
        matchRequest,
        language,
        sortModeGroup,
        scoreType,
        expansionType,
        matchingStrategy,
        exportType,
        exportSize,
    }) {
        return this.post(
            `/hrmatching/match2candidates/${candidateIndex}/${exportType}`,
            removeUndefinedValues({
                ...convertMatchRequestToApi(matchRequest, candidateIndex, language, sortModeGroup),
                from: 0,
                size: exportSize,
                queryParams: {
                    scoreType,
                    expansionType,
                },
                userLanguage: language,
            }),
            removeUndefinedValues({
                strategy: matchingStrategy !== null ? matchingStrategy : undefined,
            }),
            {},
            true,
            "arraybuffer"
        );
    };

    Client.prototype.matchProfileToJobs = function({
        jobIndex,
        index,
        matchRequest,
        from,
        size,
        language,
        sortModeGroup,
        scoreType,
        expansionType,
        matchingStrategy,
        conversionPreProcessor,
        _source,
    }) {
        const theIndex = index || jobIndex;

        return this.post(
            `/hrmatching/match2jobs/${theIndex}`,
            removeUndefinedValues({
                ...convertMatchRequestToApi(matchRequest, theIndex, language, sortModeGroup),
                from,
                size,
                _source,
                queryParams: {
                    scoreType,
                    expansionType,
                },
                userLanguage: language,
            }),
            removeUndefinedValues({
                strategy: matchingStrategy !== null ? matchingStrategy : undefined,
            })
        ).then(({response}) => {
            const metadata = {
                from: response.from,
                size: response.size,
                count: response.count,
            };

            const matches = response.matches.map(x =>
                convertJobApiMatch(conversionPreProcessor, x)
            );

            return {matches, metadata};
        });
    };

    Client.prototype.matchDetailsForJobToCandidate = function(
        sourceIndex,
        sourceId,
        targetIndex,
        targetId
    ) {
        return this.post("/hrmatching/match2candidates/details", {
            sourceIndex,
            sourceId,
            targetIndex,
            targetId,
        }).then(({response}) => response.matches.map(match => convertCandidateApiMatch(undefined, match)));
    };
}

function convertCandidateApiMatch(preprocessor, match) {
    return {
        id: match.id,
        score: match.score,
        candidate: maybePreprocess(preprocessor, convertCandidateFromApi, match),
        details: match.details,
        explanations: match.explanations,
    };
}

function convertJobApiMatch(preprocessor, match) {
    return {
        id: match.id,
        score: match.score,
        job: maybePreprocess(preprocessor, convertJobFromApi, match),
        details: match.details,
        explanations: match.explanations,
    };
}

function maybePreprocess(preprocessor, converter, match) {
    if (!match.document) {
        return undefined;
    } else if (preprocessor) {
        return converter(preprocessor(match.document));
    } else {
        return converter(match.document);
    }
}
