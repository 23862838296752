import Api from "~/api";
import config from "~/config";

export const SET_FETCHING = "candidates/SET_FETCHING";
export const ADD_RESULTS = "candidates/ADD_RESULTS";
export const SET_PAGE = "candidates/SET_PAGE";

export function fetchCandidates(from, size) {
    return dispatch => {
        const candidateIndex = config("features.candidateList.candidateIndex");
        dispatch(setFetching(true));

        return Api.fetchCandidates(candidateIndex, from, size)
            .then(({metadata, candidates}) => {
                dispatch(setFetching(false));
                dispatch(addResults(metadata, candidates));
            })
            .catch(e => {
                dispatch(setFetching(false));
                throw e;
            });
    };
}

function setFetching(isFetching) {
    return {
        type: SET_FETCHING,
        isFetching,
    };
}

function addResults(metadata, candidates) {
    return {
        type: ADD_RESULTS,
        count: metadata.count,
        candidates,
    }
}

export function setPage(page) {
    return {
        type: SET_PAGE,
        page,
    }
}