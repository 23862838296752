import config from "~/config";

export function getDefaultSort(sortModeGroup) {
    if (!sortModeGroup) {
        return undefined;
    }

    if (!config(`sort.${sortModeGroup}`) || config(`sort.${sortModeGroup}`).length < 1) {
        console.error(`Sort mode group ${sortModeGroup} does not exist or has no sort modes`);
        return undefined;
    }

    for (const sortMode of config(`sort.${sortModeGroup}`)) {
        if (sortMode.default) {
            return sortMode.id;
        }
    }

    return config(`sort.${sortModeGroup}.0`).id;
}

export function getSortMode(sortModeGroup, id) {
    if (!sortModeGroup) {
        return undefined;
    }

    if (!config(`sort.${sortModeGroup}`) || config(`sort.${sortModeGroup}`).length < 1) {
        console.error(`Sort mode group ${sortModeGroup} does not exist or has no sort modes`);
        return undefined;
    }

    for (const sortMode of config(`sort.${sortModeGroup}`)) {
        if (sortMode.id === id) {
            return sortMode;
        }
    }

    return undefined;
}