import React from "react";
import PropTypes from "prop-types";
import BottomScrollListener from "react-bottom-scroll-listener";
import c from "classnames";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import ProgressBar from "react-bootstrap/ProgressBar";
import WordCloud from "~/components/WordCloud";
import CollapsibleSection from "~/components/text-mining/CollapsibleSection";
import styles from "./styles.module.scss";
import SimplePage from "~/components/SimplePage";
import Breadcrumbs from "~/components/text-mining/Breadcrumbs";
import {FormattedMessage} from "react-intl";

export default class TextMiningNGrams extends React.PureComponent {
    static propTypes = {
        oneGrams: PropTypes.objectOf(PropTypes.number).isRequired,
        oneGramsFrequencyMax: PropTypes.number.isRequired,
        oneGramsOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
        nGrams: PropTypes.objectOf(PropTypes.number).isRequired,
        nGramsFrequencyMax: PropTypes.number.isRequired,
        nGramsOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
        worldCloudData: PropTypes.arrayOf(
            PropTypes.shape({
                text: PropTypes.string.isRequired,
                value: PropTypes.number.isRequired,
            })
        ).isRequired,
        fetching: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            amountVisible: CHUNK_SIZE,
        };
    }

    render() {
        const {worldCloudData, fetching} = this.props;

        return (
            <SimplePage subMenu={() => <Breadcrumbs />} isLoading={fetching}>
                <Row>
                    <Col>
                        <CollapsibleSection title="Word cloud">
                            <WordCloud
                                data={worldCloudData}
                                fontSizeMapper={this.fontSizeMapper}
                                rotate={this.rotationMapper}
                                width={720}
                                height={240}
                            />
                        </CollapsibleSection>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BottomScrollListener offset={200} onBottom={this.showMore} />
                        <Table className={styles.table}>
                            <thead>
                                <tr>
                                    <th>
                                        <FormattedMessage id="textMining.1GramsTitle" />
                                    </th>
                                    <th className={styles.freqColumn} />
                                    <th className={styles.borderLeft}>
                                        <FormattedMessage id="textMining.nGramsTitle" />
                                    </th>
                                    <th className={styles.freqColumn} />
                                </tr>
                            </thead>
                            {this.renderNGrams()}
                        </Table>
                    </Col>
                </Row>
            </SimplePage>
        );
    }

    renderNGrams() {
        const {amountVisible} = this.state;
        const {
            oneGrams,
            oneGramsFrequencyMax,
            oneGramsOrder,
            nGrams,
            nGramsFrequencyMax,
            nGramsOrder,
        } = this.props;
        const amountShown = Math.min(
            amountVisible,
            Math.max(oneGramsOrder.length, nGramsOrder.length)
        );
        const result = [];

        for (let i = 0; i < amountShown; i++) {
            result.push(
                <tr key={i}>
                    {oneGramsOrder[i] !== undefined &&
                        this.renderNGram(oneGrams, oneGramsFrequencyMax, oneGramsOrder[i], false)}
                    {nGramsOrder[i] !== undefined &&
                        this.renderNGram(nGrams, nGramsFrequencyMax, nGramsOrder[i], true)}
                </tr>
            );
        }

        return <tbody>{result}</tbody>;
    }

    renderNGram(nGrams, frequencySum, nGram, borderLeft) {
        return (
            <React.Fragment>
                <td className={c({[styles.borderLeft]: borderLeft})}>
                    {nGram} ({nGrams[nGram]})
                </td>
                <td className={styles.freqColumn}>
                    <ProgressBar
                        variant="success"
                        now={nGrams[nGram] / frequencySum}
                        min={0}
                        max={1}
                    />
                </td>
            </React.Fragment>
        );
    }

    showMore = () => {
        this.setState((state, props) => {
            const {amountVisible} = state;
            const {oneGramsOrder, nGramsOrder} = props;
            const maximum = Math.max(oneGramsOrder.length, nGramsOrder.length);

            return {
                amountVisible: Math.min(maximum, amountVisible + CHUNK_SIZE),
            };
        });
    };

    fontSizeMapper = word => {
        const {oneGramsFrequencyMax} = this.props;
        const percentage = word.value / oneGramsFrequencyMax;

        return (percentage / 1.5 + 1 / 3) * 40;
    };

    rotationMapper = word => {
        const {oneGramsFrequencyMax} = this.props;
        const percentage = word.value / oneGramsFrequencyMax;

        return 45 * (1 - percentage) * (word.randomDirection ? 1 : -1);
    };
}

const CHUNK_SIZE = 100;
