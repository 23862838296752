import {removeUndefinedValues} from "~/util/misc";

export default function buildQuery(params = {}, includeQuestionMark = false) {
    let query = Object.keys(removeUndefinedValues(params))
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join("&");

    if (includeQuestionMark && query) {
        query = "?" + query;
    }

    return query;
}
