import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import styles from "./styles.module.scss";
import {FormattedMessage} from "react-intl";

export default class Title extends React.PureComponent {
    static propTypes = {
        value: PropTypes.string.isRequired,
        isEditing: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isEditing: false,
    };

    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
    }

    focus() {
        this.inputRef.current.focus();
    }

    render() {
        return this.props.isEditing ? this.renderEditMode() : this.renderDisplayMode();
    }

    renderDisplayMode() {
        const {value} = this.props;
        return <h2>{value}</h2>;
    }

    renderEditMode() {
        const {value} = this.props;
        return (
            <React.Fragment>
                <h5>
                    <FormattedMessage id="jobEditor.title" />
                </h5>
                <input
                    value={value}
                    onChange={this.handleChange}
                    className={c("form-control", styles.titleInput)}
                    ref={this.inputRef}
                />
            </React.Fragment>
        );
    }

    handleChange = e => {
        const {onChange} = this.props;
        onChange(e.target.value);
    };
}
