import React from "react";
import PropTypes from "prop-types";
import memoizeOne from "memoize-one";
import {parsePhoneNumberFromString} from "libphonenumber-js";
import config from "~/config";

export default class PhoneNumber extends React.PureComponent {
    static propTypes = {
        value: PropTypes.string.isRequired,
        onlyButtons: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        onlyButtons: false,
    };

    constructor(props) {
        super(props);

        this.parsePhoneNumber = memoizeOne(value => {
            return parsePhoneNumberFromString(value, config("ui.phoneNumberDefaultCountry"));
        });
    }

    render() {
        const {value, onlyButtons} = this.props;
        const phoneNumber = this.parsePhoneNumber(value);
        const usedNumber = phoneNumber !== undefined ? phoneNumber.number : value;

        return (
            <span>
                {!onlyButtons && value}
                <a
                    href={`${config("ui.phoneNumberProtocol")}:${usedNumber}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-sm btn-secondary ml-2"
                    onClick={stopPropagation}
                >
                    <i className="fas fa-phone" />
                </a>
                <a
                    href={`https://api.whatsapp.com/send?phone=${usedNumber}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-sm btn-secondary ml-1"
                    onClick={stopPropagation}
                >
                    <i className="fab fa-whatsapp" />
                </a>
            </span>
        );
    }
}

function stopPropagation(e) {
    e.stopPropagation();
}
