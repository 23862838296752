import Validators from "~/config/validation/validators/Validators";
import Auth0Definition from "~/config/definitions/features/Auth0Definition";
import MatchingPageDefinition from "~/config/definitions/features/MatchingPageDefinition";

export default Validators.shape({
    description: "Configuration for various UI features",
    shape: {
        auth0: Auth0Definition,
        profileToJobs: MatchingPageDefinition,
        profileToCandidates: MatchingPageDefinition,
        jobToCandidates: MatchingPageDefinition,
    },
});
