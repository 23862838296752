export default class Validator {
    constructor(description, defaultValue) {
        if (this.constructor === Validator) {
            throw new Error("Can't instantiate Validator directly, use a subclass instead.");
        }

        this.description = description;
        this.defaultValue = defaultValue;
    }

    /**
     * @param {any} value
     * @param {string|undefined} path
     * @return {any}
     */
    validate(value, path) {
        throw new Error("validate() not implemented!");
    }

    get allowsUndefined() {
        // Implemented in AllowsUndefinedValidator
        return undefined;
    }

    get allowsNull() {
        // Implemented in AllowsNullValidator
        return undefined;
    }
}
