import React from "react";
import PropTypes from "prop-types";
import {Switch, Route, Redirect} from "react-router-dom";
import JobToCandidatesInput from "~/containers/pages/job-to-candidates/JobToCandidatesInput";
import JobToCandidatesMatch from "~/containers/pages/job-to-candidates/JobToCandidatesMatch";

export default class JobToCandidates extends React.PureComponent {
    static propTypes = {
        furthestStep: PropTypes.number.isRequired,
    };

    render() {
        const {match, furthestStep} = this.props;

        return (
            <Switch>
                <Route path={`${match.path}/input`} component={JobToCandidatesInput} />
                {furthestStep >= 2 && <Route path={`${match.path}/match`} component={JobToCandidatesMatch} />}
                <Redirect from={`${match.path}`} to={`${match.path}/input`} />
            </Switch>
        );
    }
}
