import config from "~/config";

export function initializeLocalization() {
    const locale = config("ui.locale");
    const messages = config(`i18n.messages.${locale}`);

    if (!Intl.PluralRules) {
        require("@formatjs/intl-pluralrules/polyfill");
        require("@formatjs/intl-pluralrules/dist/locale-data/nl");
        require("@formatjs/intl-pluralrules/dist/locale-data/en");
    }

    return {locale, messages};
}
