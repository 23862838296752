import urlJoin from "url-join";
import buildQuery from "~/util/build-query";

/**
 * Returns an abortable API call, which is a Promise with an extra .abort() function.
 *
 * @param method
 * @param url
 * @param queryParameters
 * @param body
 * @param headers
 * @param tokenPromise
 * @param dontDecode
 * @param responseType
 * @returns {Promise}
 */
export default function call(
    method,
    url,
    queryParameters = {},
    body = null,
    headers = {},
    tokenPromise = Promise.resolve(),
    dontDecode = false,
    responseType = null
) {
    const request = new XMLHttpRequest();

    // Create a Promise to handle the result
    const promise = new Promise(async (resolve, reject) => {
        // Create request URL
        const queryString = buildQuery(queryParameters, true);
        const finalUrl = urlJoin(url, queryString);
        request.open(method, finalUrl);

        // Set headers
        for (const name in headers) {
            request.setRequestHeader(name, headers[name]);
        }

        // Await token
        const token = await tokenPromise;

        if (token !== undefined) {
            request.setRequestHeader("Authorization", `Bearer ${token}`);
        }

        // Set response type if defined
        if (responseType) {
            request.responseType = responseType;
        }

        // Register event handlers
        request.onabort = () => {
            reject({
                request,
                status: 499,
                statusText: "Client Closed Request",
                responseText: "",
            });
        };

        request.onerror = () => {
            reject({
                request,
                status: request.status,
                statusText: request.statusText,
                responseText: request.responseText,
            });
        };

        request.onload = () => {
            if (request.status >= 200 && request.status < 300) {
                if (
                    !dontDecode &&
                    (request.responseType === "json" ||
                        request.getResponseHeader("Content-Type") === "application/json")
                ) {
                    resolve({request, response: JSON.parse(request.response)});
                } else {
                    resolve({request, response: request.response});
                }
            } else {
                reject({
                    request,
                    status: request.status,
                    statusText: request.statusText,
                    responseText: request.responseText,
                });
            }
        };

        // Execute request
        if (body !== null) {
            if (headers["Content-Type"] === undefined) {
                request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
                request.send(JSON.stringify(body));
            } else {
                request.send(body);
            }
        } else {
            request.send();
        }
    });

    promise.abort = function() {
        request.abort();
    };

    return preserveAbort(promise);
}

// It would be better to rewrite abortableCall() to return both a promise and an abort call, but this means rewriting a
// lot of client code and there is no time right now.
function preserveAbort(promise) {
    const originalThen = promise.then.bind(promise);
    const originalCatch = promise.catch.bind(promise);
    const originalFinally = promise.finally.bind(promise);

    promise.then = function(onFulfilled, onRejected) {
        const resultingPromise = originalThen(onFulfilled, onRejected);
        resultingPromise.abort = this.abort;
        return preserveAbort(resultingPromise);
    };

    promise.catch = function(onRejected) {
        const resultingPromise = originalCatch(onRejected);
        resultingPromise.abort = this.abort;
        return preserveAbort(resultingPromise);
    };

    promise.finally = function(onFinally) {
        const resultingPromise = originalFinally(onFinally);
        resultingPromise.abort = this.abort;
        return preserveAbort(resultingPromise);
    };

    return promise;
}
