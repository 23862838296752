import React from "react";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import ListGroup from "react-bootstrap/ListGroup";
import config from "~/config";
import NumberInput from "~/components/NumberInput";
import RangeUnitSelect from "~/components/RangeUnitSelect";
import {RANGE_UNIT_TRANSLATIONS} from "~/util/ui-translations";
import styles from "./styles.module.scss";
import Foco from "react-foco";

class Location extends React.PureComponent {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        value: PropTypes.shape({
            id: PropTypes.string.isRequired,
            weight: PropTypes.number.isRequired,
            required: PropTypes.bool.isRequired,
            label: PropTypes.string.isRequired,
            range: PropTypes.number,
            rangeUnit: PropTypes.oneOf(config("enums.rangeUnit")).isRequired,
        }).isRequired,
        isEditing: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        onDelete: PropTypes.func,
    };

    static defaultProps = {
        placeholder: "",
    };

    constructor(props) {
        super(props);

        this.labelInputRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isEditing && !this.props.isEditing) {
            this.normalizeValue();
        }
    }

    componentWillUnmount() {
        this.normalizeValue();
    }

    focus() {
        if (this.labelInputRef.current) {
            this.labelInputRef.current.focus();
        }
    }

    render() {
        const {isEditing} = this.props;

        return (
            <ListGroup.Item className={styles.listItem}>
                <Foco
                    onFocusOutside={this.normalizeValue}
                    onClickOutside={this.normalizeValue}
                    className={styles.foco}
                    component="div"
                >
                    {isEditing ? this.renderValueEdit() : this.renderValueDisplay()}
                </Foco>
            </ListGroup.Item>
        );
    }

    renderValueDisplay() {
        const {value} = this.props;

        return (
            <span className={styles.description}>
                {value.label}, within {value.range} {RANGE_UNIT_TRANSLATIONS[value.rangeUnit]}
            </span>
        );
    }

    renderValueEdit() {
        const {intl, value} = this.props;

        return (
            <React.Fragment>
                <input
                    value={value.label}
                    placeholder={intl.formatMessage({id: "placeholder.location"})}
                    className={styles.locationInput}
                    onChange={this.handleLabelChange}
                    ref={this.labelInputRef}
                />
                <span>{" ≤ "}</span>
                <NumberInput
                    value={value.range}
                    placeholder={intl.formatMessage({id: "placeholder.distance"})}
                    className={styles.distanceInput}
                    onChange={this.handleRangeChange}
                />
                <RangeUnitSelect
                    value={value.rangeUnit}
                    className={styles.unitSelect}
                    onChange={this.handleRangeUnitChange}
                />
            </React.Fragment>
        );
    }

    handleLabelChange = e => {
        const {value, onChange} = this.props;
        onChange(value.id, {...value, label: e.target.value});
    };

    handleRangeChange = range => {
        const {value, onChange} = this.props;
        onChange(value.id, {...value, range});
    };

    handleRangeUnitChange = rangeUnit => {
        const {value, onChange} = this.props;
        onChange(value.id, {...value, rangeUnit});
    };

    normalizeValue = () => {
        const {value, onDelete} = this.props;

        if (onDelete) {
            if (value.label === "" || value.range === undefined) {
                onDelete(value.id);
            }
        } else {
            if (value.label !== "" && value.range === undefined) {
                this.handleRangeChange(config("ui.defaultRange"));
            }
        }
    };
}

export default injectIntl(Location, {forwardRef: true});
