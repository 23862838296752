import React from "react";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import config from "~/config";
import FilterValuePropType from "~/prop-types/filter-value";
import Filter from "../Filter";
import Option from "./Option";
import {getValuesForFilter} from "~/util/filter-values";

class MultipleTermFilter extends React.PureComponent {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        value: FilterValuePropType.isRequired,
        filterName: PropTypes.string.isRequired,
        className: PropTypes.string,
        onChange: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            values: [],
        };

        getValuesForFilter(props.filterName).then(values => {
            this.setState({values});
        });
    }

    render() {
        const {intl, value, filterName, className} = this.props;
        const label = config(`filters.${filterName}.label`);

        return (
            <Filter
                label={intl.formatMessage({id: "multipleTermFilter.label"}, {label})}
                value={value.enabled}
                childrenBelow={true}
                className={className}
                onChange={this.handleEnabledChange}
            >
                {this.renderOptions()}
            </Filter>
        );
    }

    renderOptions() {
        const {values} = this.state;

        return values.map(value => (
            <Option
                key={value.value}
                value={value.value}
                label={value.label}
                checked={this.isOptionChecked(value.value)}
                onChange={this.handleOptionChange}
            />
        ));
    }

    isOptionChecked(value) {
        return this.props.value.value.includes(value);
    }

    handleEnabledChange = nextEnabled => {
        const {value, filterName, onChange} = this.props;

        onChange(filterName, {
            ...value,
            enabled: nextEnabled,
        });
    };

    handleOptionChange = (optionValue, checked) => {
        const {value, filterName, onChange} = this.props;

        let nextValue = [...value.value];

        if (checked && !nextValue.includes(optionValue)) {
            nextValue.push(optionValue)
        } else if (!checked && nextValue.includes(optionValue)) {
            nextValue = nextValue.filter(v => v !== optionValue)
        }

        onChange(filterName, {
            ...value,
            enabled: nextValue.length > 0,
            value: nextValue,
        });
    };
}

export default injectIntl(MultipleTermFilter, {forwardRef: true});
