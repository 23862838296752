const TMB_DEBUG_UTIL = {};

export function registerTmbDebugUtil(name, callback) {
    Object.defineProperty(TMB_DEBUG_UTIL, name, {
        configurable: true,
        get: callback,
    });
}

window.tmb = TMB_DEBUG_UTIL;
