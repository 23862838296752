import React from "react";
import PropTypes from "prop-types";
import baseStyles from "../styles.module.scss";
import WorkExperience from "./WorkExperience";

export default class WorkExperiencesRow extends React.PureComponent {
    static propTypes = {
        matches: PropTypes.array,
        getMatchDisplayValue: PropTypes.func.isRequired,
    };

    render() {
        return (
            <tr>
                <td className={baseStyles.stickyColumn} />
                {this.renderMatches()}
            </tr>
        );
    }

    renderMatches() {
        const {matches} = this.props;
        return matches.map((match, index) => (
            <td key={index} className={baseStyles.matchColumn}>
                {this.renderMatch(match)}
            </td>
        ));
    }

    renderMatch(match) {
        const {getMatchDisplayValue} = this.props;
        const workExperiences = getMatchDisplayValue(match, "workExperiences");
        const workExperiencesSorted = [...workExperiences].sort(compareWorkExperiences);

        return workExperiencesSorted.map(workExperience => (
            <WorkExperience key={workExperience.id} value={workExperience} />
        ));
    }
}

function compareWorkExperiences(a, b) {
    if (a.dateFrom === null && b.dateFrom === null) {
        return 0;
    } else if (a.dateFrom === null) {
        return 1;
    } else if (b.dateFrom === null) {
        return -1;
    } else {
        return b.dateFrom.localeCompare(a.dateFrom);
    }
}
