import uuid from "uuid/v4";
import config from "~/config";
import {EnabledAspectsBlackWhiteList} from "~/util/enabled-aspects";

export function importProfile(profile) {
    const completedProfile = {
        ...profile,
        aspects: {...profile.aspects},
    };

    const allAspects = Object.keys(config("aspects"));

    for (const name of EnabledAspectsBlackWhiteList.apply(allAspects)) {
        if (completedProfile.aspects[name] === undefined) {
            completedProfile.aspects[name] = config(`aspects.${name}`);
        }
    }

    for (const name in completedProfile.aspects) {
        const aspect = completedProfile.aspects[name];

        if (["concepts", "keywords"].includes(aspect.type)) {
            completedProfile.aspects[name] = {
                ...aspect,
                value: addAutoGeneratedIds(aspect.value),
            };
        }
    }

    return completedProfile;
}

function addAutoGeneratedIds(array) {
    if (array === undefined) {
        return;
    }

    const result = [];

    for (const item of array) {
        result.push({...item, id: uuid()});
    }

    return result;
}

export function mergeAspectValues(base, other) {
    const result = {...base, aspects: {...base.aspects}};

    for (const aspect in base.aspects) {
        if (other.aspects[aspect] === undefined) continue;

        try {
            switch (aspect) {
                case "jobFunctions":
                case "hardSkills":
                case "languages":
                case "softSkills":
                case "jobCategories":
                case "contractTypes":
                case "workingHours":
                case "seniority":
                default:
                    result.aspects[aspect] = {
                        ...base.aspects[aspect],
                        value: mergeValues(base.aspects[aspect].value, other.aspects[aspect].value),
                    };
                    break;

                case "locations":
                    result.aspects[aspect] = {
                        ...base.aspects[aspect],
                        value: other.aspects[aspect].value.length ? other.aspects[aspect].value : base.aspects[aspect].value,
                    };
                    break;

                case "wage":
                    result.aspects[aspect] = {
                        ...base.aspects[aspect],
                        value: other.aspects[aspect].value ? other.aspects[aspect].value : base.aspects[aspect].value,
                    };
                    break;
            }
        } catch (e) {
            console.error(aspect, e);
        }
    }

    return result;
}

function mergeValues(base, other) {
    const baseLowerCase = base.map(x => x.label.toLowerCase());
    return base.concat(other.filter(x => !baseLowerCase.includes(x.label.toLowerCase())));
}
