import React from "react";
import PropTypes from "prop-types";
import {HashRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import config from "~/config";
import JobList from "~/containers/pages/JobList";
import CandidateList from "~/containers/pages/CandidateList";
import ProfileToCandidates from "~/containers/pages/ProfileToCandidates";
import ProfileToJobs from "~/containers/pages/ProfileToJobs";
import TextMining from "~/pages/text-mining/TextMining";
import JobToCandidates from "~/containers/pages/job-to-candidates/JobToCandidates";
import MatchingStrategy from "~/containers/pages/MatchingStrategy";
import AppLoading from "~/components/AppLoading";
import MatchingTest from "~/containers/pages/MatchingTest";
import StandaloneMatchDetails from "~/containers/pages/StandaloneMatchDetails";
import ErrorMessages from "~/containers/components/ErrorMessages";

export default class App extends React.PureComponent {
    static propTypes = {
        auth0Context: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            requestedLogin: false,
        };
    }

    componentDidMount() {
        this.loginIfNeeded();
    }

    componentDidUpdate() {
        this.loginIfNeeded();
    }

    loginIfNeeded() {
        if (config("features.auth0.enabled", false)) {
            const {loading, isAuthenticated, loginWithRedirect} = this.props.auth0Context;
            const {requestedLogin} = this.state;

            if (!loading && !isAuthenticated && !requestedLogin) {
                this.setState({requestedLogin: true});
                loginWithRedirect({someCustomOption: "hi"});
            }
        }
    }

    render() {
        const {auth0Context} = this.props;

        if (config("features.auth0.enabled", false)) {
            const {loading, isAuthenticated} = auth0Context;

            if (loading) {
                return <AppLoading />;
            } else if (!isAuthenticated) {
                return <AppLoading label="Redirecting to login..." />;
            }
        }

        return (
            <React.Fragment>
                <Router>
                    <Switch>
                        <Route
                            exact
                            path="/profile-to-candidates"
                            component={ProfileToCandidates}
                        />
                        <Route
                            path="/profile-to-candidates/:type/:index/:id"
                            component={ProfileToCandidates}
                        />
                        <Route
                            path="/profile-to-candidates/:type/:id"
                            component={ProfileToCandidates}
                        />
                        <Route path="/profile-to-candidates/:id" component={ProfileToCandidates} />
                        <Route exact path="/profile-to-jobs" component={ProfileToJobs} />
                        <Route path="/profile-to-jobs/:type/:index/:id" component={ProfileToJobs} />
                        <Route path="/profile-to-jobs/:type/:id" component={ProfileToJobs} />
                        <Route path="/profile-to-jobs/:id" component={ProfileToJobs} />
                        <Route path="/job-to-candidates" component={JobToCandidates} />
                        <Route path="/text-mining" component={TextMining} />
                        <Route path="/jobs/list" component={JobList} />
                        <Route path="/candidates/list" component={CandidateList} />
                        <Route path="/matching-strategy" component={MatchingStrategy} />
                        <Route path="/matching-test" component={MatchingTest} />
                        <Route
                            path="/match-details/:sourceIndex/:sourceId/:targetIndex/:targetId"
                            component={StandaloneMatchDetails}
                        />
                        <Redirect exact from="/" to={this.defaultRedirect()} />
                    </Switch>
                </Router>
                <ErrorMessages />
            </React.Fragment>
        );
    }

    defaultRedirect() {
        return config("ui.defaultRedirect", "/profile-to-candidates");
    }
}
