import React from "react";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import config from "~/config";
import AspectPropType from "~/prop-types/aspect";
import Weight from "~/components/Weight";
import styles from "./styles.module.scss";
import Number from "./Number";
import ListGroup from "react-bootstrap/ListGroup";
import Checkbox from "~/components/Checkbox";

export default class NumberAspectEditor extends React.PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        aspect: AspectPropType.isRequired,
        isEditing: PropTypes.bool.isRequired,
        forceAspectRequiredCheckbox: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        forceAspectRequiredCheckbox: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            newNumber: createNewNumber(),
        }
    }

    reset() {
        this.setState({newNumber: createNewNumber()})
    }

    render() {
        return (
            <Card className={styles.aspectEditor}>
                {this.renderHeader()}
                <ListGroup variant="flush">{this.renderNumber()}</ListGroup>
            </Card>
        );
    }

    renderHeader() {
        const {title, aspect, isEditing, forceAspectRequiredCheckbox} = this.props;

        return (
            <Card.Header className={styles.cardHeader}>
                {(forceAspectRequiredCheckbox || !config("ui.hideAspectRequiredCheckbox")) && (
                    <Checkbox
                        checked={aspect.required}
                        disabled={!isEditing}
                        onChange={this.handleRequiredChange}
                    />
                )}
                <span>{title}</span>
                <Weight
                    value={aspect.weight}
                    isEditing={isEditing}
                    className="ml-auto"
                    onChange={this.handleWeightChange}
                />
            </Card.Header>
        );
    }

    renderNumber() {
        const {aspect, isEditing} = this.props;

        if (aspect.value) {
            return (
                <Number
                    value={aspect.value}
                    isEditing={isEditing}
                    onChange={this.handleNumberChange}
                />
            );
        } else if (isEditing) {
            const {newNumber} = this.state;

            return (
                <Number
                    value={newNumber}
                    isEditing={true}
                    onChange={this.handleNumberChange}
                />
            );
        } else {
            return null;
        }
    }

    handleNumberChange = value => {
        const {name, aspect, onChange} = this.props;

        if (value.value !== undefined) {
            onChange(name, {...aspect, value: value});
        } else {
            onChange(name, {...aspect, value: null});
            this.setState({newNumber: value});
        }
    };

    handleWeightChange = nextWeight => {
        const {name, aspect, onChange} = this.props;

        onChange(name, {
            ...aspect,
            weight: nextWeight,
        });
    };

    handleRequiredChange = nextRequired => {
        const {name, aspect, onChange} = this.props;

        onChange(name, {
            ...aspect,
            required: nextRequired,
        });
    };
}

function createNewNumber() {
    return {
        weight: config("ui.weight.default"),
        required: false,
        value: undefined,
    };
}
