import Validator from "~/config/validation/validators/Validator";

class AllowsUndefinedValidator extends Validator {
    constructor(baseValidator) {
        super(baseValidator.description, baseValidator.defaultValue);
        this.baseValidator = baseValidator;
    }

    /**
     * @param {any} value
     * @param {string|undefined} path
     * @return {any}
     */
    validate(value, path = undefined) {
        if (value === undefined) {
            return undefined;
        } else {
            return this.baseValidator.validate(value, path);
        }
    }
}

Object.defineProperty(Validator.prototype, "allowsUndefined", {
    get: function() {
        return new AllowsUndefinedValidator(this)
    },
});
