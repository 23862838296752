import React from "react";
import PropTypes from "prop-types";
import Select from "~/components/Select";
import styles from "./styles.module.scss";

export default class EnumEditor extends React.PureComponent {
    static propTypes = {
        schema: PropTypes.object.isRequired,
        theKey: PropTypes.any,
        isKeyEditable: PropTypes.bool,
        value: PropTypes.string,
        renderKey: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        renderKey: () => null,
    };

    render() {
        const {theKey, value, renderKey} = this.props;

        return (
            <div className={styles.editor}>
                {renderKey(theKey)}
                <div className={styles.value}>
                    <Select
                        variant="small"
                        selected={value}
                        options={this.options()}
                        onChange={this.handleChange}
                    />
                </div>
            </div>
        );
    }

    options() {
        const {schema} = this.props;
        return schema.enum.map(value => ({
            label: value,
            value,
        }));
    }

    handleChange = option => {
        const {theKey, onChange} = this.props;
        onChange(theKey, option.value);
    };
}
