import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import typeEditorsStyles from "../TypeEditors/styles.module.scss";

export default class KeyCell extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node,
        value: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        isEditable: PropTypes.bool.isRequired,
        onChange: PropTypes.func,
    };

    static defaultProps = {
        isEditable: false,
    };

    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
    }

    focus() {
        this.inputRef.current.focus();
    }

    render() {
        const {children, isEditable} = this.props;

        return (
            <div className={typeEditorsStyles.key}>
                <div className={styles.main}>
                    {children}
                    {isEditable ? this.renderEditable() : this.renderStatic()}
                </div>
            </div>
        );
    }

    renderStatic() {
        const {value} = this.props;
        return value || "\xa0";
    }

    renderEditable() {
        const {value, placeholder} = this.props;

        return (
            <input
                className="form-control form-control-smallish"
                value={value}
                placeholder={placeholder}
                onChange={this.handleChange}
                ref={this.inputRef}
            />
        );
    }

    handleChange = e => {
        this.props.onChange(e.target.value);
    };
}
