import PropTypes from "prop-types";
import TranslatableLabelPropType from "~/prop-types/translatable-label";

const rule = PropTypes.shape({
    property: PropTypes.string.isRequired,
    data: PropTypes.any,
});

const groupShape = {combinator: PropTypes.oneOf(["and", "or"]).isRequired};
const group = PropTypes.shape(groupShape);
groupShape.rules = PropTypes.arrayOf(PropTypes.oneOfType([rule, group])).isRequired;

const propertyDefinition = PropTypes.shape({
    label: TranslatableLabelPropType.isRequired,
    type: PropTypes.oneOf(["boolean", "keyword", "number", "age", "enum", "date", "fullText"]),
});

const propertyDefinitions = PropTypes.objectOf(propertyDefinition);

export default {
    rule,
    group,
    propertyDefinition,
    propertyDefinitions,
};
