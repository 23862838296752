import Validator from "~/config/validation/validators/Validator";
import ValidationError from "~/config/validation/ValidationError";
import {isString} from "~/util/misc";
import {betterTypeOf} from "~/config/validation/validators/util";

export default class EnumValidator extends Validator {
    constructor(description, defaultValue, enumType) {
        super(description, defaultValue);
        this.enumType = enumType;
    }

    /**
     * @param {any} value
     * @param {string|undefined} path
     * @return {string}
     */
    validate(value, path = undefined) {
        if (value === undefined && this.defaultValue !== undefined) {
            return this.validate(this.defaultValue, path);
        } else if (this.enumType.values.includes(value)) {
            return value;
        } else {
            const whatWeGot = isString(value) ? value : betterTypeOf(value);

            throw new ValidationError(
                path,
                `Expected one of ${this.enumType.values.join(", ")}; but got ${whatWeGot}`
            );
        }
    }
}
