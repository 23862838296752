import PropTypes from "prop-types";
import {formatMessageMaybe} from "~/util/ui-translations";

export function Enum(values) {
    for (const key in values) {
        if (!values.hasOwnProperty(key)) continue;
        Object.defineProperty(this, key, {value: values[key], enumerable: true});
    }

    Object.defineProperties(this, {
        values: {value: Object.values(values)},
        propType: {value: PropTypes.oneOf(Object.values(values))},
    });
}

Enum.prototype.translate = function(intl, value) {
    return formatMessageMaybe(intl, {id: `enum.${this.name}.${value}`}, undefined, value);
};

Enum.prototype.selectOptions = function(intl) {
    return this.values.map(value => ({
        value,
        label: intl ? this.translate(intl, value) : value,
    }));
};
