import React from "react";
import PropTypes from "prop-types";
import Checkbox from "~/components/Checkbox";

export default class Option extends React.PureComponent {
    static propTypes = {
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
        checked: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    render() {
        const {label, checked} = this.props;

        return <Checkbox label={label} checked={checked} onChange={this.handleChange} />;
    }

    handleChange = checked => {
        const {value, onChange} = this.props;
        onChange(value, checked);
    };
}
