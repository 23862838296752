import objectPath from "object-path";
import {isFunction, isPlainObject, isString} from "~/util/misc";

const WILDCARD = "*";

/**
 * Return a new object based on the data in obj and the given organization.
 *
 * By default, all keys in the object appear in the result. To set the default organiation of all keys, set a value
 * in the organization object for the key "*".
 *
 * In organization, you can have the following values:
 *     - false, to hide the value from the object
 *     - true, to show the value from the object
 *     - an object, to define the organization of a sub-object or sub-array
 *     - a string, to replace the object by a the value of a (possibly deep) sub-key, or map the array in the same way (if an array)
 *
 * @param obj
 * @param organization
 * @returns {{}}
 */
export function reorganizeObject(obj, organization) {
    const defaultVisibility = objectPath.get(organization, WILDCARD, true);
    const finalOrganization = setDefaultVisibility(organization, obj, defaultVisibility);
    const result = {};

    for (const key in finalOrganization) {
        if (key === WILDCARD) continue;
        if (!finalOrganization.hasOwnProperty(key)) continue;

        const subOrganization = finalOrganization[key];
        if (subOrganization === false) continue;

        const value = objectPath.get(obj, key);

        if (value === undefined) {
            // Do nothing
        } else if (isFunction(subOrganization)) {
            result[key] = subOrganization(value);
        } else if (Array.isArray(value) && isString(subOrganization)) {
            result[key] = value.map(x => objectPath.get(x, subOrganization));
        } else if (isPlainObject(value) && isString(subOrganization)) {
            result[key] = objectPath.get(value, subOrganization);
        } else if (Array.isArray(value) && isPlainObject(subOrganization)) {
            result[key] = value.map(x => reorganizeObject(x, subOrganization));
        } else if (isPlainObject(value) && isPlainObject(subOrganization)) {
            result[key] = reorganizeObject(value, subOrganization);
        } else {
            result[key] = value;
        }
    }

    return result;
}

function setDefaultVisibility(organization, obj, defaultVisibility) {
    const result = {...organization};

    for (const key in obj) {
        if (!obj.hasOwnProperty(key)) continue;
        if (result[key] !== undefined) continue;
        result[key] = defaultVisibility;
    }

    return result;
}
