import React from "react";
import PropTypes from "prop-types";
import hash from "object-hash";
import c from "classnames";
import styles from "./main-category.module.scss";
import Match from "./Match";

export default class CompetenceSubcategory extends React.PureComponent {
    static propTypes = {
        subcategory: PropTypes.object.isRequired,
        categoryLabel: PropTypes.string.isRequired,
        shouldRenderCategoryLabel: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
        };
    }

    render() {
        return (
            <React.Fragment>
                {this.renderFirstMatch()}
                {this.renderRemainingMatches()}
            </React.Fragment>
        );
    }

    renderFirstMatch() {
        const {subcategory, categoryLabel, shouldRenderCategoryLabel} = this.props;
        const match = subcategory.matches[0];

        return (
            <tr>
                <td className={styles.categoryColumn}>
                    {shouldRenderCategoryLabel && categoryLabel}
                </td>
                <td className={styles.subcategoryColumn}>
                    {prepareSubcategoryLabel(subcategory.label)}
                </td>
                <td className={styles.countColumn} onClick={this.handleToggleExpanded}>
                    {this.renderMoreSwitch()}
                </td>
                <Match match={match} />
            </tr>
        );
    }

    renderRemainingMatches() {
        const {subcategory} = this.props;
        const {expanded} = this.state;
        const className = c({
            [styles.expandable]: true,
            [styles.expanded]: expanded,
        });

        return subcategory.matches.slice(1).map(match => (
            <tr key={match.id || hash(match)} className={className}>
                <td />
                <td />
                <td />
                <Match match={match} />
            </tr>
        ));
    }

    renderMoreSwitch() {
        const {subcategory} = this.props;
        const {expanded} = this.state;
        const invisible = subcategory.matches.length <= 1;

        return (
            <span className={styles.moreExpander}>
                (<span className={styles.numbers}>{subcategory.matches.length}</span>){" "}
                <i
                    className={c("fas", {
                        "fa-caret-right": !expanded,
                        "fa-caret-down": expanded,
                        invisible,
                    })}
                />
            </span>
        );
    }

    handleToggleExpanded = () => {
        this.setState(state => ({
            expanded: !state.expanded,
        }));
    };
}

function prepareSubcategoryLabel(label) {
    return label
        .split(" ")
        .join("\xa0")
        .replace("\xa0(", " (");
}
