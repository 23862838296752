import config from "~/config";
import ProfileType from "~/enums/ProfileType";
import {getInitialFilterState} from "~/util/filters-config";
import ObjectPath from "object-path";
import ProfileToJobs from "~/pages/ProfileToJobs";
import ProfileToCandidates from "~/pages/ProfileToCandidates";

/**
 * Even though the configuration is taken mostly directly, it's written out explicitly, to cause
 * earlier and better config errors.
 *
 * TODO: We need to implement config checking at the config level itself, instead of elsewhere,
 *       so that other could can just assume the config is correct!
 *
 * @param configPath
 * @returns
 */
export function toBaseMatchingConfiguration(configPath, resultType) {
    const indexCount = config(`${configPath}.indices`).length;
    const indices = [];

    for (let i = 0; i < indexCount; i++) {
        const defaultCustomFiltersPredicate = config(
            `${configPath}.indices.${i}.defaultCustomFilters`,
            undefined
        );

        indices.push({
            resultType,
            pageSize: 20,
            label: config(`${configPath}.indices.${i}.label`),
            name: config(`${configPath}.indices.${i}.name`),
            scoreType: config(`${configPath}.indices.${i}.scoreType`),
            expansionType: config(`${configPath}.indices.${i}.expansionType`),
            filters: config(`${configPath}.indices.${i}.filters`),
            customFiltersEnabled: defaultCustomFiltersPredicate !== undefined,
            defaultFilters: getInitialFilterState(config(`${configPath}.indices.${i}.filters`)),
            defaultCustomFiltersPredicate,
            sortModeGroup: config(`${configPath}.indices.${i}.sortModeGroup`),
            allowSelection: config(`${configPath}.indices.${i}.allowSelection`),
            selectionStatuses: config(`${configPath}.indices.${i}.selectionStatuses`),
            selectionStatusDefault: config(`${configPath}.indices.${i}.selectionStatusDefault`),
            selectionEndpoints: config(`${configPath}.indices.${i}.selectionEndpoints`, undefined),
            allowComparison: config(`${configPath}.indices.${i}.allowComparison`),
            allowDownload: config(`${configPath}.indices.${i}.allowDownload`),
            exportType: config(`${configPath}.indices.${i}.exportType`),
            exportSize: config(`${configPath}.indices.${i}.exportSize`),
            showMatchButton: config(`${configPath}.indices.${i}.showMatchButton`),
            extraColumns: config(`${configPath}.indices.${i}.extraColumns`),
            hideDetailsColumn: config(`${configPath}.indices.${i}.hideDetailsColumn`, false),
            detailsTitleProperty: config(`${configPath}.indices.${i}.detailsTitleProperty`),
            comparisonSubtitleProperty: config(`${configPath}.indices.${i}.comparisonSubtitleProperty`),
            extraProperties: config(`${configPath}.indices.${i}.extraProperties`),
            extraPropertyPhoneNumbers: config(
                `${configPath}.indices.${i}.extraPropertyPhoneNumbers`
            ),
            handleVisitExternal: config(`${configPath}.indices.${i}.handleVisitExternal`),
            createNoteCallback: config(`${configPath}.indices.${i}.createNoteCallback`),
            noteTypes: config(`${configPath}.indices.${i}.noteTypes`),
            defaultNoteType: config(`${configPath}.indices.${i}.defaultNoteType`),
            externalDetailsTabUrlFetcher: config(`${configPath}.indices.${i}.externalDetailsTabUrlFetcher`),
            externalDetailsTabDefaultActive: config(`${configPath}.indices.${i}.externalDetailsTabDefaultActive`),
            apiConversionPreProcessor: config(`${configPath}.indices.${i}.apiConversionPreProcessor`),
            matchInformationGetters: config(`${configPath}.indices.${i}.matchInformationGetters`),
            openExternalExistingSelection: config(`${configPath}.indices.${i}.openExternalExistingSelection`),
            selectAllLimit: config(`${configPath}.indices.${i}.selectAllLimit`),
            resultAnnotationEndpoint: config(`${configPath}.indices.${i}.resultAnnotationEndpoint`),
        });
    }

    return {
        enabled: config(`${configPath}.enabled`),
        indices: indices,
        keywordSearch: config(`${configPath}.keywordSearch`),
        keywordSuggestions: config(`${configPath}.keywordSuggestions`),
        matchSourceEntity: undefined,
    };
}

export function setDefaultMatchSourceEntityConfiguration(configuration, state) {
    const candidateIndex = config("features.profileToCandidates.indices.0.name", undefined);
    const candidateMatchingStrategy = ObjectPath.get(
        state,
        ["matching", "contexts", ProfileToCandidates.MATCHING_CONTEXT_ID, "matchingStrategy"],
        null
    );

    const jobIndex = config("features.profileToJobs.indices.0.name", undefined);
    const jobMatchingStrategy = ObjectPath.get(
        state,
        ["matching", "contexts", ProfileToJobs.MATCHING_CONTEXT_ID, "matchingStrategy"],
        null
    );

    if (candidateIndex !== undefined && jobIndex !== undefined) {
        configuration.matchSourceEntity = {
            candidateIndex,
            candidateMatchingStrategy,
            jobIndex,
            jobMatchingStrategy,
        };
    }
}

export function setSelectionConfiguration(configuration, state, contextId) {
    const matchSourceEntity = ObjectPath.get(
        state,
        ["matching", "contexts", contextId, "matchSourceEntity"],
        undefined
    );

    for (const index of configuration.indices) {
        if (!index.allowSelection) {
            continue;
        }

        if (matchSourceEntity === undefined || matchSourceEntity.hasFailedLoading) {
            // TODO: We really shouldn't change this here, the UI logic should do this instead...
            index.allowSelection = false;
        } else if (index.selectionEndpoints) {
            // Do nothing, the index itself has set endpoints
        } else if (index.resultType === ProfileType.CANDIDATE && matchSourceEntity.type === ProfileType.JOB) {
            index.selectionEndpoints = {
                get: config("api.selectionEndpoints.job.get"),
                set: config("api.selectionEndpoints.job.set"),
            };
        } else if (
            index.resultType === ProfileType.JOB &&
            matchSourceEntity.type === ProfileType.CANDIDATE
        ) {
            index.selectionEndpoints = {
                get: config("api.selectionEndpoints.candidate.get"),
                set: config("api.selectionEndpoints.candidate.set"),
            };
        } else {
            index.allowSelection = false;
        }
    }
}
