import PropTypes from "prop-types";
import {STORE} from "~/bootstrap";
import {triggerErrorMessage} from "~/actions/ui";

export default class ErrorMessage {
    static CLOSABLE_ALWAYS = "always";
    static CLOSABLE_SOMETIMES = "sometimes";
    static CLOSABLE_NEVER = "never";

    static propType = PropTypes.shape({
        message: PropTypes.string.isRequired,
        closable: PropTypes.oneOf([
            ErrorMessage.CLOSABLE_ALWAYS,
            ErrorMessage.CLOSABLE_SOMETIMES,
            ErrorMessage.CLOSABLE_NEVER,
        ]),
    });

    constructor(title, message, closable, values) {
        this.title = title;
        this.message = message;
        this.closable = closable;
        this.values = values;
    }

    static trigger(title, message, closable, values) {
        STORE.dispatch(triggerErrorMessage(new ErrorMessage(title, message, closable, values)));
    }
}
