import {createSelector} from "reselect";

export function createObjectOptionsSelector(
    dataSelector,
    getValue,
    getLabel,
    isDisabled = () => false
) {
    return createSelector(dataSelector, data => {
        return Object.keys(data).map(key => {
            const value = data[key];

            return {
                value: getValue(value, key),
                label: getLabel(value, key),
                disabled: isDisabled(value, key),
            };
        });
    });
}

export function createOptionsSelector(dataSelector, getValue, getLabel, isDisabled = () => false) {
    return createSelector(dataSelector, data => {
        return data.map(item => ({
            value: getValue(item),
            label: getLabel(item),
            disabled: isDisabled(item),
        }));
    });
}

export function createSortedUniqueValueSelector(dataSelector, property) {
    return createSelector(dataSelector, data => {
        const set = {};
        for (const item of data) set[item[property]] = true;
        return Object.keys(set).sort();
    });
}
