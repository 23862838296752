import React from "react";
import PropTypes from "prop-types";
import TabBar from "~/components/TabBar";

export default class IndexTab extends React.PureComponent {
    static propTypes = {
        index: PropTypes.object.isRequired,
        isActive: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired,
    };

    render() {
        const {index, isActive} = this.props;

        return (
            <TabBar.Tab
                title={index.label}
                isActive={isActive}
                isProcessing={false}
                onClick={this.handleClick}
            />
        );
    }

    handleClick = () => {
        const {index, onClick} = this.props;
        onClick(index);
    };
}
