import React from "react";
import PropTypes from "prop-types";
import baseStyles from "../styles.module.scss";
import Education from "./Education";

export default class EductionsRows extends React.PureComponent {
    static propTypes = {
        matches: PropTypes.array,
        getMatchDisplayValue: PropTypes.func.isRequired,
    };

    render() {
        return (
            <tr>
                <td className={baseStyles.stickyColumn} />
                {this.renderMatches()}
            </tr>
        );
    }

    renderMatches() {
        const {matches} = this.props;
        return matches.map((match, index) => (
            <td key={index} className={baseStyles.matchColumn}>
                {this.renderMatch(match)}
            </td>
        ));
    }

    renderMatch(match) {
        const {getMatchDisplayValue} = this.props;
        const educations = getMatchDisplayValue(match, "educations");
        const educationsSorted = [...educations].sort(compareEducations);

        return educationsSorted.map(education => (
            <Education key={education.id} value={education} />
        ));
    }
}

function compareEducations(a, b) {
    if (a.dateFrom === null && b.dateFrom === null) {
        return 0;
    } else if (a.dateFrom === null) {
        return 1;
    } else if (b.dateFrom === null) {
        return -1;
    } else {
        return b.dateFrom.localeCompare(a.dateFrom);
    }
}
