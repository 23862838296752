import React from "react";
import PropTypes from "prop-types";
import Foco from "react-foco";
import c from "classnames";
import {injectIntl} from "react-intl";
import ListGroup from "react-bootstrap/ListGroup";
import NumberInput from "~/components/NumberInput";
import styles from "./styles.module.scss";

class Number extends React.PureComponent {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        value: PropTypes.shape({
            weight: PropTypes.number.isRequired,
            required: PropTypes.bool.isRequired,
            value: PropTypes.number
        }).isRequired,
        isEditing: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    componentDidUpdate(prevProps) {
        if (prevProps.isEditing && !this.props.isEditing) {
            this.normalizeValue();
        }
    }

    componentWillUnmount() {
        this.normalizeValue();
    }

    render() {
        const {isEditing} = this.props;

        return (
            <ListGroup.Item className={styles.listItem}>
                <Foco
                    onFocusOutside={this.normalizeValue}
                    onClickOutside={this.normalizeValue}
                    className={styles.foco}
                    component="div"
                >
                    {isEditing ? this.renderValueEdit() : this.renderValueDisplay()}
                </Foco>
            </ListGroup.Item>
        );
    }

    renderValueDisplay() {
        const {value} = this.props;

        return (
            <span className={styles.description}>
                {value.value}
            </span>
        );
    }

    renderValueEdit() {
        const {intl, value} = this.props;

        return (
            <React.Fragment>
                <NumberInput
                    value={value.value}
                    placeholder={intl.formatMessage({id: "placeholder.number"})}
                    onChange={this.handleNumberChange}
                    className={c(styles.numberInput, "left")}
                />
            </React.Fragment>
        );
    }

    handleNumberChange = aValue => {
        const {value, onChange} = this.props;
        onChange({
            ...value,
            value: aValue});
    };

    normalizeValue = () => {
        // Do nothing?
    };

}

export default injectIntl(Number, {forwardRef: true});