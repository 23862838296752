import {createSelector} from "reselect";

export function createSelectors() {
    const matchesSelector = props => Object.values(props.state.results)[0] ? Object.values(props.state.results)[0].matches : {};
    const pagesSelector = props => Object.values(props.state.results)[0] ? Object.values(props.state.results)[0].pages : {};
    const displayedPageSelector = props => Object.values(props.state.results)[0] ? Object.values(props.state.results)[0].displayedPage : 0;

    const displayedMatchIdsSelector = createSelector(
        pagesSelector,
        displayedPageSelector,
        (pages, displayedPage) => {
            return pages[displayedPage] ? pages[displayedPage].ids : [];
        }
    );

    const displayedMatchesSelector = createSelector(
        matchesSelector,
        displayedMatchIdsSelector,
        (matches, displayedMatchIds) => {
            return displayedMatchIds.map(id => matches[id]);
        }
    );

    return {
        displayedMatches: displayedMatchesSelector,
    };
}
