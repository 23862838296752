import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import Select from "~/components/Select";
import NumberInput from "~/components/NumberInput";
import styles from "./styles.module.scss";

const COMPARISONS = {
    lessThan: {
        label: "<",
        inputType: "single",
        toFilter: (property, input) => {
            return {
                range: {
                    [property]: {
                        gte: `now-${input}y/d`,
                    },
                },
            };
        },
    },
    lessThanOrEqualTo: {
        label: "<=",
        inputType: "single",
        toFilter: (property, input) => {
            return {
                range: {
                    [property]: {
                        gte: `now-${input + 1}y/d`,
                    },
                },
            };
        },
    },
    equalTo: {
        label: "=",
        inputType: "single",
        toFilter: (property, input) => {
            return {
                range: {
                    [property]: {
                        gte: `now-${input + 1}y/d`,
                        lte: `now-${input}y/d`,
                    },
                },
            };
        },
    },
    greaterThanOrEqualTo: {
        label: ">=",
        inputType: "single",
        toFilter: (property, input) => {
            return {
                range: {
                    [property]: {
                        lte: `now-${input}y/d`,
                    },
                },
            };
        },
    },
    greaterThan: {
        label: ">",
        inputType: "single",
        toFilter: (property, input) => {
            return {
                range: {
                    [property]: {
                        lte: `now-${input + 1}y/d`,
                    },
                },
            };
        },
    },
    isBetween: {
        label: {id: "predicateEditor.isBetween"},
        inputType: "range",
        toFilter: (property, input, secondInput) => ({range: {[property]: {lte: `now-${input}y/d`, gte: `now-${secondInput + 1}y/d`}}}),
    },
    isEmpty: {
        label: {id: "predicateEditor.isEmpty"},
        inputType: "none",
        toFilter: property => ({bool: {must_not: {exists: {field: property}}}}),
    },
    isNotEmpty: {
        label: {id: "predicateEditor.isNotEmpty"},
        inputType: "none",
        toFilter: property => ({bool: {must: {exists: {field: property}}}}),
    },
};

export default class AgeProperty extends React.PureComponent {
    static propTypes = {
        data: PropTypes.shape({
            comparison: PropTypes.oneOf(Object.keys(COMPARISONS)),
            input: PropTypes.number,
            secondInput: PropTypes.number,
        }).isRequired,
        onChange: PropTypes.func.isRequired,
    };

    static defaultValue() {
        return {
            comparison: "equalTo",
            input: null,
            secondInput: null,
        };
    }

    static toFilter(property, data) {
        const comparisonInfo = COMPARISONS[data.comparison];

        if (comparisonInfo.inputType === "none") {
            return comparisonInfo.toFilter(property);
        } if (comparisonInfo.inputType === "single" && (data.input !== null && data.input !== undefined)) {
            return comparisonInfo.toFilter(property, data.input);
        } else if (comparisonInfo.inputType === "range" && (data.input !== null && data.input !== undefined) && (data.secondInput !== null && data.secondInput !== undefined)) {
            return comparisonInfo.toFilter(property, data.input, data.secondInput);
        } else {
            return undefined;
        }
    }

    render() {
        const {data} = this.props;
        const comparisonInfo = COMPARISONS[data.comparison];

        return (
            <React.Fragment>
                <Select
                    className={c(styles.givePriority, styles.comparisonSelect)}
                    selected={data.comparison}
                    onChange={this.handleComparisonChange}
                    options={COMPARISON_OPTIONS}
                    variant="extra-small"
                />
                {comparisonInfo.inputType === "single" && (
                    <NumberInput
                        className={c(
                            styles.givePriority,
                            styles.input,
                            "form-control",
                            "form-control-sm"
                        )}
                        value={data.input}
                        onChange={this.handleInputChange}
                    />
                )}
                {comparisonInfo.inputType === "range" && (
                    <React.Fragment>
                        <NumberInput
                            className={c(
                                styles.givePriority,
                                styles.input,
                                "form-control",
                                "form-control-sm"
                            )}
                            value={data.input}
                            onChange={this.handleInputChange}
                        />
                        <span className={styles.inBetweenLabel}>and</span>
                        <NumberInput
                            className={c(
                                styles.givePriority,
                                styles.input,
                                "form-control",
                                "form-control-sm"
                            )}
                            value={data.secondInput}
                            onChange={this.handleSecondInputChange}
                        />
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }

    handleComparisonChange = option => {
        const {data, onChange} = this.props;
        const nextData = {...data, comparison: option.value};
        onChange(nextData);
    };

    handleInputChange = input => {
        const {data, onChange} = this.props;
        const nextData = {...data, input};
        onChange(nextData);
    };

    handleSecondInputChange = secondInput => {
        const {data, onChange} = this.props;
        const nextData = {...data, secondInput};
        onChange(nextData);
    };
}

const COMPARISON_OPTIONS = Object.keys(COMPARISONS).map(key => ({
    label: COMPARISONS[key].label,
    value: key,
}));
