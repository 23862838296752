import {connect} from "react-redux";
import {
    initialize,
    selectFeedbackFromType,
    selectFeedbackToType,
    selectFeedbackKey,
    selectMatchingStrategy,
    selectProfile,
    updateFeedbackDecision,
    match,
} from "~/actions/monitoring";
import MatchingTest from "~/pages/MatchingTest";
import {
    strategyOptionsSelector,
    fullProfilesSelector,
    feedbackKeysOptionsSelector,
    feedbackToTypesOptionsSelector,
    orderedFullProfilesSelector,
} from "~/selectors/monitoring";

function mapStateToProps(state) {
    return {
        status: state.monitoring.status,
        isMatching: state.monitoring.isMatching,
        selectedStrategy: state.monitoring.selection.strategy,
        strategyOptions: strategyOptionsSelector(state),
        selectedProfile: state.monitoring.selection.profile,
        orderedProfiles: orderedFullProfilesSelector(state),
        allProfiles: fullProfilesSelector(state),
        feedbackKeysOptions: feedbackKeysOptionsSelector(state),
        feedbackToTypesOptions: feedbackToTypesOptionsSelector(state),
        selection: state.monitoring.selection,
        globalStats: state.monitoring.globalStats,
    };
}

const mapDispatchToProps = {
    initialize,
    selectFeedbackFromType,
    selectFeedbackToType,
    selectFeedbackKey,
    selectMatchingStrategy,
    selectProfile,
    updateFeedbackDecision,
    match,
};

export default connect(mapStateToProps, mapDispatchToProps)(MatchingTest);
