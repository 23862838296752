import uuid from "uuid/v4";
import config from "~/config";
import {createEmptyAspectByName} from "~/util/create-empty-aspect";
import {importProfile} from "~/util/match-profile";
import {EnabledAspectsBlackWhiteList} from "~/util/enabled-aspects";

export default function applyJobParseResults(matchProfile, parsingAspects) {
    let result = importProfile({...matchProfile, aspects: {}});

    for (const parsingAspect of parsingAspects) {
        const applicator = ASPECT_APPLICATORS[parsingAspect.aspectType];

        if (applicator !== undefined) {
            result = applicator(result, parsingAspect);
        }
    }

    return result;
}

const ASPECT_APPLICATORS = {
    JOB_FUNCTION: applyJobFunction,
    JOB_PROPERTY: applyHardSkill,
    JOB_SOFT_COMPTENCE: applySoftSkill,
    CONTRACT_TYPE: applyContractType,
    WORKING_HOURS: applyWorkingHours,
    GEO: applyLocation,
    WAGE: applyWage,
};

function applyJobFunction(matchProfile, parsingAspect) {
    if (!EnabledAspectsBlackWhiteList.allows("jobFunctions")) return matchProfile

    return addValueToAspect(matchProfile, "jobFunctions", {
        label: parsingAspect.matchedText,
    });
}

function applyHardSkill(matchProfile, parsingAspect) {
    if (!EnabledAspectsBlackWhiteList.allows("hardSkills")) return matchProfile

    return addValueToAspect(matchProfile, "hardSkills", {
        label: parsingAspect.matchedText,
    });
}

function applySoftSkill(matchProfile, parsingAspect) {
    if (!EnabledAspectsBlackWhiteList.allows("softSkills")) return matchProfile

    return addValueToAspect(matchProfile, "softSkills", {
        label: parsingAspect.behavioralCompetence,
    });
}

function applyContractType(matchProfile, parsingAspect) {
    if (!EnabledAspectsBlackWhiteList.allows("contractTypes")) return matchProfile

    return addValueToAspect(matchProfile, "contractTypes", {
        label: parsingAspect.value,
    });
}

function applyWorkingHours(matchProfile, parsingAspect) {
    if (!EnabledAspectsBlackWhiteList.allows("wage")) return matchProfile

    return addValueToAspect(matchProfile, "workingHours", {
        label: parsingAspect.value,
    });
}

function applyLocation(matchProfile, parsingAspect) {
    if (!EnabledAspectsBlackWhiteList.allows("locations")) return matchProfile

    return addValueToAspect(matchProfile, "locations", {
        label: parsingAspect.labels.map(x => x.label).join(', '),
        range: 50, // TODO: Get from parsing somehow?
        rangeUnit: "KILOMETER", // TODO: Same as above
    });
}

function applyWage(matchProfile, parsingAspect) {
    if (!EnabledAspectsBlackWhiteList.allows("wage")) return matchProfile

    const aspect = clonedAspectFromProfileOrNew(matchProfile, "wage");

    if (aspect === null) {
        return matchProfile;
    }

    aspect.value = aspect.value
        ? {...aspect.value}
        : {
              weight: config("ui.weight.default"),
              required: false,
              from: 0,
              to: 0,
          };

    aspect.value.from = parsingAspect.amountLow;
    aspect.value.to = parsingAspect.amountHigh;

    return updateAspect(matchProfile, "wage", aspect);
}

function addValueToAspect(matchProfile, name, valueProps) {
    const aspect = clonedAspectFromProfileOrNew(matchProfile, name);

    if (aspect === null) {
        return matchProfile;
    }

    aspect.value = [...aspect.value];
    aspect.value.push({
        id: uuid(),
        weight: config("ui.weight.default"),
        required: false,
        ...valueProps,
    });

    return updateAspect(matchProfile, name, aspect);
}

function updateAspect(matchProfile, name, nextValue) {
    return {
        ...matchProfile,
        aspects: {
            ...matchProfile.aspects,
            [name]: nextValue,
        },
    };
}

function clonedAspectFromProfileOrNew(matchProfile, name) {
    if (matchProfile.aspects[name] !== undefined) {
        return {...matchProfile.aspects[name]};
    } else {
        return createEmptyAspectByName(name);
    }
}
