import BooleanProperty from "./BooleanProperty";
import KeywordProperty from "./KeywordProperty";
import NumberProperty from "./NumberProperty";
import AgeProperty from "./AgeProperty";
import EnumProperty from "./EnumProperty";
import DateProperty from "./DateProperty";
import FullTextProperty from "./FullTextProperty";

export default {
    boolean: BooleanProperty,
    keyword: KeywordProperty,
    number: NumberProperty,
    age: AgeProperty,
    enum: EnumProperty,
    date: DateProperty,
    fullText: FullTextProperty,
};
