import React from "react";
import ObjectEditor from "./TypeEditors/ObjectEditor";
import FloatEditor from "./TypeEditors/FloatEditor";
import IntegerEditor from "./TypeEditors/IntegerEditor";
import StringEditor from "./TypeEditors/StringEditor";
import BooleanEditor from "./TypeEditors/BooleanEditor";
import EnumEditor from "./TypeEditors/EnumEditor";
import ArrayEditor from "./TypeEditors/ArrayEditor";

const loggedUnknownTypeError = {};

export function editorForSchema(schema) {
    if (schema.enum !== undefined) {
        return EnumEditor;
    }

    switch (schema.type) {
        case "Object":
            return ObjectEditor;

        case "Array":
            return ArrayEditor;

        case "float":
        case "double":
            return FloatEditor;

        case "int":
            return IntegerEditor;

        case "boolean":
            return BooleanEditor;

        case "String":
            return StringEditor;

        default:
            if (!loggedUnknownTypeError[schema.type]) {
                console.error("Unknown schema type: " + schema.type);
                loggedUnknownTypeError[schema.type] = true;
            }

            return undefined;
    }
}

export function renderEditorForSchema(schema, props = {}) {
    const Editor = editorForSchema(schema);
    if (Editor === undefined) return undefined;

    return <Editor {...props} schema={schema} />;
}
