import {
    UPDATE_JOB,
    UPDATE_JOB_PARSE_STATE,
    SET_FURTHEST_STEP,
} from "~/actions/job-to-candidates";
import matchingDemoJob from "~/data/matching-demo-job.json";
import {importJob} from "~/util/job";
import config from "~/config";

const INITIAL_STATE = {
    job: importJob(matchingDemoJob),
    jobParseState: config("enums.apiCallState.idle"),
    furthestStep: 1,
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case UPDATE_JOB:
            return updateJob(state, action.job);

        case UPDATE_JOB_PARSE_STATE:
            return updateJobParseState(state, action.jobParseState);

        case SET_FURTHEST_STEP:
            return setFurthestStep(state, action.furthestStep);

        default:
            return state;
    }
}

function updateJob(state, job) {
    return {...state, job};
}

function updateJobParseState(state, jobParseState) {
    return {...state, jobParseState};
}


function setFurthestStep(state, furthestStep) {
    return {
        ...state,
        furthestStep,
    };
}
