import uuid from "uuid/v4";
import PROPERTY_TYPES from "./property-types";

export function generateKeysForPredicate(rawPredicate) {
    if (rawPredicate === undefined) {
        return undefined;
    }

    if (!isGroup(rawPredicate)) {
        throw new Error("The predicate itself must be a group");
    }

    return generateKeysForGroup(rawPredicate);
}

export function removeInvalidRules(predicate, propertyDefinitions) {
    if (predicate === undefined) {
        return undefined;
    }

    if (propertyDefinitions === undefined) {
        throw new Error("propertyDefinitions not defined");
    }

    if (!isGroup(predicate)) {
        throw new Error("The predicate itself must be a group");
    }

    return removeInvalidRulesFromGroup(predicate, propertyDefinitions);
}

function removeInvalidRulesFromGroup(group, propertyDefinitions) {
    const result = {...group};

    result.rules = group.rules
        .filter(rule => isGroup(rule) || propertyDefinitions[rule.property] !== undefined)
        .map(rule => (isGroup(rule) ? removeKeysFromGroup(rule, propertyDefinitions) : rule));

    return result;
}

function generateKeysForGroup(group) {
    return {
        ...group,
        key: uuid(),
        rules: group.rules.map(rule => {
            if (isGroup(rule)) {
                return generateKeysForGroup(rule);
            } else {
                return {...rule, key: uuid()};
            }
        }),
    };
}

export function predicateToFilter(predicate, propertyDefinitions) {
    if (!isGroup(predicate)) {
        throw new Error("The predicate itself must be a group");
    }

    return groupToFilter(predicate, propertyDefinitions);
}

function groupToFilter(group, propertyDefinitions) {
    let ruleFilters = group.rules
        .map(rule => {
            if (isGroup(rule)) {
                return groupToFilter(rule, propertyDefinitions);
            } else {
                const propertyDefinition = propertyDefinitions[rule.property];

                if (propertyDefinition === undefined) {
                    console.warn("Invalid rule in predicate!", rule.property);
                    return undefined;
                }

                const PropertyType = PROPERTY_TYPES[propertyDefinition.type];
                const baseFilter = PropertyType.toFilter(rule.property, rule.data);
                return adjustBaseFilter(baseFilter, propertyDefinition);
            }
        })
        .filter(f => f !== undefined);

    if (ruleFilters.length === 0) {
        return undefined;
    } else if (group.combinator === "and") {
        return {bool: {must: ruleFilters}};
    } else if (group.combinator === "or") {
        return {bool: {should: ruleFilters}};
    } else {
        throw new Error("Unknown combinator");
    }
}

export function removeKeysFromPredicate(keyedPredicate) {
    if (!isGroup(keyedPredicate)) {
        throw new Error("The predicate itself must be a group");
    }

    return removeKeysFromGroup(keyedPredicate);
}

function removeKeysFromGroup(group) {
    const result = {...group};
    delete result.key;

    result.rules = group.rules.map(rule => {
        if (isGroup(rule)) {
            return removeKeysFromGroup(rule);
        } else {
            const result = {...rule};
            delete result.key;
            return result;
        }
    });

    return result;
}

function adjustBaseFilter(baseFilter, propertyDefinition) {
    let result = baseFilter;

    if (propertyDefinition.nested !== undefined) {
        result = {
            nested: {
                path: propertyDefinition.nested,
                query: result,
            },
        };
    }

    return result;
}

export function isGroup(rule) {
    return rule.combinator !== undefined;
}

export const EMPTY_PREDICATE = {
    combinator: "and",
    rules: [],
};

export const EMPTY_PREDICATE_WITH_KEYS = generateKeysForPredicate(EMPTY_PREDICATE);
