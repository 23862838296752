import React from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MainCategory from "~/components/text-mining/MainCategory";
import Breadcrumbs from "~/components/text-mining/Breadcrumbs";
import SimplePage from "~/components/SimplePage";

export default class TextMiningResults extends React.PureComponent {
    static propTypes = {
        feedback: PropTypes.array.isRequired,
        fetching: PropTypes.bool.isRequired,
    };

    render() {
        const {fetching} = this.props;

        return (
            <SimplePage subMenu={() => <Breadcrumbs />} isLoading={fetching}>
                <Row>
                    <Col>{this.renderMainCategories()}</Col>
                </Row>
            </SimplePage>
        );
    }

    renderMainCategories() {
        const {feedback} = this.props;

        return feedback.map(aspect => {
            return this.renderMainCategory(aspect);
        });
    }

    renderMainCategory(aspect) {
        return <MainCategory key={aspect.aspectType} name={aspect.label} aspect={aspect} />;
    }
}
