import {connect} from "react-redux";
import JobToCandidatesMatch from "~/pages/job-to-candidates/JobToCandidatesMatch";
import {updateLanguage} from "~/actions/ui";
import {actionsForMatchingContext} from "~/actions/matching";
import {getConfiguration, getState} from "~/util/matching-context";

function mapStateToProps(state) {
    return {
        job: state.jobToCandidates.job,
        jobParseState: state.jobToCandidates.jobParseState,
        language: state.ui.language,
        configuration: getConfiguration(state, JobToCandidatesMatch.MATCHING_CONTEXT_ID),
        state: getState(state, JobToCandidatesMatch.MATCHING_CONTEXT_ID),
    };
}

const mapDispatchToProps = {
    updateLanguage,
    ...actionsForMatchingContext(JobToCandidatesMatch.MATCHING_CONTEXT_ID),
};

export default connect(mapStateToProps, mapDispatchToProps)(JobToCandidatesMatch);
