import React from "react";
import ProgressBarBase from "react-bootstrap/ProgressBar";
import c from "classnames";
import styles from "./styles.module.scss";

export default class ProgressBar extends React.PureComponent {
    render() {
        const {now, max, className, onMouseOver, onMouseOut} = this.props;

        return (
            <div className={c(className, "position-relative")} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
                <div className={styles.percentage}>{Math.round((now / max) * 100)}%</div>
                <ProgressBarBase {...this.props} className={styles.progress} />
            </div>
        );
    }
}
