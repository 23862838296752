import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import styles from "./styles.module.scss";

export default class ExpandButton extends React.PureComponent {
    static propTypes = {
        value: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    render() {
        const {value} = this.props;

        return (
            <div
                className={c(styles.main, {[styles.expanded]: value})}
                onClick={this.handleClick}
            />
        );
    }

    handleClick = () => {
        const {value, onChange} = this.props;
        onChange(!value);
    };
}
