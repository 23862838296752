import {combineReducers} from "redux";
import candidates from "./candidates";
import textMining from "./text-mining";
import jobToCandidates from "./job-to-candidates";
import jobs from "./jobs";
import matching from "./matching";
import matchingStrategy from "./matching-strategy";
import monitoring from "./monitoring";
import ui from "./ui";
import standaloneMatchDetails from "./standalone-match-details";

const rootReducer = combineReducers({
    candidates,
    jobs,
    jobToCandidates,
    matching,
    matchingStrategy,
    monitoring,
    standaloneMatchDetails,
    textMining,
    ui,
});

export default rootReducer;