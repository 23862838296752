import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import Button from "react-bootstrap/Button";
import styles from "./styles.module.scss";

export default class RemoveButton extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        onClick: PropTypes.func.isRequired,
    };

    render() {
        const {className} = this.props;

        return (
            <Button className={c(styles.button, className)} size="sm" variant="outline-secondary" onClick={this.handleClick}>
                <i className="far fa-trash-alt"/>
            </Button>
        );
    }

    handleClick = () => {
        this.props.onClick();
    }
}
