import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import Select from "~/components/Select";
import styles from "./styles.module.scss";

export default class BooleanProperty extends React.PureComponent {
    static propTypes = {
        data: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    static defaultValue() {
        return true;
    }

    static toFilter(property, data) {
        return {term: {[property]: data}};
    }

    render() {
        const {data} = this.props;

        return (
            <Select
                className={c(styles.givePriority, styles.comparisonSelect)}
                selected={data}
                options={OPTIONS}
                onChange={this.handleSelect}
                variant="extra-small"
            />
        );
    }

    handleSelect = option => {
        this.props.onChange(option.value);
    };
}

const OPTIONS = [
    {label: {id: "predicateEditor.isTrue"}, value: true},
    {label: {id: "predicateEditor.isFalse"}, value: false},
];
