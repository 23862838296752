import {connect} from "react-redux";
import ProfileToCandidates from "~/pages/ProfileToCandidates";
import {updateLanguage} from "~/actions/ui";
import {actionsForMatchingContext} from "~/actions/matching";
import {getConfiguration, getState} from "~/util/matching-context";

function mapStateToProps(state) {
    return {
        language: state.ui.language,
        configuration: getConfiguration(state, ProfileToCandidates.MATCHING_CONTEXT_ID),
        state: getState(state, ProfileToCandidates.MATCHING_CONTEXT_ID),
    };
}

const mapDispatchToProps = {
    updateLanguage,
    ...actionsForMatchingContext(ProfileToCandidates.MATCHING_CONTEXT_ID),
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileToCandidates);
