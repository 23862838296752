import {connect} from "react-redux";
import JobToCandidates from "~/pages/job-to-candidates/JobToCandidates";

function mapStateToProps(state) {
    return {
        furthestStep: state.jobToCandidates.furthestStep,
    };
}

const mapDispatchToProps = {
    //
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JobToCandidates);
