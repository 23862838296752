import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import config from "~/config";
import {RANGE_UNIT_TRANSLATIONS} from "~/util/ui-translations";

export default class RangeUnitSelect extends React.PureComponent {
    static propTypes = {
        value: PropTypes.string.isRequired,
        className: PropTypes.string,
        onChange: PropTypes.func.isRequired,
    };

    render() {
        const {value, className} = this.props;

        return (
            <select value={value} onChange={this.handleChange} className={c(className)}>
                {this.renderOptions()}
            </select>
        );
    }

    renderOptions() {
        return config("enums.rangeUnit").map(unit => (
            <option key={unit} value={unit}>
                {RANGE_UNIT_TRANSLATIONS[unit]}
            </option>
        ));
    }

    handleChange = e => {
        const value = e.target.value;

        if (!config("enums.rangeUnit").includes(value)) {
            return;
        }

        this.props.onChange(value);
    };
}
