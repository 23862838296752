import {
    REMOVE_STRATEGY,
    SET_ALL_STRATEGIES,
    SET_DEFAULT_STRATEGY, SET_SAVE_STATUS,
    SET_SCHEMA,
    SET_STATUS,
    UPDATE_STRATEGY,
} from "~/actions/matching-strategy";

export const Status = {
    UNINITIALIZED: "UNINITIALIZED",
    INITIALIZING: "INITIALIZING",
    INITIALIZED: "INITIALIZED",
};

export const SaveStatus = {
    SAVED: "SAVED",
    CHANGED: "CHANGED",
    SAVING: "SAVING",
};

const INITIAL_STATE = {
    status: Status.UNINITIALIZED,
    saveStatus: SaveStatus.SAVED,
    schema: {},
    originalAllStrategies: {},
    allStrategies: {},
    defaultStrategies: {},
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_STATUS:
            return setStatus(state, action.status);

        case SET_SAVE_STATUS:
            return setSaveStatus(state, action.saveStatus);

        case SET_SCHEMA:
            return setSchema(state, action.schema);

        case SET_ALL_STRATEGIES:
            return setAllStrategies(state, action.allStrategies);

        case SET_DEFAULT_STRATEGY:
            return setDefaultStrategy(state, action.profileType, action.defaultStrategy);

        case UPDATE_STRATEGY:
            return updateStrategy(state, action.name, action.strategy);

        case REMOVE_STRATEGY:
            return removeStrategy(state, action.name);

        default:
            return state;
    }
}

function setStatus(state, status) {
    return {...state, status};
}

function setSaveStatus(state, saveStatus) {
    return {...state, saveStatus};
}

function setSchema(state, schema) {
    return {...state, schema};
}

function setAllStrategies(state, allStrategies) {
    if (state.saveStatus === SaveStatus.SAVING) return state;

    return {...state, allStrategies, originalAllStrategies: allStrategies};
}

function setDefaultStrategy(state, profileType, defaultStrategy) {
    return {
        ...state,
        defaultStrategies: {
            ...state.defaultStrategies,
            [profileType]: defaultStrategy,
        },
    };
}

function updateStrategy(state, name, strategy) {
    if (state.saveStatus === SaveStatus.SAVING) return state;

    const nextAllStrategies = {
        ...state.allStrategies,
        [name]: strategy,
    };

    return updateSaveStatus({...state, allStrategies: nextAllStrategies});
}

function removeStrategy(state, name) {
    if (state.saveStatus === SaveStatus.SAVING) return state;

    const nextAllStrategies = {...state.allStrategies};
    delete nextAllStrategies[name];
    return updateSaveStatus({...state, allStrategies: nextAllStrategies});
}

function updateSaveStatus(state) {
    return {
        ...state,
        saveStatus:
            JSON.stringify(state.allStrategies) === JSON.stringify(state.originalAllStrategies)
                ? SaveStatus.SAVED
                : SaveStatus.CHANGED,
    };
}
