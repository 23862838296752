import React from "react";
import Spinner from "react-bootstrap/Spinner";
import styles from "./parsing-loader.module.scss";
import {FormattedMessage} from "react-intl";

export default class ParsingLoader extends React.PureComponent {
    render() {
        return (
            <div className={styles.main}>
                <Spinner animation="border" role="status" size="sm" />{" "}
                <FormattedMessage id="progress.processing" />
            </div>
        );
    }
}
