import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import config from "~/config";
import ParsingLoader from "~/components/text-mining/ParsingLoader/ParsingLoader";
import Container from "react-bootstrap/Container";
import Menu from "~/components/Menu";
import {Auth0Context} from "~/util/auth0-provider";
import styles from "./styles.module.scss";

/**
 * DEPRECATED: Use BasicPageLayout instead.
 */
export default class SimplePage extends React.PureComponent {
    static propTypes = {
        subMenu: PropTypes.func.isRequired,
        children: PropTypes.any,
        isLoading: PropTypes.bool.isRequired,
        fullHeight: PropTypes.bool.isRequired,
        fullWidth: PropTypes.bool.isRequired,
        className: PropTypes.string,
    };

    static defaultProps = {
        subMenu: () => undefined,
        isLoading: false,
        fullHeight: false,
        fullWidth: false,
    };

    render() {
        const {className, subMenu, children, isLoading, fullHeight, fullWidth} = this.props;
        const hideMenu = config("ui.hideMenu", false);

        return (
            <div className={c(styles.page, {[styles.fullHeight]: fullHeight})}>
                {!hideMenu && (
                    <Auth0Context.Consumer>
                        {auth0Context => <Menu auth0Context={auth0Context} />}
                    </Auth0Context.Consumer>
                )}
                {subMenu()}
                <div className={styles.content}>
                    <div className={styles.content2}>
                        <Container
                            className={c(className, {
                                [styles.fullHeightContainer]: fullHeight,
                                [styles.fullWidthContainer]: fullWidth,
                            })}
                        >
                            {isLoading ? <ParsingLoader /> : children}
                        </Container>
                    </div>
                </div>
            </div>
        );
    }
}
