import React from "react";
import PropTypes from "prop-types";
import ObjectPath from "object-path";
import ProfileType from "~/enums/ProfileType";
import CandidateMatchList from "~/components/CandidateMatchList";
import JobMatchList from "~/components/JobMatchList";
import {hasEnabledFilters, isFunction} from "~/util/misc";
import styles from "./styles.module.scss";
import {createSelectors} from "~/components/MatchingPage/IndexResultsTab/selectors";
import MatchResultsPaginationBar from "~/components/MatchResultsPaginationBar";
import {FormattedMessage} from "react-intl";
import {getMatchDetailsTitleSubject, isGreenMatch} from "~/util/match";
import {getNameForJobMatch} from "~/components/JobMatchList/JobMatchList";
import MatchDetailsDialog from "~/components/MatchDetailsDialog/MatchDetailsDialog";
import MatchComparisonDialog from "~/components/MatchComparisonDialog/MatchComparisonDialog";
import {getCandidateMatchDisplayValue, getJobMatchDisplayValue} from "~/util/match-display-values";
import SelectAllState from "~/enums/SelectAllState";

export default class IndexResultsTab extends React.PureComponent {
    static propTypes = {
        index: PropTypes.shape({
            label: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            createNoteCallback: PropTypes.func,
        }).isRequired,
        results: PropTypes.object,
        matchSourceEntity: PropTypes.object,
        requestPage: PropTypes.func.isRequired,
        makeSelection: PropTypes.func.isRequired,
        selectAllFromApi: PropTypes.func.isRequired,
        onSelectionChange: PropTypes.func.isRequired,
        onSelectionStatusChange: PropTypes.func.isRequired,
        onDownload: PropTypes.func.isRequired,
        onMatchToOthers: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            processingSelection: false, // TODO: This will need to go into global state
            selectingAll: false, // TODO: Might need to put into global state
            displayedMatches: [],
        };

        this.selectors = createSelectors();
        this.matchListRef = React.createRef();
        this.unmounted = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.results.displayedPage !== this.props.results.displayedPage) {
            this.matchListRef.current.scrollToTop();
        }
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    render() {
        const {index, results, onMatchToOthers} = this.props;

        const selection = results.selection.reduce((selection, id) => {
            selection[id] = true;
            return selection;
        }, {});

        const MatchListComponent = this.forResultType({
            [ProfileType.CANDIDATE]: () => CandidateMatchList,
            [ProfileType.JOB]: () => JobMatchList,
        });

        return (
            <div className={styles.resultsWrapper}>
                <MatchListComponent
                    indexName={index.name}
                    ref={this.matchListRef}
                    className={styles.matchList}
                    matchInformationGetters={index.matchInformationGetters}
                    openExternalExistingSelection={index.openExternalExistingSelection}
                    allowSelection={index.allowSelection || index.allowComparison}
                    extraColumns={index.extraColumns}
                    hideDetailsColumn={index.hideDetailsColumn}
                    extraPropertyPhoneNumbers={index.extraPropertyPhoneNumbers}
                    matches={this.selectors.displayedMatches(results)}
                    annotations={results.annotations}
                    selection={selection}
                    existingSelection={results.backendSelection.data}
                    selectAllState={this.getSelectAllState()}
                    isGreenMatch={this.isGreenMatch}
                    noResultsLabel={
                        !hasEnabledFilters(results.lastRequest.filters)
                            ? "match.noResults"
                            : "match.noResultsFiltersHint"
                    }
                    getMatchDisplayValue={this.forResultType({
                        [ProfileType.CANDIDATE]: () => this.getCandidateMatchDisplayValue,
                        [ProfileType.JOB]: () => this.getJobMatchDisplayValue,
                    })}
                    onView={this.handleView}
                    onMatch={index.showMatchButton ? onMatchToOthers : undefined}
                    onVisitExternal={index.handleVisitExternal}
                    onSelectionChange={this.handleSelectionChange}
                    onSelectAllChange={this.handleSelectAllChange}
                />
                {this.renderPaginationBar()}
                {this.renderDetailsDialog()}
                {this.renderComparisonDialog()}
            </div>
        );
    }

    getSelectAllState() {
        const {selectingAll} = this.state;

        if (selectingAll) {
            return SelectAllState.SELECTING;
        }

        const {index, results} = this.props;

        if (index.selectAllLimit <= 0) {
            return undefined;
        }

        const allSelected =
            results.count !== undefined &&
            results.count > 0 &&
            (results.selection.length === results.count ||
                results.selection.length >= index.selectAllLimit);

        return allSelected ? SelectAllState.SELECTED_ALL : SelectAllState.SELECTED_NONE;
    }

    renderPaginationBar() {
        const {index, results} = this.props;

        return (
            <MatchResultsPaginationBar
                page={results.displayedPage}
                pageSize={index.pageSize}
                totalItems={results.count || 0}
                selectionSize={results.selection.length}
                makingSelection={results.backendSelection.isMakingSelection}
                selectionStatuses={index.selectionStatuses}
                selectionStatus={results.selectionStatus}
                canSelect={index.allowSelection}
                canCompare={index.allowComparison}
                canDownload={index.allowDownload}
                selectLabel={this.forResultType({
                    [ProfileType.CANDIDATE]: "temporary.selectCandidates",
                    [ProfileType.JOB]: "temporary.selectJobs",
                })}
                compareLabel={this.forResultType({
                    [ProfileType.CANDIDATE]: "compareCandidates",
                    [ProfileType.JOB]: "compareJobs",
                })}
                onSelectionStatusChange={this.handleSelectionStatusChange}
                onPageChange={this.handlePageChange}
                onDownload={this.handleDownload}
                onSelect={this.handleSelect}
                onCompare={this.handleCompare}
            />
        );
    }

    renderDetailsDialog() {
        const {index, results, matchSourceEntity} = this.props;
        const {displayedMatches} = this.state;

        if (displayedMatches.length !== 1) {
            return null;
        }

        const labels = {
            forwardLabel: <FormattedMessage id="matchQuality.searchProfile" />,
            forwardSubLabel: undefined,
            reverseLabel: this.forResultType({
                [ProfileType.CANDIDATE]: () =>
                    getMatchDetailsTitleSubject(results.matches[displayedMatches[0]]),
                [ProfileType.JOB]: () => getNameForJobMatch(results.matches[displayedMatches[0]]),
            }),
            title: this.forResultType({
                [ProfileType.CANDIDATE]: () => (
                    <FormattedMessage
                        id="detailsModal.title"
                        values={{
                            subject: getMatchDetailsTitleSubject(
                                results.matches[displayedMatches[0]]
                            ),
                        }}
                    />
                ),
                [ProfileType.JOB]: () => (
                    <FormattedMessage
                        id="detailsModal.title"
                        values={{subject: getNameForJobMatch(results.matches[displayedMatches[0]])}}
                    />
                ),
            }),
        };

        if (matchSourceEntity) {
            labels.forwardLabel = matchSourceEntity.displayName;
            labels.forwardSubLabel = <FormattedMessage id="matchQuality.searchProfile" />;
        }

        return (
            <MatchDetailsDialog
                match={results.matches[displayedMatches[0]]}
                extraPropertiesOrganization={index.extraProperties}
                extraPropertyPhoneNumbers={index.extraPropertyPhoneNumbers}
                title={labels.title}
                forwardLabel={labels.forwardLabel}
                forwardSubLabel={labels.forwardSubLabel}
                reverseLabel={labels.reverseLabel}
                getMatchDisplayValue={this.forResultType({
                    [ProfileType.CANDIDATE]: () => this.getCandidateMatchDisplayValue,
                    [ProfileType.JOB]: () => this.getJobMatchDisplayValue,
                })}
                noteTypes={index.noteTypes}
                defaultNoteType={index.defaultNoteType}
                externalDetailsTabUrlFetcher={index.externalDetailsTabUrlFetcher}
                externalDetailsTabDefaultActive={index.externalDetailsTabDefaultActive}
                onHide={this.handleHideMatchDetailsDialogs}
                onCreateNote={index.createNoteCallback ? this.handleCreateNote : undefined}
            />
        );
    }

    renderComparisonDialog = () => {
        const {results} = this.props;
        const {displayedMatches} = this.state;

        if (displayedMatches.length < 2) {
            return null;
        }

        const titleLabel = this.forResultType({
            [ProfileType.CANDIDATE]: "compareCandidates",
            [ProfileType.JOB]: "compareJobs",
        });

        const reverseLabel = this.forResultType({
            [ProfileType.CANDIDATE]: "reverse.candidateToProfile",
            [ProfileType.JOB]: "reverse.jobToProfile",
        });

        const reverseHelp = this.forResultType({
            [ProfileType.CANDIDATE]: "reverse.candidateToProfileHelp",
            [ProfileType.JOB]: "reverse.jobToProfileHelp",
        });

        return (
            <MatchComparisonDialog
                matches={displayedMatches.map(id => results.matches[id])}
                titleLabel={titleLabel}
                reverseLabel={reverseLabel}
                reverseHelp={reverseHelp}
                getMatchDisplayValue={this.forResultType({
                    [ProfileType.CANDIDATE]: () => this.getCandidateMatchDisplayValue,
                    [ProfileType.JOB]: () => this.getJobMatchDisplayValue,
                })}
                onHide={this.handleHideMatchDetailsDialogs}
            />
        );
    };

    isGreenMatch = match => {
        return isGreenMatch(match, this.props.index.lastRequest);
    };

    getCandidateMatchDisplayValue = (match, section) => {
        const {index} = this.props;

        if (section === "additionalText") {
            return undefined; // TODO
        } else if (section === "header") {
            return getMatchDetailsTitleSubject(match);
        } else if (section === "headerSubtitle") {
            if (index.comparisonSubtitleProperty) {
                return ObjectPath.get(match.candidate, index.comparisonSubtitleProperty, undefined);
            } else {
                return undefined;
            }
        } else {
            return getCandidateMatchDisplayValue(match, section);
        }
    };

    getJobMatchDisplayValue = (match, section) => {
        const {index} = this.props;

        if (section === "additionalText") {
            return undefined; // TODO
        } else if (section === "header") {
            return getNameForJobMatch(match);
        } else if (section === "headerSubtitle") {
            console.log(index);
            if (index.comparisonSubtitleProperty) {
                return ObjectPath.get(match.job, index.comparisonSubtitleProperty, undefined);
            } else {
                return undefined;
            }
        } else {
            return getJobMatchDisplayValue(match, section);
        }
    };

    forResultType = values => {
        const value = values[this.props.index.resultType];
        return isFunction(value) ? value() : value;
    };

    handleView = id => {
        this.setState({displayedMatches: [id]});
    };

    handleCompare = () => {
        const {selection, matches} = this.props.results;

        this.setState({
            displayedMatches: selection
                .map(id => matches[id])
                .filter(m => m)
                .sort((a, b) => b.score - a.score)
                .map(match => match.id),
        });
    };

    handleSelectionChange = selection => {
        const {index, onSelectionChange} = this.props;
        onSelectionChange(index, Object.keys(selection));
    };

    handleSelectAllChange = checked => {
        const {selectingAll} = this.state;

        if (selectingAll) {
            return SelectAllState.SELECTING;
        }

        const {index, results, onSelectionChange, selectAllFromApi} = this.props;

        if (checked && results.count !== undefined && results.count > 0) {
            this.setState({selectingAll: true});
            selectAllFromApi(index)
                .then(() => this.clearSelectingAll())
                .catch(e => {
                    this.clearSelectingAll();
                    throw e;
                });
        } else {
            onSelectionChange(index, []);
        }
    };

    clearSelectingAll() {
        if (!this.unmounted) {
            this.setState({selectingAll: false});
        }
    }

    handlePageChange = page => {
        const {requestPage, index} = this.props;
        requestPage(index, page);
    };

    handleDownload = () => {
        const {index, onDownload} = this.props;
        return onDownload(index);
    };

    handleSelect = () => {
        const {index, results, makeSelection} = this.props;
        makeSelection(index, results.selection);
    };

    handleHideMatchDetailsDialogs = () => {
        this.setState({displayedMatches: []});
    };

    handleSelectionStatusChange = status => {
        const {index, onSelectionStatusChange} = this.props;
        onSelectionStatusChange(index, status);
    };

    handleCreateNote = (...args) => {
        const {index} = this.props;
        return index.createNoteCallback(...args);
    };
}
