import React from "react";
import PropTypes from "prop-types";
import uuid from "uuid/v4";
import c from "classnames";

export default class Checkbox extends React.PureComponent {
    static propTypes = {
        label: PropTypes.string,
        checked: PropTypes.bool.isRequired,
        disabled: PropTypes.bool.isRequired,
        color: PropTypes.oneOf([undefined, "purple", "green"]),
        title: PropTypes.string,
        type: PropTypes.oneOf(["checkbox", "switch"]).isRequired,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        disabled: false,
        type: "checkbox",
    };

    constructor(props) {
        super(props);

        this.uuid = uuid();
    }

    render() {
        const {type, label, checked, disabled, color, title} = this.props;

        return (
            <div
                className={c("custom-control", `custom-${type}`, {
                    [`custom-control-${color}`]: color,
                })}
                title={title}
            >
                <input
                    id={`check-${this.uuid}`}
                    type="checkbox"
                    className="custom-control-input"
                    checked={checked}
                    disabled={disabled}
                    onClick={stopPropagation}
                    onChange={this.handleChange}
                />
                <label
                    className="custom-control-label w-100"
                    htmlFor={`check-${this.uuid}`}
                    onClick={stopPropagation}
                >
                    {label}
                </label>
            </div>
        );
    }

    handleChange = e => {
        this.props.onChange(e.target.checked);
    };
}

function stopPropagation(e) {
    e.stopPropagation();
}
