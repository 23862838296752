import Api from "~/api";
import {Status} from "~/reducers/monitoring";
import TaskStatus from "~/enums/TaskStatus";
import {delay} from "~/util/promise";
import MatchDecisionType from "~/enums/MatchDecisionType";

export const SET_STATUS = "monitoring/SET_STATUS";
export const SET_MATCHING = "monitoring/SET_MATCHING";
export const SET_ALL_STRATEGIES = "monitoring/SET_ALL_STRATEGIES";
export const SET_PROFILES = "monitoring/SET_PROFILES";
export const SELECT_FEEDBACK_FROM_TYPE = "monitoring/SELECT_FEEDBACK_FROM_TYPE";
export const SELECT_FEEDBACK_TO_TYPE = "monitoring/SELECT_FEEDBACK_TO_TYPE";
export const SELECT_FEEDBACK_KEY = "monitoring/SELECT_FEEDBACK_KEY";
export const SELECT_MATCHING_STRATEGY = "monitoring/SELECT_MATCHING_STRATEGY";
export const SELECT_PROFILE = "monitoring/SELECT_PROFILE";
export const SET_FEEDBACK_DECISION = "monitoring/UPDATE_FEEDBACK_DECISION";
export const SET_FEEDBACK_IDS = "monitoring/SET_FEEDBACK_IDS";
export const APPLY_MATCH_DATA = "monitoring/APPLY_MATCH_RESULTS";

export function initialize() {
    return (dispatch, getState) => {
        dispatch(setStatus(Status.INITIALIZING));

        const selection = getState().monitoring.selection;

        return Promise.all([
            Api.getMatchingStrategies(),
            Api.getFeedbackIds(selection.feedbackFromType),
        ]).then(([allStrategies, feedbackIds]) => {
            dispatch(setAllStrategies(allStrategies));
            dispatch(setFeedbackIds(feedbackIds));
            dispatch(setStatus(Status.INITIALIZED));
        });
    };
}

let activeGetFeedbackIdsPromise = null;
export function getFeedbackIds() {
    return (dispatch, getState) => {
        if (activeGetFeedbackIdsPromise !== null) activeGetFeedbackIdsPromise.abort();

        const selection = getState().monitoring.selection;

        const promise = Api.getFeedbackIds(selection.feedbackFromType)
            .then(feedbackIds => {
                dispatch(setFeedbackIds(feedbackIds));
                if (activeGetFeedbackIdsPromise === promise) activeGetFeedbackIdsPromise = null;
            })
            .catch(error => {
                if (activeGetFeedbackIdsPromise === promise) activeGetFeedbackIdsPromise = null;
                throw error;
            });

        activeGetFeedbackIdsPromise = promise;
        return promise;
    };
}

let activeGetProfilesPromise = null;
export function getProfiles() {
    return (dispatch, getState) => {
        if (activeGetProfilesPromise !== null) activeGetProfilesPromise.abort();

        const selection = getState().monitoring.selection;

        const promise = Api.getProfiles(
            selection.feedbackFromType,
            selection.feedbackToType,
            selection.feedbackKey,
            0,
            50
        )
            .then(result => {
                dispatch(setProfiles(result.profiles));
                if (activeGetProfilesPromise === promise) activeGetProfilesPromise = null;
            })
            .catch(error => {
                if (activeGetProfilesPromise === promise) activeGetProfilesPromise = null;
                throw error;
            });

        activeGetProfilesPromise = promise;
        return promise;
    };
}

let activeMatchPromise = null;
const MATCH_STATUS_FETCH_DELAY = 1000;
export function match() {
    return (dispatch, getState) => {
        if (activeMatchPromise !== null) activeMatchPromise.abort();

        const selection = getState().monitoring.selection;
        dispatch(setMatching(true));

        const promise = Api.match(
            selection.feedbackFromType,
            selection.feedbackToType,
            selection.feedbackKey,
            selection.matchingStrategy
        )
            .then(function waitForCompletion(task) {
                if ([TaskStatus.FINISHED, TaskStatus.ERROR].includes(task.taskStatus)) {
                    return task;
                } else {
                    dispatch(applyMatchData(task));
                    return delay(MATCH_STATUS_FETCH_DELAY)
                        .then(() => Api.getMatchingReport(task.reportId))
                        .then(waitForCompletion);
                }
            })
            .then(task => {
                dispatch(setMatching(false));
                dispatch(applyMatchData(task));
                if (activeMatchPromise === promise) activeMatchPromise = null;
            })
            .catch(error => {
                dispatch(setMatching(false));
                if (activeMatchPromise === promise) activeMatchPromise = null;
                throw error;
            });

        activeMatchPromise = promise;
        return promise;
    };
}

export function updateFeedbackDecision(profileId, feedbackId, decision) {
    return (dispatch, getState) => {
        const state = getState();
        const originalDecision = getFeedbackDecision(state, profileId, feedbackId);
        dispatch(setFeedbackDecision(profileId, feedbackId, decision));

        return Api.addOrUpdateFeedback({
            type: state.monitoring.selection.feedbackFromType,
            key: state.monitoring.selection.feedbackKey,
            matchedProfileId: feedbackId,
            decision: decision,
        }).catch(error => {
            dispatch(setFeedbackDecision(profileId, feedbackId, originalDecision));
            throw error;
        });
    };
}

function getFeedbackDecision(state, profileId, feedbackId) {
    for (const feedback of state.monitoring.allProfiles[profileId].matchFeedback) {
        if (feedback.matchedProfileId === feedbackId) {
            return feedback.decision;
        }
    }

    return MatchDecisionType.UNDECIDED;
}

function setFeedbackDecision(profileId, feedbackId, decision) {
    return {
        type: SET_FEEDBACK_DECISION,
        profileId,
        feedbackId,
        decision,
    };
}

function setStatus(status) {
    return {
        type: SET_STATUS,
        status,
    };
}

function setMatching(isMatching) {
    return {
        type: SET_MATCHING,
        isMatching,
    };
}

function setAllStrategies(allStrategies) {
    return {
        type: SET_ALL_STRATEGIES,
        allStrategies,
    };
}

function setProfiles(profiles) {
    return {
        type: SET_PROFILES,
        profiles,
    };
}

function applyMatchData(data) {
    return {
        type: APPLY_MATCH_DATA,
        data,
    };
}

export function selectFeedbackFromType(id) {
    return {
        type: SELECT_FEEDBACK_FROM_TYPE,
        id,
    };
}

export function selectFeedbackToType(id) {
    return {
        type: SELECT_FEEDBACK_TO_TYPE,
        id,
    };
}

export function selectFeedbackKey(id) {
    return {
        type: SELECT_FEEDBACK_KEY,
        id,
    };
}

export function selectMatchingStrategy(id) {
    return {
        type: SELECT_MATCHING_STRATEGY,
        id,
    };
}

export function selectProfile(id) {
    return {
        type: SELECT_PROFILE,
        id,
    };
}

function setFeedbackIds(feedbackIds) {
    return {
        type: SET_FEEDBACK_IDS,
        feedbackIds,
    };
}
