import React from "react";
import PropTypes from "prop-types";
import ValidatedInput from "~/components/ValidatedInput";

export default class FloatInput extends React.PureComponent {
    static propTypes = {
        value: PropTypes.number,
        defaultValue: PropTypes.number,
        className: PropTypes.string,
        allowNull: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        allowNull: false,
    };

    render() {
        const {value, defaultValue, className, allowNull, onChange} = this.props;

        return (
            <ValidatedInput
                value={value}
                defaultValue={defaultValue}
                valueToInput={valueToInput}
                inputToValue={allowNull ? inputToValueWithNull : inputToValue}
                isValidAsInput={isValidAsInput}
                isValidForConversion={allowNull ? isValidForConversionWithNull : isValidForConversion}
                className={className}
                onChange={onChange}
            />
        );
    }
}

const INPUT_REGEX = new RegExp(
    "^[-+]?[0-9]*\\.?[0-9]*$"
);

const VALIDATION_REGEX = INPUT_REGEX;

function isValidAsInput(input) {
    return INPUT_REGEX.test(input);
}

function isValidForConversion(input) {
    return input !== "" && input !== "." && VALIDATION_REGEX.test(input);
}

function isValidForConversionWithNull(input) {
    return input !== "." && VALIDATION_REGEX.test(input);
}

function valueToInput(value) {
    return value !== undefined && value !== null ? String(value) : "";
}

function inputToValue(input) {
    return parseFloat(input);
}

function inputToValueWithNull(input) {
    return input !== "" ? parseFloat(input) : null;
}
