import {connect} from "react-redux";
import MatchingStrategySelect from "~/components/MatchingStrategySelect";

function mapStateToProps(state) {
    return {
        allStrategies: state.matchingStrategy.originalAllStrategies,
    };
}

const mapDispatchToProps = {
    //
};

export default connect(mapStateToProps, mapDispatchToProps)(MatchingStrategySelect);
