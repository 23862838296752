import React from "react";
import {fromString} from "html-to-text";
import MatchQualityTable from "~/components/MatchDetailsDialog/MatchQualityTable";
import styles from "~/components/MatchDetailsDialog/styles.module.scss";
import config from "~/config";
import PropTypes from "prop-types";
import {FormattedMessage, injectIntl} from "react-intl";
import {reorganizeObject} from "~/util/object-reorganize";
import Table from "react-bootstrap/Table";
import PhoneNumber from "~/components/PhoneNumber";
import DOMPurify from "dompurify";
import {isString} from "~/util/misc";

class MatchDetailsOverview extends React.PureComponent {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
        extraPropertiesOrganization: PropTypes.object.isRequired,
        extraPropertyPhoneNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
        forwardLabel: PropTypes.node.isRequired,
        forwardSubLabel: PropTypes.node,
        reverseLabel: PropTypes.node.isRequired,
        hideForwardColumn: PropTypes.bool.isRequired,
        getMatchDisplayValue: PropTypes.func.isRequired,
        externalDetailsTabUrlFetcher: PropTypes.func,
        externalDetailsTabDefaultActive: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);

        this.additionalTextRef = React.createRef();
    }

    render() {
        return (
            <div>
                {this.renderDetailsTable()}
                {this.renderProfileDisplay()}
            </div>
        );
    }

    renderDetailsTable() {
        const {
            match,
            getMatchDisplayValue,
            forwardLabel,
            forwardSubLabel,
            reverseLabel,
            hideForwardColumn,
        } = this.props;

        const additionalTextExists = Boolean(getMatchDisplayValue(match, "additionalText"));

        return (
            <MatchQualityTable
                details={match.details}
                otherAspects={getMatchDisplayValue(match, "aspects")}
                forwardLabel={forwardLabel}
                forwardSubLabel={forwardSubLabel}
                reverseLabel={reverseLabel}
                hideForwardColumn={hideForwardColumn}
                onForwardLabelClick={
                    additionalTextExists ? this.handleForwardLabelClick : undefined
                }
                className={styles.qualityTable}
            />
        );
    }

    renderProfileDisplay() {
        const {match, getMatchDisplayValue} = this.props;
        const sections = config("ui.profileDisplay");

        return sections.map(section => {
            const value = getMatchDisplayValue(match, section);

            switch (section) {
                case "text":
                    return this.renderText(value);

                case "extraProperties":
                    return this.renderExtraProperties(value);

                case "educations":
                    return this.renderEducations(value);

                case "workExperiences":
                    return this.renderWorkExperiences(value);

                case "additionalText":
                    return this.renderText(value, this.additionalTextRef);

                default:
                    return this.renderUnknownSection(value);
            }
        });
    }

    renderText(text, ref) {
        if (!text) {
            return null;
        }

        let displayed;

        if (!text.value) {
            displayed = <FormattedMessage id="detailsModal.noText" />;
        } else if (containsHtml(text.value)) {
            displayed = <div dangerouslySetInnerHTML={{__html: sanitize(text.value)}} />;
        } else {
            displayed = <div className={styles.jobDescription}>{text.value}</div>;
        }

        return (
            <div key="text" ref={ref} className={styles.detailsSection}>
                <h5>
                    <FormattedMessage id={text.label} />
                </h5>
                <div>{displayed}</div>
            </div>
        );
    }

    renderExtraProperties(extraProperties) {
        if (!extraProperties) {
            return null;
        }

        const {extraPropertiesOrganization, extraPropertyPhoneNumbers} = this.props;
        const displayedExtraProperties = reorganizeObject(
            extraProperties,
            extraPropertiesOrganization
        );

        return (
            <div key="extraProperties" className={styles.detailsSection}>
                <h5>
                    <FormattedMessage id="detailsModal.extraProperties" />
                </h5>
                <Table size="sm" borderless={true} className={styles.extraPropertiesTable}>
                    <tbody>
                    {Object.keys(displayedExtraProperties).map(property => (
                        <tr key={property}>
                            <td className={styles.propertyColumn}>
                                <FormattedMessage.Quiet
                                    id={`extraProperties.${property}`}
                                    defaultMessage={property}
                                />
                            </td>
                            <td className={styles.valueColumn}>
                                {renderPropertyValue(
                                    property,
                                    displayedExtraProperties[property],
                                    `extraProperties.${property}`,
                                    {
                                        isPhoneNumber: extraPropertyPhoneNumbers.includes(
                                            property
                                        ),
                                    }
                                )}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
        );
    }

    renderEducations(educations) {
        if (!educations || !educations.length) {
            return null;
        }

        return (
            <div key="educations" className={styles.detailsSection}>
                <h5>
                    <FormattedMessage id="detailsModal.educations" />
                </h5>
                <Table size="sm" bordered={true}>
                    <tbody>
                    {educations.map(education => (
                        <tr key={education.id}>
                            <td>
                                <div className={styles.simpleDetailList}>
                                    {this.renderSimpleDetail(
                                        "education.dateFrom",
                                        education.dateFrom
                                    )}
                                    {this.renderSimpleDetail(
                                        "education.dateTo",
                                        education.dateTo
                                    )}
                                </div>
                                <div className={styles.simpleDetailList}>
                                    {this.renderSimpleDetail(
                                        "education.field",
                                        education.field
                                    )}
                                    {this.renderSimpleDetail(
                                        "education.degree",
                                        education.degree
                                    )}
                                    {this.renderSimpleDetail(
                                        "education.institute",
                                        education.institute
                                    )}
                                </div>
                                {education.info && <div>{education.info}</div>}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
        );
    }

    renderWorkExperiences(workExperiences) {
        if (!workExperiences || !workExperiences.length) {
            return null;
        }

        return (
            <div key="workExperiences" className={styles.detailsSection}>
                <h5>
                    <FormattedMessage id="detailsModal.workExperiences" />
                </h5>
                <Table bordered={true}>
                    <tbody>
                    {workExperiences.map(education => (
                        <tr key={education.id}>
                            <td>
                                <div className={styles.simpleDetailList}>
                                    {this.renderSimpleDetail(
                                        "workExperience.dateFrom",
                                        education.dateFrom
                                    )}
                                    {this.renderSimpleDetail(
                                        "workExperience.dateTo",
                                        education.dateTo
                                    )}
                                    {this.renderSimpleDetail(
                                        "workExperience.company",
                                        education.company
                                    )}
                                </div>
                                {this.renderSimpleDetail(
                                    "workExperience.title",
                                    education.title
                                )}
                                {education.text && <div>{education.text}</div>}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
        );
    }

    renderSimpleDetail(label, value) {
        return (
            value && (
                <div className={styles.simpleDetail}>
                    <div className={styles.simpleDetailTitle}>
                        <FormattedMessage id={label} />
                    </div>
                    <div className={styles.simpleDetailValue}>{value}</div>
                </div>
            )
        );
    }

    renderUnknownSection() {
        return null;
    }

    handleForwardLabelClick = () => {
        if (this.additionalTextRef.current) {
            this.additionalTextRef.current.scrollIntoView();
        }
    };
}

function renderPropertyValue(key, value, path, options) {
    const {isPhoneNumber} = options;

    if (Array.isArray(value)) {
        if (config("ui.temporaryCarerixNotesFilter", false) && key === "toDos") {
            value = value.filter(x => x.subject && !x.subject.startsWith("[SYS]"));
        }

        return value.map((value, index) =>
            renderPropertyValue(`${key}.${index}`, value, path, options)
        );
    } else if (value instanceof Object) {
        return renderObjectPropertyValue(key, value, path, options);
    } else if (value === true) {
        return (
            <React.Fragment key={key}>
                <i className="fas fa-check" /> <FormattedMessage id="boolean.true" />
            </React.Fragment>
        );
    } else if (value === false) {
        return (
            <React.Fragment key={key}>
                <i className="fas fa-times" /> <FormattedMessage id="boolean.false" />
            </React.Fragment>
        );
    } else if (isPhoneNumber) {
        return <PhoneNumber key={key} value={value}/>;
    } else if (isString(value) && config("ui.extraPropertiesTextMode") === "strip-html") {
        return <div key={key}>{fromString(value, {wordWrap: false})}</div>;
    } else {
        return <div key={key}>{value}</div>;
    }
}

function renderObjectPropertyValue(baseKey, value, path, options) {
    return (
        <Table key={baseKey} bordered={true} className={styles.objectTable}>
            <tbody>
            {Object.keys(value).map(key => (
                <tr key={key}>
                    <td className={styles.propertyColumn}>
                        <FormattedMessage.Quiet id={`${path}.${key}`} defaultMessage={key} />
                    </td>
                    <td style={{width: "100%"}}>
                        {renderPropertyValue(`${baseKey}.${key}`, value[key], `${path}.${key}`, options)}
                    </td>
                </tr>
            ))}
            </tbody>
        </Table>
    );
}

const CONTAINS_HTML_REGEX = /<\/?[a-z][\s\S]*>/i;

function containsHtml(text) {
    return CONTAINS_HTML_REGEX.test(text);
}

function sanitize(html) {
    return DOMPurify.sanitize(html, {
        ALLOWED_TAGS: ["b", "strong", "i", "em", "ul", "ol", "li", "div", "p"],
    });
}

export default injectIntl(MatchDetailsOverview, {forwardRef: true});
