import Api from "~/api";
import BackendSelection from "~/util/BackendSelection";
import ProfileType from "~/enums/ProfileType";
import {createCachedPromiseContainer} from "~/util/cached-promises";

const CACHE = createCachedPromiseContainer(
    (configuration, index, entityId) => {
        return `${index.selectionEndpoints.get}:${index.name}:${entityId}`;
    },
    (configuration, index, entityId) => {
        return Api.fetchSelection(index.selectionEndpoints.get, entityId)
            .catch(e => {
                console.error("Coulnd't get existing selection", e);
                return BackendSelection.empty();
            })
            .then(data => {
                data = convertOldStyleSelectionData(index.resultType, data);
                return BackendSelection.fromApi(data);
            });
    }
);

export function fetchBackendSelection(configuration, index, entityId, maximumAge) {
    if (index.selectionEndpoints.get.derivedFromIndex !== undefined) {
        return handleDerivedSelection(configuration, index, entityId, maximumAge);
    }

    return CACHE.get(maximumAge, configuration, index, entityId);
}

function handleDerivedSelection(configuration, index, entityId, maximumAge) {
    const sourceIndexName = index.selectionEndpoints.get.derivedFromIndex;
    const derivationKey = index.selectionEndpoints.get.derivationKey;

    for (const sourceIndex of configuration.indices) {
        if (sourceIndex.name === sourceIndexName) {
            return fetchBackendSelection(configuration, sourceIndex, entityId, maximumAge).then(backendSelection => {
                if (backendSelection.derived[derivationKey] !== undefined) {
                    return backendSelection.derived[derivationKey];
                } else {
                    return BackendSelection.empty();
                }
            });
        }
    }

    return Promise.resolve(BackendSelection.empty());
}

function convertOldStyleSelectionData(resultType, data) {
    if (!Array.isArray(data)) {
        return data;
    }

    if (resultType === ProfileType.CANDIDATE) {
        return {candidatesForJob: data};
    } else {
        return {jobsForCandidate: data};
    }
}