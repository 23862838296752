import React from "react";
import PropTypes from "prop-types";
import styles from "./app-loading.module.scss";
import Spinner from "react-bootstrap/Spinner";

export default class AppLoading extends React.PureComponent {
    static propTypes = {
        label: PropTypes.string,
    };

    render() {
        const {label} = this.props;

        return (
            <div className={styles.wrapper}>
                <div className={styles.content}>
                    <Spinner animation="border" role="status" />
                    {label && <h3>{label}</h3>}
                </div>
            </div>
        );
    }
}
