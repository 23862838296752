import React, {useMemo, useRef, useState} from "react";
import PropTypes from "prop-types";
import c from "classnames";
import {usePopper} from "react-popper";
import styles from "./styles.module.scss";

Range.propTypes = {
    value: PropTypes.number.isRequired,
    color: PropTypes.oneOf(["blue", "purple"]).isRequired,
    labels: PropTypes.arrayOf(PropTypes.node).isRequired,
    onChange: PropTypes.func.isRequired,
};

Range.defaultProps = {
    color: "blue",
    labels: [],
};

const HANDLE_SIZE = {width: 12, height: 12};

export default function Range(props) {
    const {className, color, labels, value, min, max, ...forwardedProps} = props;
    const [hovering, setHovering] = useState(false);
    const rangeElement = useRef(null);
    const popperElement = useRef(null);
    const arrowElement = useRef(null);
    const virtualReference = useMemo(() => {
        return {
            getBoundingClientRect() {
                const rangeElementBoundingRect = rangeElement.current.getBoundingClientRect();

                const fraction = (value - min) / (max - min);
                const top =
                    rangeElementBoundingRect.top +
                    rangeElementBoundingRect.height / 2 -
                    HANDLE_SIZE.height / 2;
                const left =
                    rangeElementBoundingRect.left +
                    fraction * (rangeElementBoundingRect.width - HANDLE_SIZE.width);

                return {
                    top,
                    bottom: top + HANDLE_SIZE.height,
                    left,
                    right: left + HANDLE_SIZE.width,
                    width: HANDLE_SIZE.width,
                    height: HANDLE_SIZE.height,
                };
            },
        };
    }, [value, min, max]);

    const popper = usePopper(virtualReference, popperElement.current, {
        placement: "top",
        strategy: "fixed",
        modifiers: [
            {name: "arrow", options: {element: arrowElement.current}},
            {name: "offset", options: {offset: [0, 5]}},
        ],
    });

    // TODO: handleChange is called WAY too often...
    const handleChange = e => {
        const value = parseInt(e.target.value, 10);
        if (isNaN(value)) return;
        props.onChange(value);
    };

    const label = getLabelForValue(labels, value, min, max);

    return (
        <React.Fragment>
            <input
                {...forwardedProps}
                type="range"
                value={value}
                min={min}
                max={max}
                className={c(
                    "custom-range",
                    {[`custom-range-${color}`]: color !== "blue"},
                    className
                )}
                onChange={handleChange}
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
                ref={rangeElement}
            />
            {labels.length > 0 && (
                <div
                    className={c(styles.tooltip, styles[`tooltip-${color}`], {
                        [styles.hidden]: !hovering || !label,
                    })}
                    style={popper.styles.popper}
                    {...popper.attributes.popper}
                    ref={popperElement}
                >
                    {label}
                    <div className={styles.arrow} style={popper.styles.arrow} ref={arrowElement} />
                </div>
            )}
        </React.Fragment>
    );
}

function getLabelForValue(labels, value, min, max) {
    if (labels.length === 0) return undefined;
    const fraction = (value - min) / (max - min);
    const index = Math.min(Math.floor(fraction * labels.length), labels.length - 1);
    return labels[index];
}
