import React from "react";
import PropTypes from "prop-types";
import CompetenceSubcategory from "./CompetenceSubcategory";

export default class Competence extends React.PureComponent {
    static propTypes = {
        competence: PropTypes.object.isRequired,
    };

    render() {
        const {competence} = this.props;
        const subcategories = competence.subcategories.sort(sortByMatchesDesc);

        return subcategories.map((subcategory, index) => {
            return (
                <CompetenceSubcategory
                    key={subcategory.label}
                    subcategory={subcategory}
                    categoryLabel={competence.label}
                    shouldRenderCategoryLabel={index === 0}
                />
            );
        });
    }
}

function sortByMatchesDesc(a, b) {
    if (a.aspectType === "UNKNOWN") {
        return 1;
    } else if (b.aspectType === "UNKNOWN") {
        return -1;
    }

    const result = matchesForSubcategory(b) - matchesForSubcategory(a);
    return result !== 0 ? result : a.label.localeCompare(b.label);
}

function matchesForSubcategory(subcategory) {
    return subcategory.matches.length;
}
