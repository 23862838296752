import {connect} from "react-redux";
import TextMiningNGrams from "~/pages/text-mining/TextMiningNGrams";
import {
    nGramsFrequencyMaxSelector,
    nGramsOrderSelector,
    oneGramsFrequencyMaxSelector,
    oneGramsOrderSelector, wordCloudDataSelector,
} from "~/selectors/text-mining";

function mapStateToProps(state) {
    return {
        oneGrams: state.textMining.oneGrams,
        oneGramsFrequencyMax: oneGramsFrequencyMaxSelector(state),
        oneGramsOrder: oneGramsOrderSelector(state),
        nGrams: state.textMining.nGrams,
        nGramsFrequencyMax: nGramsFrequencyMaxSelector(state),
        nGramsOrder: nGramsOrderSelector(state),
        worldCloudData: wordCloudDataSelector(state),
        fetching: state.textMining.fetching,
    };
}

const mapDispatchToProps = {
    //
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TextMiningNGrams);
