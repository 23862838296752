import React from "react";
import TranslatableLabelPropType from "~/prop-types/translatable-label";
import {isString} from "~/util/misc";
import {FormattedMessage} from "react-intl";

export default class TranslatableLabel extends React.PureComponent {
    static propTypes = {
        value: TranslatableLabelPropType.isRequired,
    };

    render() {
        const {value} = this.props;

        if (isString(value)) {
            return <React.Fragment>{value}</React.Fragment>
        } else {
            // TODO: Might need to be handled differently, we'll see when we get to it
            return <FormattedMessage {...value}/>
        }
    }
}

TranslatableLabel.getFinalValue = function(intl, label) {
    if (isString(label)) {
        return label;
    } else {
        return intl.formatMessage(label);
    }
}