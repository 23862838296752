import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import BasicPageLayout from "~/components/BasicPageLayout";
import Container from "react-bootstrap/Container";
import SchemaJsonEditor from "~/components/SchemaJsonEditor";
import Select from "~/components/Select";
import AddStrategyDialog from "~/containers/components/AddStrategyDialog";
import styles from "./styles.module.scss";
import {SaveStatus, Status} from "~/reducers/matching-strategy";
import Button from "react-bootstrap/Button";
import ProcessButton from "~/components/ProcessButton";
import {FormattedMessage} from "react-intl";

export default class MatchingStrategy extends React.PureComponent {
    static propTypes = {
        status: PropTypes.string.isRequired,
        saveStatus: PropTypes.string.isRequired,
        schema: PropTypes.object.isRequired,
        allStrategies: PropTypes.object.isRequired,
        defaultStrategies: PropTypes.object.isRequired,
        initialize: PropTypes.func.isRequired,
        updateStrategy: PropTypes.func.isRequired,
        removeStrategy: PropTypes.func.isRequired,
        saveChanges: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            editingStrategy: null,
            showingAddDialog: false,
        };
    }

    componentDidMount() {
        const {status, initialize} = this.props;
        if (status === Status.UNINITIALIZED) initialize();
    }

    static getDerivedStateFromProps(props, state) {
        const update = {};

        let editingStrategy = state.editingStrategy;

        if (editingStrategy !== null && props.allStrategies[editingStrategy] === undefined) {
            update.editingStrategy = null;
            editingStrategy = null;
        }

        if (editingStrategy === null && Object.keys(props.allStrategies).length > 0) {
            update.editingStrategy = Object.keys(props.allStrategies)[0];
        }

        return Object.keys(update).length > 0 ? update : null;
    }

    render() {
        return <BasicPageLayout>{this.renderPage()}</BasicPageLayout>;
    }

    renderPage() {
        const {status, schema, allStrategies} = this.props;
        const {editingStrategy, showingAddDialog} = this.state;

        if (status !== Status.INITIALIZED) return null;

        return (
            <React.Fragment>
                <Container className={c("mt-4", styles.page)}>
                    <div className="d-flex align-items-baseline mb-4">
                        <h3>
                            <FormattedMessage id="matchingStrategy.parametersFor" />
                        </h3>
                        <Select
                            className={c(styles.select, "ml-2")}
                            selected={editingStrategy}
                            onChange={this.handleEditingStrategyChange}
                            options={this.options()}
                        />
                        <Button
                            variant="outline-danger"
                            className="ml-auto text-nowrap"
                            onClick={this.handleDeleteClick}
                            disabled={editingStrategy === null}
                        >
                            <FormattedMessage id="matchingStrategy.delete" />
                        </Button>
                        <Button
                            variant="outline-success"
                            className="ml-4 text-nowrap"
                            onClick={this.handleAddClick}
                        >
                            <FormattedMessage id="matchingStrategy.add" />
                        </Button>
                        {this.renderSaveButton()}
                    </div>
                    {editingStrategy !== null && (
                        <SchemaJsonEditor
                            value={allStrategies[editingStrategy]}
                            schema={schema}
                            className={styles.editor}
                            onChange={this.handleChange}
                        />
                    )}
                </Container>
                {showingAddDialog && (
                    <AddStrategyDialog onAdd={this.handleAdd} onHide={this.handleHideAddDialog} />
                )}
            </React.Fragment>
        );
    }

    renderSaveButton() {
        const {saveStatus} = this.props;

        return (
            <div>
                <ProcessButton
                    label={<FormattedMessage id="matchingStrategy.save" />}
                    processingLabel={<FormattedMessage id="matchingStrategy.saving" />}
                    variant="outline-primary"
                    className="ml-4 text-nowrap"
                    disabled={saveStatus !== SaveStatus.CHANGED}
                    processing={saveStatus === SaveStatus.SAVING}
                    onClick={this.handleSaveClick}
                />
            </div>
        );
    }

    options() {
        const {allStrategies} = this.props;
        const names = Object.keys(allStrategies);

        if (names.length > 0) {
            return names.map(name => ({
                label: name,
                value: name,
            }));
        } else {
            return [
                {
                    label: "—",
                    value: null,
                    disabled: true,
                },
            ];
        }
    }

    handleAddClick = () => {
        this.setState({showingAddDialog: true});
    };

    handleDeleteClick = () => {
        const {removeStrategy} = this.props;
        const {editingStrategy} = this.state;
        removeStrategy(editingStrategy);
    };

    handleHideAddDialog = () => {
        this.setState({showingAddDialog: false});
    };

    handleEditingStrategyChange = option => {
        this.setState({editingStrategy: option.value});
    };

    handleChange = value => {
        const {updateStrategy} = this.props;
        const {editingStrategy} = this.state;
        updateStrategy(editingStrategy, value);
    };

    handleAdd = (name, propTypes) => {
        const {defaultStrategies, updateStrategy} = this.props;
        updateStrategy(name, defaultStrategies[propTypes]);
        this.handleHideAddDialog();
        this.setState({editingStrategy: name});
    };

    handleSaveClick = () => {
        this.props.saveChanges();
    };
}
