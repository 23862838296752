import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import ExpandButton from "~/components/SchemaJsonEditor/ExpandButton/ExpandButton";
import {renderEditorForSchema} from "~/components/SchemaJsonEditor/util";
import {createEmptyForSchema} from "~/util/schema";
import styles from "./styles.module.scss";

export default class ArrayEditor extends React.PureComponent {
    static propTypes = {
        schema: PropTypes.object.isRequired,
        theKey: PropTypes.any,
        value: PropTypes.array,
        onChange: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            isExpanded: true,
        };
    }

    render() {
        const {isExpanded} = this.state;

        return (
            <React.Fragment>
                {this.renderHeader()}
                {isExpanded && (
                    <div className={styles.children}>
                        {this.renderItems()}
                        {this.renderAddItem()}
                    </div>
                )}
            </React.Fragment>
        );
    }

    renderHeader() {
        const {theKey, renderKey} = this.props;
        const {isExpanded} = this.state;

        if (theKey === undefined) {
            return null;
        }

        return (
            <div className={c(styles.editor, styles.noHover)}>
                {renderKey(
                    theKey,
                    <ExpandButton value={isExpanded} onChange={this.handleExpandedChange} />
                )}
                <div className={styles.value} />
            </div>
        );
    }

    renderItems() {
        const {schema, value} = this.props;

        return value.map((item, index) =>
            renderEditorForSchema(schema.items, {
                key: String(index),
                theKey: index,
                value: item,
                renderKey: this.renderItemKey,
                onChange: this.handleItemChange,
            })
        );
    }

    renderItemKey = (key, childContent) => {
        return (
            <div className={c(styles.key)}>
                <div className="d-flex align-items-center">
                    {childContent || <div className={styles.icon}>•</div>}
                    Item {key + 1}
                    <div
                        className={c("ml-auto", styles.onEditorHover, styles.link, styles.linkRed)}
                        onClick={this.handleRemoveItem.bind(this, key)}
                    >
                        <div className="d-flex align-items-center">
                            <div className={styles.icon}>
                                <i className="far fa-trash-alt" />
                            </div>
                            <div className={styles.linkText}>Remove</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderAddItem() {
        return (
            <div className={c(styles.editor, styles.noHover)}>
                <div className={c(styles.key, styles.link)} onClick={this.handleAddItem}>
                    <div className="d-flex align-items-center">
                        <div className={styles.icon}>＋</div>
                        <div className={styles.linkText}>Add item</div>
                    </div>
                </div>
                <div className={styles.value} />
            </div>
        );
    }

    handleExpandedChange = isExpanded => {
        this.setState({isExpanded});
    };

    handleItemChange = (key, value) => {
        const {theKey, value: arrayValue, onChange} = this.props;

        const nextArrayValue = [...arrayValue];
        nextArrayValue[key] = value;
        onChange(theKey, nextArrayValue);
    };

    handleAddItem = () => {
        const {schema, theKey, value, onChange} = this.props;
        onChange(theKey, [...value, createEmptyForSchema(schema.items)]);
    };

    handleRemoveItem = key => {
        const {theKey, value, onChange} = this.props;
        const nextValue = [...value];
        nextValue.splice(key, 1);
        onChange(theKey, nextValue);
    };
}
