import urlJoin from "url-join";
import abortableCall from "~/api/abortable-call";
import {setInternalToken} from "~/api/client";
import {grabOneTimePasswordFromQuery} from "~/util/security";

export async function exchangeOneTimePassword(apiUrl, endpoint, otpFoundCallback) {
    if (apiUrl === undefined || endpoint === undefined) {
        return;
    }

    const otp = grabOneTimePasswordFromQuery();

    if (!otp) {
        return;
    }

    otpFoundCallback();

    return abortableCall("post", urlJoin(apiUrl, endpoint), {}, {otp}).then(({response}) => {
        setInternalToken(response.token);
    });
}
