import React from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Breadcrumbs from "~/components/text-mining/Breadcrumbs";
import SimplePage from "~/components/SimplePage";

export default class TextMiningUnmatched extends React.PureComponent {
    static propTypes = {
        unmatched: PropTypes.array.isRequired,
        fetching: PropTypes.bool.isRequired,
    };

    render() {
        const {fetching} = this.props;

        return (
            <SimplePage subMenu={() => <Breadcrumbs />} isLoading={fetching}>
                <Row>
                    <Col>{this.renderUnmatchedTable()}</Col>
                </Row>
            </SimplePage>
        );
    }

    renderUnmatchedTable() {
        const {unmatched} = this.props;

        return (
            <Table bordered>
                <tbody>
                    {unmatched.map((comment, index) => (
                        <tr key={index}>
                            <td>{comment}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    }
}
