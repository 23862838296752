import Api from "~/api";
import {getDetailsTitleAsConfigured} from "~/util/match";
import ProfileType from "~/enums/ProfileType";
import {getNameForJobMatch} from "~/components/JobMatchList/JobMatchList";

const State = {
    NOT_LOADED: "notLoaded",
    LOADING: "loading",
    LOADED: "loaded",
    ERROR: "error",
};

/**
 * Specifically meant for use on profile-to-candidates and profile-to-jobs pages to help handling loadin the match profile
 * when matching from a job/candidate.
 */
export default class MatchSourceEntity {
    constructor(type, index, id, matchingStrategies, language) {
        this.type = type;
        this.index = index;
        this.id = id;
        this.state = State.NOT_LOADED;
        this.matchingStrategies = matchingStrategies;
        this.language = language || "UNDEFINED";
        this._documentPromise = undefined; // promise
        this._document = undefined; // actual data
    }

    static fromMatchParams(indices, matchParams, defaultType, matchingStrategies, language) {
        if (!matchParams) return undefined;

        let type = matchParams.type;
        let index = matchParams.index;
        const id = matchParams.id;

        if (!indices || !id) {
            return undefined;
        }

        switch (type) {
            case "candidate":
                type = ProfileType.CANDIDATE;
                break;

            case "job":
                type = ProfileType.JOB;
                break;

            default:
                type = defaultType;
                break;
        }

        if (!type) {
            return undefined;
        }

        if (!index) {
            index = indices[type];
        }

        if (!index) {
            return undefined;
        }

        return new MatchSourceEntity(type, index, id, matchingStrategies, language);
    }

    static areSame(a, b) {
        if (a === undefined && b === undefined) {
            return true;
        } else if (a !== undefined && b !== undefined) {
            return a.equals(b);
        } else {
            return false;
        }
    }

    get documentPromise() {
        if (this.state === State.NOT_LOADED) {
            this._documentPromise = this._fetchDocument();
            this._documentPromise
                .then(document => {
                    this._document = document;
                })
                .catch(e => {
                    this._document = null;
                    throw e;
                });
        }

        return this._documentPromise;
    }

    get document() {
        return this._document;
    }

    get hasLoaded() {
        return this._document !== undefined && this._document !== null;
    }

    get hasFailedLoading() {
        return this._document === null;
    }

    get displayName() {
        if (!this.document) {
            return undefined;
        }

        switch (this.type) {
            case ProfileType.JOB:
                return getNameForJobMatch({job: this.document});

            case ProfileType.CANDIDATE:
                return getDetailsTitleAsConfigured(this.document);

            default:
                return undefined;
        }
    }

    equals(other) {
        return this.type === other.type && this.id === other.id;
    }

    _fetchDocument() {
        switch (this.type) {
            case ProfileType.JOB:
                return this._fetchJobDocument();

            case ProfileType.CANDIDATE:
                return this._fetchCandidateDocument();

            default:
                throw new Error("Unsupported MatchSourceEntity type: " + this.type);
        }
    }

    _fetchJobDocument() {
        this.state = State.LOADING;

        return Api.fetchJob(
            this.index,
            this.id,
            this.matchingStrategies ? this.matchingStrategies.jobMatchingStrategy : undefined,
            this.convertedLanguage()
        )
            .then(job => {
                this.state = State.LOADED;
                return job;
            })
            .catch(error => {
                this.state = State.ERROR;
                throw error;
            });
    }

    _fetchCandidateDocument() {
        this.state = State.LOADING;

        return Api.fetchCandidate(
            this.index,
            this.id,
            this.matchingStrategies ? this.matchingStrategies.candidateMatchingStrategy : undefined,
            this.convertedLanguage()
        )
            .then(candidate => {
                this.state = State.LOADED;
                return candidate;
            })
            .catch(error => {
                this.state = State.ERROR;
                throw error;
            });
    }

    convertedLanguage() {
        switch (this.language) {
            case "nl":
                return "DUT";

            case "fr":
                return "FRE";

            case "en":
                return "ENG";

            case "it":
                return "ITA";

            default:
                return this.language;
        }
    }
}

MatchSourceEntity.State = State;
