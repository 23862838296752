import React from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import TextMiningInput from "~/containers/pages/text-mining/TextMiningInput";
import TextMiningResults from "~/containers/pages/text-mining/TextMiningResults";
import TextMiningNGrams from "~/containers/pages/text-mining/TextMiningNGrams";
import TextMiningUnmatched from "~/containers/pages/text-mining/TextMiningUnmatched";

export default class TextMining extends React.PureComponent {
    render() {
        const {match} = this.props;

        return (
            <Switch>
                <Route path={`${match.path}/input`} component={TextMiningInput} />
                <Route path={`${match.path}/results`} component={TextMiningResults} />
                <Route path={`${match.path}/ngrams`} component={TextMiningNGrams} />
                <Route path={`${match.path}/unmatched`} component={TextMiningUnmatched} />
                <Redirect exact from={`${match.path}`} to={`${match.path}/input`} />
            </Switch>
        );
    }
}
