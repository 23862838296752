import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import Table from "react-bootstrap/Table";
import Row from "./Row";
import styles from "./styles.module.scss";
import {FormattedMessage} from "react-intl";

export default class OverviewTable extends React.PureComponent {
    static propTypes = {
        orderedProfiles: PropTypes.array.isRequired,
        selectedProfile: PropTypes.string,
        className: PropTypes.string,
        onSelect: PropTypes.func.isRequired,
    };

    render() {
        const {className} = this.props;

        return (
            <Table bordered striped className={c(styles.table, className)}>
                <thead>
                    <tr>
                        <th><FormattedMessage id="matchingTest.overview.header.matchRequest"/></th>
                        <th className={styles.countColumn}><FormattedMessage id="matchingTest.overview.header.hits"/></th>
                        <th className={styles.countColumn}><FormattedMessage id="matchingTest.overview.header.falsePositives"/></th>
                        <th className={styles.countColumn}><FormattedMessage id="matchingTest.overview.header.missed"/></th>
                        <th className={styles.fixedWidthColumn}><FormattedMessage id="matchingTest.overview.header.precision"/></th>
                        <th className={styles.fixedWidthColumn}><FormattedMessage id="matchingTest.overview.header.recall"/></th>
                    </tr>
                </thead>
                <tbody>{this.renderRows()}</tbody>
            </Table>
        );
    }

    renderRows() {
        const {orderedProfiles, selectedProfile} = this.props;

        return orderedProfiles.map(profile => (
            <Row
                key={profile.id}
                value={profile}
                selected={profile.id === selectedProfile}
                onClick={this.handleRowClick}
            />
        ));
    }

    handleRowClick = id => {
        this.props.onSelect(id);
    };
}
