import * as immutable from "object-path-immutable";
import {CLOSE_FIRST_ERROR_MESSAGE, TRIGGER_ERROR_MESSAGE, UPDATE_LANGUAGE} from "~/actions/ui";
import config from "~/config";

const INITIAL_STATE = {
    configReady: false,
    language: config("api.defaultLanguage"),
    pendingErrorMessages: [],
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case UPDATE_LANGUAGE:
            return updateLanguage(state, action.language);

        case TRIGGER_ERROR_MESSAGE:
            return triggerErrorMessage(state, action.errorMessage);

        case CLOSE_FIRST_ERROR_MESSAGE:
            return closeFirstErrorMessage(state);

        default:
            return state;
    }
}

function updateLanguage(state, language) {
    return {...state, language};
}

function triggerErrorMessage(state, errorMessage) {
    return immutable.push(state, "pendingErrorMessages", errorMessage);
}

function closeFirstErrorMessage(state) {
    const [_, ...pendingErrorMessages] = state.pendingErrorMessages;
    return {...state, pendingErrorMessages};
}
