import React from "react";
import PropTypes from "prop-types";
import ValidatedInput from "~/components/ValidatedInput";

export default class NumberInput extends React.PureComponent {
    static propTypes = {
        value: PropTypes.number,
        defaultValue: PropTypes.number,
        onChange: PropTypes.func.isRequired,
    };

    render() {
        const {value, defaultValue, onChange} = this.props;

        return (
            <ValidatedInput
                {...this.props}
                value={value}
                defaultValue={defaultValue}
                valueToInput={valueToInput}
                inputToValue={inputToValue}
                isValidAsInput={isValidAsInput}
                isValidForConversion={isValidForConversion}
                onChange={onChange}
            />
        );
    }
}

const INPUT_REGEX = new RegExp("^-?[0-9.]*$");

function isValidAsInput(input) {
    return INPUT_REGEX.test(input);
}

function isValidForConversion(input) {
    return input.trim() === "" || (input !== "-" && !isNaN(parseFloat(input)));
}

function valueToInput(value) {
    if (value !== undefined && value !== null && !isNaN(value)) {
        return String(value);
    } else {
        return "";
    }
}

function inputToValue(input) {
    return input.trim() !== "" ? parseFloat(input) : undefined;
}