import React from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import SimplePage from "~/components/SimplePage";
import Breadcrumbs from "~/components/text-mining/Breadcrumbs";
import {FormattedMessage} from "react-intl";

export default class TextMiningInput extends React.PureComponent {
    static propTypes = {
        input: PropTypes.string.isRequired,
        history: PropTypes.object.isRequired,
        updateInput: PropTypes.func.isRequired,
        textMiningParse: PropTypes.func.isRequired,
        showAllBreadcrumbs: PropTypes.bool.isRequired,
    };

    render() {
        const {input, showAllBreadcrumbs} = this.props;

        return (
            <SimplePage subMenu={() => <Breadcrumbs showAll={showAllBreadcrumbs} />}>
                <Row>
                    <Col>
                        <Form.Control
                            as="textarea"
                            rows="20"
                            value={input}
                            onChange={this.handleChange}
                            className="mb-4"
                        />
                        <Button variant="success" onClick={this.handleParseClick}>
                            <FormattedMessage id="action.parse"/>
                        </Button>
                    </Col>
                </Row>
            </SimplePage>
        );
    }

    handleChange = e => {
        this.props.updateInput(e.target.value);
    };

    handleParseClick = () => {
        const {input, textMiningParse, history} = this.props;
        textMiningParse(input);
        history.push("/text-mining/results");
    };
}
