import React from "react";
import PropTypes from "prop-types";
import Pagination from 'react-bootstrap/Pagination';

export default class Item extends React.PureComponent {
    static propTypes = {
        page: PropTypes.number.isRequired,
        active: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired,
    };

    render() {
        const {page, active} = this.props;

        return (
            <Pagination.Item active={active} onClick={this.handleClick}>
                {page + 1}
            </Pagination.Item>
        )
    }

    handleClick = () => {
        const {page, onClick} = this.props;
        onClick(page);
    }
}