import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import Table from "react-bootstrap/Table";
import Competence from "~/components/text-mining/MainCategory/Competence";
import styles from "./main-category.module.scss";

export default class MainCategory extends React.PureComponent {
    static propTypes = {
        aspect: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            expanded: true,
        };
    }

    render() {
        const {aspect} = this.props;

        if (aspect.competences.length === 0) {
            return null;
        }

        return (
            <div className={styles.mainCategory}>
                {this.renderHeader()}
                {this.renderMatchTable()}
            </div>
        );
    }

    renderHeader() {
        const {name} = this.props;
        const {expanded} = this.state;

        return (
            <div className={c(styles.header, {expanded})} onClick={this.handleToggleExanded}>
                <i className={c("fas", {"fa-caret-right": !expanded, "fa-caret-down": expanded})} />
                {name}
            </div>
        );
    }

    renderMatchTable() {
        const {aspect} = this.props;
        const {expanded} = this.state;
        const competences = aspect.competences.sort(sortCompetenceByMatchesDesc);

        return (
            <div className={c(styles.tableWrapper, {expanded})}>
                <Table className={styles.table}>
                    <colgroup>
                        <col className={styles.categoryColumn} />
                        <col className={styles.subcategoryColumn} />
                        <col className={styles.countColumn} />
                        <col className={styles.matchColumn} />
                        <col className={styles.contextToggleColumn} />
                    </colgroup>
                    <tbody>
                        {competences.map(competence => (
                            <Competence key={competence.label} competence={competence} />
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    }

    handleToggleExanded = () => {
        this.setState(state => ({
            expanded: !state.expanded,
        }));
    };
}

function sortCompetenceByMatchesDesc(a, b) {
    if (a.aspectType === "UNKNOWN") {
        return 1;
    } else if (b.aspectType === "UNKNOWN") {
        return -1;
    }

    const result = highestMatchesForCompetence(b) - highestMatchesForCompetence(a);
    return result !== 0 ? result : a.label.localeCompare(b.label);
}

function highestMatchesForCompetence(competence) {
    let highest = 0;

    for (const subcategory of competence.subcategories) {
        const matches = subcategory.matches.length;
        if (matches > highest) highest = matches;
    }

    return highest;
}
