import React from "react";
import ReactDOM from "react-dom";
import AppLoading from "~/components/AppLoading";

let timeout = undefined;
let currentElement = undefined;

function displayAfterDelay(element, delay) {
    removeIfDisplayed();

    timeout = setTimeout(() => {
        timeout = undefined;
        ReactDOM.render(<AppLoading />, element);
    }, delay);

    currentElement = element;
}

function removeIfDisplayed() {
    if (timeout !== undefined) {
        clearTimeout(timeout);
        timeout = undefined;
    }

    if (currentElement !== undefined) {
        ReactDOM.unmountComponentAtNode(currentElement);
    }
}

export default {
    displayAfterDelay,
    removeIfDisplayed,
};
