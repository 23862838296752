import React from "react";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import Select from "~/components/Select";

class MatchingStrategySelect extends React.PureComponent {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        selected: PropTypes.string,
        allowEmpty: PropTypes.bool.isRequired,
        allStrategies: PropTypes.object.isRequired,
        className: PropTypes.string,
        variant: PropTypes.string,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        allowEmpty: false,
    };

    render() {
        const {selected, className, variant} = this.props;

        return (
            <Select
                className={className}
                variant={variant}
                selected={selected}
                onChange={this.handleChange}
                options={this.options()}
            />
        );
    }

    options() {
        const {intl, allStrategies, allowEmpty} = this.props;
        const names = Object.keys(allStrategies);

        const options = [];

        if (allowEmpty) {
            options.push({
                label: intl.formatMessage({id: "matchingStrategySelect.none"}),
                value: null,
            });
        } else if (names.length === 0) {
            return [
                {
                    label: "—",
                    value: null,
                    disabled: true,
                },
            ];
        }

        for (const name of names) {
            options.push({
                label: name,
                value: name,
            });
        }

        return options;
    }

    handleChange = option => {
        this.props.onChange(option.value);
    };
}

export default injectIntl(MatchingStrategySelect, {forwardRef: true});
