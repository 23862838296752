import React from "react";
import PropTypes from "prop-types";
import Checkbox from "~/components/Checkbox";
import styles from "./styles.module.scss";

export default class BooleanEditor extends React.PureComponent {
    static propTypes = {
        schema: PropTypes.object.isRequired,
        theKey: PropTypes.any,
        isKeyEditable: PropTypes.bool,
        value: PropTypes.bool,
        renderKey: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        renderKey: () => null,
    };

    render() {
        const {theKey, value, renderKey} = this.props;

        return (
            <div className={styles.editor}>
                {renderKey(theKey)}
                <div className={styles.value} onClick={this.handleToggle}>
                    <Checkbox
                        type="switch"
                        color="green"
                        onChange={this.handleChange}
                        checked={value}
                    />
                </div>
            </div>
        );
    }

    handleToggle = () => {
        this.handleChange(!this.props.value);
    };

    handleChange = value => {
        const {theKey, onChange} = this.props;
        onChange(theKey, value);
    };
}
