import {connect} from "react-redux";
import TextMiningUnmatched from "~/pages/text-mining/TextMiningUnmatched";

function mapStateToProps(state) {
    return {
        unmatched: state.textMining.results.unmatched,
        fetching: state.textMining.fetching,
    };
}

const mapDispatchToProps = {
    //
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TextMiningUnmatched);
