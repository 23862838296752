import Validator from "~/config/validation/validators/Validator";
import {childPath} from "~/config/validation/validators/util";

export default class ArrayOfValidator extends Validator {
    constructor(description, itemValidator) {
        super(description, undefined);
        this.itemValidator = itemValidator;
    }

    /**
     * @param {any} value
     * @param {string|undefined} path
     * @return {array}
     */
    validate(value, path = undefined) {
        const result = [];

        for (let index = 0; index < value.length; index++) {
            result.push(this.itemValidator.validate(value[index], childPath(path, index)));
        }

        return result;
    }
}
