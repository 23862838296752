import {connect} from "react-redux";
import TextMiningResults from "~/pages/text-mining/TextMiningResults";

function mapStateToProps(state) {
    return {
        feedback: state.textMining.results.feedback,
        fetching: state.textMining.fetching,
    };
}

const mapDispatchToProps = {
    //
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TextMiningResults);
