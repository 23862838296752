import "core-js/stable";
import "./index.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import "./App.scss";

import {bootstrap, displayConfigurationError} from "./bootstrap";
import {setGlobalConfigAndTranslations} from "~/config";
import {grabInternalTokenFromQuery} from "~/util/security";

import "~/util/object-reorganize";
import ConfigLoadingIndicator from "~/util/ConfigLoadingIndicator";

// TODO: Redesign bootstrapping to allow rendering the App immediatly, even when configuration is incomplete, so that we
//       can display a loading screen.

grabInternalTokenFromQuery();

ConfigLoadingIndicator.displayAfterDelay(document.getElementById("root"), 500);

setGlobalConfigAndTranslations()
    .then(() => {
        ConfigLoadingIndicator.removeIfDisplayed();
        bootstrap();
    })
    .catch(error => {
        console.error(error);
        ConfigLoadingIndicator.removeIfDisplayed();
        displayConfigurationError(error);
    });
