import Validator from "~/config/validation/validators/Validator";
import {childPath} from "~/config/validation/validators/util";

export default class ShapeValidator extends Validator {
    constructor(description, shape) {
        super(description, undefined);
        this.shape = shape;
    }

    /**
     * @param {any} value
     * @param {string|undefined} path
     * @return {object}
     */
    validate(value, path = undefined) {
        const result = {};

        for (const key in this.shape) {
            result[key] = this.shape[key].validate(value[key], childPath(path, key));
        }

        for (const key in value) {
            if (this.shape[key] !== undefined) {
                continue;
            }

            console.warn(`Unknown configuration value at [${childPath(path, key)}]`);
            result[key] = value[key];
        }

        return result;
    }

    defaultValue() {
        throw new Error("TODO");
    }
}
