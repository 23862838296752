export const UPDATE_LANGUAGE = "ui/UPDATE_LANGUAGE";
export const TRIGGER_ERROR_MESSAGE = "ui/TRIGGER_ERROR_MESSAGE";
export const CLOSE_FIRST_ERROR_MESSAGE = "ui/CLOSE_FIRST_ERROR_MESSAGE";

export function updateLanguage(language) {
    return {
        type: UPDATE_LANGUAGE,
        language,
    };
}

export function triggerErrorMessage(errorMessage) {
    return {
        type: TRIGGER_ERROR_MESSAGE,
        errorMessage,
    };
}

export function closeFirstErrorMessage() {
    return {
        type: CLOSE_FIRST_ERROR_MESSAGE,
    };
}
