import Api from "~/api";

export const SET_SOURCE_AND_TARGET = "standalone-match-details/SET_SOURCE_AND_TARGET";
export const START_MATCH = "standalone-match-details/START_MATCH";
export const MATCH_SUCCESS = "standalone-match-details/MATCH_SUCCESS";
export const MATCH_FAILURE = "standalone-match-details/MATCH_FAILURE";

export function setSourceAndTarget(sourceIndex, sourceId, targetIndex, targetId) {
    return dispatch => {
        dispatch(startMatch(sourceIndex, sourceId, targetIndex, targetId));

        return Api.matchDetailsForJobToCandidate(sourceIndex, sourceId, targetIndex, targetId)
            .then(matches => {
                if (matches.length > 0) {
                    dispatch(matchSuccess(matches[0]));
                } else {
                    dispatch(matchFailure("No result"));
                }
            })
            .catch(error => {
                dispatch(matchFailure(error));
                throw error;
            });
    };
}

function startMatch(sourceIndex, sourceId, targetIndex, targetId) {
    return {
        type: START_MATCH,
        sourceIndex,
        sourceId,
        targetIndex,
        targetId,
    };
}

function matchSuccess(result) {
    return {
        type: MATCH_SUCCESS,
        result,
    };
}

function matchFailure(error) {
    return {
        type: MATCH_FAILURE,
        error,
    };
}
