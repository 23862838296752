export function getCandidateMatchDisplayValue(match, section) {
    switch (section) {
        case "text":
            return {
                label: "detailsModal.candidateText",
                value: match.candidate.description,
            };

        case "extraProperties":
            return match.candidate[section];

        case "educations":
        case "workExperiences":
            return match.candidate[section];

        case "aspects":
            return match.candidate.matchProfile.aspects;

        default:
            return undefined;
    }
}

export function getJobMatchDisplayValue(match, section) {
    switch (section) {
        case "text":
            return {
                label: "detailsModal.jobText",
                value: match.job.description,
            };

        case "extraProperties":
            return match.job[section];
            
        case "educations":
        case "workExperiences":
            return match.job[section];

        case "aspects":
            return match.job.matchProfile.aspects;

        default:
            return undefined;
    }
}
