import {createSelector} from "reselect";

const oneGramsSelector = state => state.textMining.oneGrams;
const nGramsSelector = state => state.textMining.nGrams;

export const oneGramsOrderSelector = createSelector(
    oneGramsSelector,
    oneGrams => {
        return keysSortedByValue(oneGrams);
    }
);

export const oneGramsFrequencyMaxSelector = createSelector(
    oneGramsSelector,
    oneGrams => {
        return valuesMax(oneGrams);
    }
);

export const nGramsOrderSelector = createSelector(
    nGramsSelector,
    nGrams => {
        return keysSortedByValue(nGrams);
    }
);

export const nGramsFrequencyMaxSelector = createSelector(
    nGramsSelector,
    nGrams => {
        return valuesMax(nGrams);
    }
);

export const wordCloudDataSelector = createSelector(
    [oneGramsSelector, oneGramsOrderSelector],
    (oneGrams, oneGramsOrder) => {
        return oneGramsOrder.slice(0, 100).map(oneGram => ({
            text: oneGram,
            value: oneGrams[oneGram],
            randomDirection: Math.random() >= 0.5,
        }));
    }
);

function keysSortedByValue(obj) {
    return Object.keys(obj).sort((a, b) => obj[b] - obj[a]);
}

function valuesMax(obj) {
    return Object.values(obj).reduce((sum, value) => Math.max(sum, value), 0);
}
