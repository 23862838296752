import PropTypes from "prop-types";
import ReactFauxDom from "react-faux-dom";
import cloud from "d3-cloud";
import React from "react";
import {scaleOrdinal} from "d3-scale";
import {select} from "d3-selection";

export default class WordCloud extends React.PureComponent {
    static propTypes = {
        data: PropTypes.arrayOf(
            PropTypes.shape({
                text: PropTypes.string.isRequired,
                value: PropTypes.number.isRequired,
            })
        ).isRequired,
        font: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        fontSizeMapper: PropTypes.func,
        height: PropTypes.number,
        padding: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
        rotate: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
        width: PropTypes.number,
        onWordClick: PropTypes.func,
        onWordMouseOut: PropTypes.func,
        onWordMouseOver: PropTypes.func,
    };

    static defaultProps = {
        width: 700,
        height: 600,
        padding: 5,
        font: "serif",
        rotate: 0,
        onWordClick: null,
        onWordMouseOver: null,
        onWordMouseOut: null,
    };

    UNSAFE_componentWillMount() {
        this.wordCloud = ReactFauxDom.createElement("div");
    }

    render() {
        const {
            data,
            width,
            height,
            padding,
            font,
            fontSizeMapper,
            rotate,
            onWordClick,
            onWordMouseOver,
            onWordMouseOut,
        } = this.props;

        // clear old words
        select(this.wordCloud)
            .selectAll("*")
            .remove();

        // render based on new data
        const layout = cloud()
            .size([width, height])
            .font(font)
            .words(data)
            .padding(padding)
            .rotate(rotate)
            .fontSize(fontSizeMapper)
            .on("end", words => {
                const texts = select(this.wordCloud)
                    .append("svg")
                    .attr("viewBox", `0 0 ${layout.size()[0]} ${layout.size()[1]}`)
                    .append("g")
                    .attr("transform", `translate(${layout.size()[0] / 2},${layout.size()[1] / 2})`)
                    .selectAll("text")
                    .data(words)
                    .enter()
                    .append("text")
                    .style("font-size", d => `${d.size}px`)
                    .style("font-family", font)
                    .style("fill", (d, i) => fill(i))
                    .attr("text-anchor", "middle")
                    .attr("transform", d => `translate(${[d.x, d.y]})rotate(${d.rotate})`)
                    .text(d => d.text);

                if (onWordClick) {
                    texts.on("click", onWordClick);
                }
                if (onWordMouseOver) {
                    texts.on("mouseover", onWordMouseOver);
                }
                if (onWordMouseOut) {
                    texts.on("mouseout", onWordMouseOut);
                }
            });

        layout.start();

        return this.wordCloud.toReact();
    }
}

const fill = scaleOrdinal([
    '#DF5D33',
    '#F2B446',
    '#9E3656',
    '#3CA1CB',
    '#FEEF35',
    '#BBAE93',
    '#179B90',
]);
