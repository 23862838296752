import React from "react";
import PropTypes from "prop-types";
import BaseBreadcrumbs from "~/components/Breadcrumbs";
import {injectIntl} from "react-intl";

class Breadcrumbs extends React.PureComponent {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        showAll: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        showAll: true,
    };

    render() {
        const {intl} = this.props;

        const breadcrumbs = [
            {
                label: intl.formatMessage({id: "textMining.breadcrumbs.input"}),
                link: "/text-mining/input",
            },
        ];

        if (this.props.showAll) {
            breadcrumbs.push({label: intl.formatMessage({id: "textMining.breadcrumbs.results"}), link: "/text-mining/results"});
            breadcrumbs.push({label: intl.formatMessage({id: "textMining.breadcrumbs.ngrams"}), link: "/text-mining/ngrams"});
            breadcrumbs.push({label: intl.formatMessage({id: "textMining.breadcrumbs.unmatched"}), link: "/text-mining/unmatched"});
        }

        return <BaseBreadcrumbs breadcrumbs={breadcrumbs} />;
    }
}

export default injectIntl(Breadcrumbs, {forwardRef: true});
