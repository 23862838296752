import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import Table from "react-bootstrap/Table";
import styles from "./styles.module.scss";

export default class Education extends React.PureComponent {
    static propTypes = {
        value: PropTypes.object,
    };

    render() {
        const {value} = this.props;

        return (
            <Table size="sm" bordered>
                <tbody>
                    {(!!value.dateFrom || !!value.dateTo) && (
                        <tr>
                            <td className={styles.label}>
                                <FormattedMessage id="education.dateFrom" />
                            </td>
                            <td className={styles.halfWidth}>{value.dateFrom || "—"}</td>
                            <td className={styles.label}>
                                <FormattedMessage id="education.dateTo" />
                            </td>
                            <td className={styles.halfWidth}>{value.dateTo || "—"}</td>
                        </tr>
                    )}
                    {!!value.institute && (
                        <tr>
                            <td className={styles.label}>
                                <FormattedMessage id="education.institute" />
                            </td>
                            <td colSpan={3} className={styles.fullWidth}>
                                {value.institute}
                            </td>
                        </tr>
                    )}
                    {!!value.field && (
                        <tr>
                            <td className={styles.label}>
                                <FormattedMessage id="education.field" />
                            </td>
                            <td colSpan={3} className={styles.fullWidth}>
                                {value.field}
                            </td>
                        </tr>
                    )}
                    {!!value.degree && (
                        <tr>
                            <td className={styles.label}>
                                <FormattedMessage id="education.degree" />
                            </td>
                            <td colSpan={3} className={styles.fullWidth}>
                                {value.degree}
                            </td>
                        </tr>
                    )}
                    {!!value.info && (
                        <tr>
                            <td colSpan={4}>{value.info}</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        );
    }
}
