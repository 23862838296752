import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import styles from "./styles.module.scss";
import {FormattedMessage} from "react-intl";

export default class Description extends React.PureComponent {
    static propTypes = {
        value: PropTypes.string.isRequired,
        descriptionExplanation: PropTypes.string,
        isEditing: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isEditing: false,
    };

    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
    }

    focus() {
        this.inputRef.current.focus();
    }

    render() {
        const {value, isEditing, descriptionExplanation} = this.props;

        return (
            <React.Fragment>
                <h5 className="mt-3">
                    <FormattedMessage id="jobEditor.description" />
                </h5>
                {descriptionExplanation && (
                    <p>
                        <FormattedMessage id={descriptionExplanation} />
                    </p>
                )}
                <textarea
                    disabled={!isEditing}
                    value={value}
                    className={c("form-control", styles.description)}
                    onChange={this.handleChange}
                    ref={this.inputRef}
                />
            </React.Fragment>
        );
    }

    handleChange = e => {
        const {onChange} = this.props;
        onChange(e.target.value);
    };
}
