import React from "react";
import PropTypes from "prop-types";
import FloatInput from "~/components/FloatInput";
import styles from "./styles.module.scss";

export default class FloatEditor extends React.PureComponent {
    static propTypes = {
        schema: PropTypes.object.isRequired,
        theKey: PropTypes.any,
        isKeyEditable: PropTypes.bool,
        value: PropTypes.number,
        renderKey: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        renderKey: () => null,
    };

    render() {
        const {theKey, value, renderKey} = this.props;

        return (
            <div className={styles.editor}>
                {renderKey(theKey)}
                <div className={styles.value}>
                    <FloatInput
                        value={value}
                        onChange={this.handleChange}
                        className="form-control form-control-smallish"
                    />
                </div>
            </div>
        );
    }

    handleChange = value => {
        const {theKey, onChange} = this.props;
        onChange(theKey, value);
    };
}
