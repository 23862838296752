import React from "react";
import PropTypes from "prop-types";
import Pagination from "react-bootstrap/Pagination";
import c from "classnames";
import Item from "./Item";

export default class SimplePagination extends React.PureComponent {
    static propTypes = {
        page: PropTypes.number.isRequired,
        totalItems: PropTypes.number.isRequired,
        itemsPerPage: PropTypes.number.isRequired,
        radius: PropTypes.number.isRequired,
        className: PropTypes.string,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        radius: 4,
    };

    getTotalPages() {
        const {totalItems, itemsPerPage} = this.props;
        return Math.max(Math.ceil(totalItems / itemsPerPage), 1);
    }

    render() {
        const {className} = this.props;
        return <Pagination size="sm" className={c("mb-0", className)}>{this.renderItems()}</Pagination>;
    }

    renderItems() {
        const {page} = this.props;
        const totalPages = this.getTotalPages();
        const {start, end} = this.calculateStartAndEnd(page, totalPages);
        const items = [];

        items.push(
            <Pagination.First key="<<" onClick={this.handleFirstClick} disabled={page <= 0} />
        );
        items.push(<Pagination.Prev key="<" onClick={this.handlePrevClick} disabled={page <= 0} />);

        for (let i = start; i < end; i++) {
            items.push(<Item key={i} page={i} active={i === page} onClick={this.handleClick} />);
        }

        items.push(
            <Pagination.Next
                key=">"
                onClick={this.handleNextClick}
                disabled={page >= totalPages - 1}
            />
        );
        items.push(
            <Pagination.Last
                key=">>"
                onClick={this.handleLastClick}
                disabled={page >= totalPages - 1}
            />
        );

        return items;
    }

    calculateStartAndEnd(page, totalPages) {
        const {radius} = this.props;

        let start = page - radius;
        let end = start + 2 * radius + 1;

        if (start < 0) {
            end -= start;
            start = 0;
        }

        if (end > totalPages) {
            const diff = end - totalPages;
            end -= diff;
            start -= diff;
        }

        if (start < 0) {
            start = 0;
        }

        return {start, end};
    }

    handleClick = page => {
        this.props.onChange(page);
    };

    handleFirstClick = () => {
        const {onChange} = this.props;
        onChange(0);
    };

    handlePrevClick = () => {
        const {page, onChange} = this.props;
        onChange(Math.max(page - 1, 0));
    };

    handleNextClick = () => {
        const {page, onChange} = this.props;
        const totalPages = this.getTotalPages();
        onChange(Math.min(page + 1, totalPages));
    };

    handleLastClick = () => {
        const {onChange} = this.props;
        const totalPages = this.getTotalPages();
        onChange(Math.max(totalPages - 1, 0));
    };
}
