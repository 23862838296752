export default function addMonitoringCalls(Client) {
    Client.prototype.getFeedbackIds = function(type) {
        return this.get(`/monitoring/from/${type}/feedbackIds`).then(({response}) => response);
    };

    Client.prototype.getProfiles = function(sourceType, toType, feedbackKey, from, size) {
        return this.get(`/monitoring/from/${sourceType}/to/${toType}/key/${feedbackKey}`, {
            from,
            size,
        }).then(({response}) => response);
    };

    Client.prototype.match = function(sourceType, toType, feedbackKey, strategy) {
        return this.post(
            `/monitoring/match/${sourceType}/to/${toType}/key/${feedbackKey}`,
            undefined,
            strategy !== null ? {strategy} : undefined
        ).then(({response}) => response);
    };

    Client.prototype.getMatchingReport = function(id) {
        return this.get(`/monitoring/match/reports/${id}`).then(({response}) => response);
    };

    Client.prototype.addOrUpdateFeedback = function({type, key, matchedProfileId, decision}) {
        return this.put(`/monitoring/from/${type}/doc/${matchedProfileId}`, {
            type,
            key,
            matchedProfileId,
            decision,
        }).then(({response}) => response);
    };
}
