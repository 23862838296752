import {connect} from "react-redux";
import TextMiningInput from "~/pages/text-mining/TextMiningInput";
import {updateInput, textMiningParse} from "~/actions/text-mining";

function mapStateToProps(state) {
    return {
        input: state.textMining.input,
        showAllBreadcrumbs: state.textMining.fetching || state.textMining.results.feedback.length > 0,
    };
}

const mapDispatchToProps = {
    updateInput,
    textMiningParse,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TextMiningInput);
