import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import * as clipboardy from "clipboardy";
import c from "classnames";
import styles from "./styles.module.scss";

ConfigurationError.propTypes = {
    error: PropTypes.any,
};

export default function ConfigurationError(props) {
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => setCopied(false), 5000);
        return () => clearTimeout(timeout);
    }, [copied]);

    function copyToClipboard() {
        setCopied(true);
        clipboardy.write(displayedError(props.error));
    }

    return (
        <div className={styles.main}>
            <div>
                <h6 className="mb-4">There was an error setting up the application.</h6>
                <p className="small mb-2">Please provide the following to theMatchBox:</p>
                <div>
                    <textarea rows={10} className={c(styles.error, "mb-1")} readOnly={true} defaultValue={displayedError(props.error)}/>
                </div>
                <button className="btn btn-sm btn-secondary" onClick={copyToClipboard}>
                    {copied ? "Copied!" : "Copy to clipboard"}
                </button>
            </div>
        </div>
    );
}

function displayedError(error) {
    let actualError = error;

    if (error instanceof Error) {
        actualError = error.message;
    }

    return btoa(JSON.stringify(actualError, replaceXmlHttpRequest));
}

function replaceXmlHttpRequest(key, value) {
    if (value instanceof XMLHttpRequest) {
        const result = {};

        for (const property in value) {
            result[property] = value[property];
        }

        return result;
    } else {
        return value;
    }
}
