import uuid from "uuid/v4";
import languages from "@cospired/i18n-iso-languages";
import config from "~/config";
import objectPath from "object-path";
import {rescaleWeight} from "~/util/weights-calculations";
import {importJob} from "~/util/job";
import {importCandidate} from "~/util/candidate";
import {importProfile} from "~/util/match-profile";
import {EnabledAspectsBlackWhiteList} from "~/util/enabled-aspects";

function gatherAspects(object, aspectWeightStats, overwriteWeights, aspects, preferredLanguage) {
    for (const aspectName in config("aspects")) {
        if (!EnabledAspectsBlackWhiteList.allows(aspectName)) {
            continue;
        }

        const aspectConfig = config(`aspects.${aspectName}`);
        const aspectPath = aspectConfig.customPath || aspectName;
        const aspect = objectPath.get(object, aspectPath);

        if (aspect === undefined || aspect === null || typeof aspect !== "object") {
            continue;
        }

        let converted;

        try {
            converted = convertAspectFromApi(
                aspectConfig,
                aspect,
                aspectWeightStats,
                preferredLanguage
            );
        } catch (e) {
            console.error("Couldn't convert aspect (possibly inside extraProperties)");
            console.error(e);
            continue;
        }

        if (converted === null) {
            continue;
        }

        if (overwriteWeights) {
            converted = {...converted, weight: aspectConfig.weight};
        }

        if (aspectConfig.forceRequired === true) {
            converted.required = true;
        } else if (aspectConfig.overrideApiRequired) {
            converted.required = aspectConfig.required;
        }

        aspects[aspectName] = converted;
    }
}

export function convertJobFromApi(job) {
    const aspects = {};

    const overwriteWeights = config("ui.weight.overwrite", false);
    let aspectWeightStats = calculateAspectWeightStats(job, overwriteWeights);
    const preferredLanguage = languages.alpha2ToAlpha3B(config("ui.locale")).toUpperCase();

    gatherAspects(job, aspectWeightStats, overwriteWeights, aspects, preferredLanguage);

    return importJob({
        title: job.jobTitle,
        description: job.jobText,
        matchProfile: {
            aspects,
        },
        extraProperties: job.extraProperties,
    });
}

export function convertCandidateFromApi(candidate) {
    const aspects = {};

    const overwriteWeights = config("ui.weight.overwrite", false);
    let aspectWeightStats = calculateAspectWeightStats(candidate, overwriteWeights);
    const preferredLanguage = languages.alpha2ToAlpha3B(config("ui.locale")).toUpperCase();

    gatherAspects(candidate, aspectWeightStats, overwriteWeights, aspects, preferredLanguage);

    let description = [candidate.cvText, candidate.preferredJobTitle, candidate.preferredJobInfo]
        .filter(v => v !== null)
        .join("\n\n");

    if (description === "") {
        if (candidate.jobFunctions !== null && candidate.jobFunctions.value !== null) {
            description = candidate.jobFunctions.value
                .flatMap(concept => concept.labels)
                .join("\n");
        }
    }

    // TODO: Temporary hack
    if (
        !aspects.jobFunctions ||
        !aspects.jobFunctions.value ||
        !aspects.jobFunctions.value.length
    ) {
        if (aspects.hardSkills) {
            aspects.hardSkills.required = true;
        }
    }

    return importCandidate({
        description,
        matchProfile: {
            aspects,
        },
        workExperiences: (candidate.workExperiences || []).map(convertWorkExperience),
        educations: (candidate.educations || []).map(convertEducation),
        extraProperties: candidate.extraProperties,
    });
}

export function convertAspectFromApi(aspectConfig, aspect, aspectWeightStats, preferredLanguage) {
    switch (aspectConfig.type) {
        case "concepts":
            return convertConceptsAspect(
                aspectConfig,
                aspect,
                aspectWeightStats,
                preferredLanguage
            );

        case "keywords":
            return convertKeywordsAspect(aspectConfig, aspect, aspectWeightStats);

        case "locations":
            return convertLocationsAspect(aspectConfig, aspect, aspectWeightStats);

        case "range":
            return convertRangeAspect(aspectConfig, aspect, aspectWeightStats);

        case "integer":
        case "float":
            return convertNumberAspect(aspectConfig, aspect, aspectWeightStats);

        default:
            return null;
    }
}

function convertConceptsAspect(aspectConfig, aspect, aspectWeightStats, preferredLanguage) {
    let aspectWeight = rescaleWeight(aspect.weight, aspectWeightStats);
    return {
        type: aspectConfig.type,
        weight: aspectWeight,
        required: aspect.required,
        value: aspect.value.map(value => {
            return {
                id: uuid(),
                weight: value.weight * config("ui.weight.scale"),
                required: value.required,
                label: getPreferredLabel(value, preferredLanguage)
            };
        }),
    };
}

function getPreferredLabel(conceptValue, preferredLanguage) {
    if (conceptValue.labelsMap !== undefined) {
        let label = getFirstlabel(conceptValue.labelsMap[preferredLanguage]);
        if (label === undefined) label = getFirstlabel(conceptValue.labelsMap["UNIVERSAL"]);
        if (label === undefined) label = getFirstlabel(conceptValue.labelsMap["ENG"]);
        if (label === undefined && Object.values(conceptValue.labelsMap).length > 0) label = getFirstlabel(Object.values(conceptValue.labelsMap)[0]);
        if (label !== undefined) return label;
    }

    return conceptValue.labels ? conceptValue.labels[0] : "";
}

function getFirstlabel(labels) {
    if (!labels) {
        return undefined;
    } else if (!Array.isArray(labels)) {
        return undefined;
    } else if (labels.length === 0) {
        return undefined;
    } else {
        return labels[0];
    }
}

function convertKeywordsAspect(aspectConfig, aspect, sumOfAspectWeights) {
    if (aspect.profileValue) {
        return convertCustomKeywordsAspect(aspectConfig, aspect, sumOfAspectWeights);
    }

    let aspectWeight = rescaleWeight(aspect.weight, sumOfAspectWeights);
    return {
        type: aspectConfig.type,
        weight: aspectWeight,
        required: aspect.required,
        value: aspect.value.map(value => ({
            id: uuid(),
            weight: value.weight * config("ui.weight.scale"),
            required: value.required,
            label: value.value,
        })),
    };
}

function convertCustomKeywordsAspect(aspectConfig, aspect, sumOfAspectWeights) {
    const aspectWeight = rescaleWeight(aspect.profileWeight, sumOfAspectWeights);
    return {
        type: aspectConfig.type,
        weight: aspectWeight,
        required: aspect.profileRequirement === "REQUIRED",
        value: aspect.profileValue.map(value => ({
            id: uuid(),
            weight: value.weight * config("ui.weight.scale"),
            required: value.required,
            label: value.term,
        })),
    };
}

function convertLocationsAspect(aspectConfig, aspect, sumOfAspectWeights) {
    let aspectWeight = rescaleWeight(aspect.weight, sumOfAspectWeights);

    return {
        type: aspectConfig.type,
        weight: aspectWeight,
        required: aspect.required,
        value: aspect.value.map(value => ({
            id: uuid(),
            weight: value.weight * config("ui.weight.scale"),
            required: value.required,
            label: value.label,
            range: config("ui.overrideApiRange") ? config("ui.defaultRange") : value.range,
            rangeUnit: value.rangeUnit,
        })),
    };
}

function convertRangeAspect(aspectConfig, aspect, sumOfAspectWeights) {
    let aspectWeight = rescaleWeight(aspect.weight, sumOfAspectWeights);
    return {
        type: aspectConfig.type,
        weight: aspectWeight,
        required: aspect.required,
        value: {
            weight: aspect.value.weight * config("ui.weight.scale"),
            required: aspect.value.required,
            from: aspect.value.from,
            to: aspect.value.to,
        },
    };
}

function convertNumberAspect(aspectConfig, aspect, sumOfAspectWeights) {
    let aspectWeight = rescaleWeight(aspect.weight, sumOfAspectWeights);
    return {
        type: aspectConfig.type,
        weight: aspectWeight,
        required: aspect.required,
        value: {
            weight: aspect.value.weight * config("ui.weight.scale"),
            required: aspect.value.required,
            value: aspect.value.value,
        },
    };
}

function convertWorkExperience(workExperience) {
    return {
        id: uuid(),
        title: workExperience.title,
        company: workExperience.company,
        text: workExperience.text,
        dateFrom: workExperience.dateFrom,
        dateTo: workExperience.dateTo,
    };
}

function convertEducation(education) {
    return {
        id: uuid(),
        field: education.field,
        degree: education.degree,
        institute: education.institute,
        info: education.info,
        dateFrom: education.dateFrom,
        dateTo: education.dateTo,
    };
}

function calculateAspectWeightStats(aspects, overwriteWeights) {
    let sumOfAspectWeights = 0;
    let maxAspectWeight = 0;
    for (const name in aspects) {
        let configAspect = config(`aspects.${name}`, undefined);
        if (configAspect === undefined) {
            continue;
        }
        const aspect = aspects[name];
        if (aspect === null) {
            continue;
        }

        let weight = overwriteWeights ? configAspect.weight : aspect.weight;
        sumOfAspectWeights += weight;
        maxAspectWeight = Math.max(weight, maxAspectWeight);
    }

    return {
        sum: sumOfAspectWeights,
        max: maxAspectWeight,
    };
}

export function convertParsedQueryItemsMatchProfile(keywords) {
    let matchProfile = importProfile({aspects: {}});
    if (!keywords) return matchProfile;

    for (const item of keywords) {
        switch (item.type) {
            case "FUNCTION":
                matchProfile = addValueToAspect(matchProfile, "jobFunctions", {
                    label: item.text,
                    weight: config("ui.weight.default"),
                    required: false,
                });
                break;

            case "HARD_SKILL":
            case "COMPANY":
            case "TEXT":
                matchProfile = addValueToAspect(matchProfile, "hardSkills", {
                    label: item.text,
                    weight: config("ui.weight.default"),
                    required: false,
                });
                break;

            case "SOFT_SKILL":
                matchProfile = addValueToAspect(matchProfile, "softSkills", {
                    label: item.text,
                    weight: config("ui.weight.default"),
                    required: false,
                });
                break;

            case "LOCATION":
                matchProfile = addValueToAspect(matchProfile, "locations", {
                    label: item.text,
                    weight: config("ui.weight.default"),
                    required: false,
                    range: 10,
                    rangeUnit: "KILOMETER",
                });
                break;

            default:
                break;
        }
    }

    return matchProfile;
}

function addValueToAspect(matchProfile, aspect, value) {
    return {
        ...matchProfile,
        aspects: {
            ...matchProfile.aspects,
            [aspect]: {
                ...matchProfile.aspects[aspect],
                value: [...matchProfile.aspects[aspect].value, {...value, id: uuid()}],
            },
        },
    };
}
