import {connect} from "react-redux";
import CandidateList from "~/pages/CandidateList";
import {fetchCandidates, setPage} from "~/actions/candidates";

function mapStateToProps(state) {
    return {
        allCandidates: state.candidates.all,
        candidateOrder: state.candidates.order,
        page: state.candidates.page,
        pageSize: state.candidates.pageSize,
        count: state.candidates.count,
        isFetching: state.candidates.isFetching,
    };
}

const mapDispatchToProps = {
    fetchCandidates,
    setPage,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CandidateList);
