import React from "react";
import PropTypes from "prop-types";
import {createSelector} from "reselect";
import config from "~/config";
import Select from "~/components/Select";

export default class SortSelect extends React.PureComponent {
    static propTypes = {
        selected: PropTypes.string,
        sortModeGroup: PropTypes.string.isRequired,
        variant: PropTypes.oneOf(["normal", "small", "extra-small"]),
        className: PropTypes.string,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        variant: "normal",
    };

    render() {
        const {selected, sortModeGroup, className, variant} = this.props;
        const options = optionsSelector(config(`sort.${sortModeGroup}`));

        return (
            <Select
                selected={selected}
                options={options}
                onChange={this.handleChange}
                className={className}
                variant={variant}
            />
        );
    }

    handleChange = nextSelected => {
        this.props.onChange(nextSelected.value);
    };
}

const optionsSelector = createSelector(
    sortModes => sortModes,
    sortModes => {
        const options = sortModes.map(sortMode => ({
            value: sortMode.id,
            label: sortMode.label,
        }));

        options.unshift({
            value: null,
            label: "Sort by",
            disabled: true,
        });

        return options;
    }
);
