import React from "react";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import uuid from "uuid/v4";
import config from "~/config";
import FilterValuePropType from "~/prop-types/filter-value";
import Filter from "../Filter";
import {getValuesForFilter} from "~/util/filter-values";

class AutocompleteTermFilter extends React.PureComponent {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        value: FilterValuePropType.isRequired,
        filterName: PropTypes.string.isRequired,
        className: PropTypes.string,
        onChange: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            options: [],
            values: [],
        };

        this.id = uuid();

        getValuesForFilter(props.filterName).then(values => {
            this.setState({values: values.map(prepareItem)});
        });
    }

    render() {
        const {intl, value, filterName, className} = this.props;
        const label = config(`filters.${filterName}.label`);

        return (
            <Filter
                label={intl.formatMessage({id: "termFilter.label"}, {label})}
                value={value.enabled}
                className={className}
                onChange={this.handleEnabledChange}
            >
                {this.renderSelect()}
            </Filter>
        );
    }

    renderSelect() {
        const {value} = this.props;
        const {options} = this.state;

        return (
            <AsyncTypeahead
                id={this.id}
                selected={value.value ? [value.value] : []}
                placeholder="..."
                isLoading={false}
                options={options}
                minLength={2}
                useCache={false}
                onChange={this.handleChange}
                onInputChange={this.handleInputChange}
                onSearch={this.handleSearch}
            />
        );
    }

    handleEnabledChange = nextEnabled => {
        const {value, filterName, onChange} = this.props;

        onChange(filterName, {
            ...value,
            enabled: nextEnabled,
        });
    };

    handleChange = ([theValue]) => {
        if (theValue !== undefined) {
            const {value, filterName, onChange} = this.props;

            onChange(filterName, {
                ...value,
                enabled: true,
                value: theValue,
            });
        }
    };

    handleInputChange = theValue => {
        const {value, filterName, onChange} = this.props;

        onChange(filterName, {
            ...value,
            enabled: theValue !== "" ? value.enabled : false,
            value: theValue,
        });
    };

    handleSearch = input => {
        const {values} = this.state;
        const lowercaseInput = input.toLowerCase();

        this.setState({
            options: values
                .filter(x => x.lowercaseValue.includes(lowercaseInput))
                .sort((a, b) => b.count - a.count)
                .map(x => x.value),
        });
    };
}

function prepareItem(item) {
    return {
        ...item,
        lowercaseValue: item.value.toLowerCase(),
    };
}

export default injectIntl(AutocompleteTermFilter, {forwardRef: true});
