import objectPath from "object-path";
import stringify from "fast-json-stable-stringify";
import config from "~/config";
import Api from "~/api";

const AGGREGATION_SIZE = 10000;
const CACHE = {};
const ENUM_VALUES_CACHE = {};

export function getValuesForFilter(filterName) {
    if (CACHE[filterName] === undefined) {
        CACHE[filterName] = loadValuesForFilter(filterName);
    }

    return CACHE[filterName];
}

function loadValuesForFilter(filterName) {
    const configuredValues = config(`filters.${filterName}.values`, undefined);
    return loadEnumValues(configuredValues);
}

export function loadEnumValues(values) {
    if (values === undefined) {
        return Promise.resolve([]);
    } else if (Array.isArray(values)) {
        return Promise.resolve(values);
    }

    const key = stringify(values);

    if (ENUM_VALUES_CACHE[key] === undefined) {
        ENUM_VALUES_CACHE[key] = getLoadEnumValuesPromise(values);
    }

    return ENUM_VALUES_CACHE[key];
}

function getLoadEnumValuesPromise(values) {
    if (typeof values === "string" || values instanceof String) {
        return loadValuesFromData(values);
    } else if (values.endpoint !== undefined && values.field !== undefined) {
        return loadValuesFromApiAggregates(values);
    } else if (values.endpoint !== undefined) {
        return loadValuesFromApi(values);
    } else {
        return Promise.resolve([]);
    }
}

function loadValuesFromData(dataFilename) {
    return import(`~/data/autocomplete/${dataFilename}.json`).then(module => module.values);
}

function loadValuesFromApiAggregates(config) {
    return Api.post(config.endpoint, {
        size: 0,
        aggregations: {
            default: {
                terms: {
                    field: config.field,
                    size: AGGREGATION_SIZE,
                },
            },
        },
    })
        .then(({response}) => {
            return transformBuckets(config, response);
        })
        .catch(error => {
            console.error(error);
            return [];
        });
}

function loadValuesFromApi(config) {
    return Api.get(config.endpoint, config.queryParameters)
        .then(({response}) => {
            return response.options;
        })
        .catch(error => {
            console.error(error);
            return [];
        });
}

function transformBuckets(config, response) {
    const buckets = objectPath.get(response, "aggregations.default.buckets");

    if (buckets === undefined) {
        console.error("No buckets in response");
        return [];
    }

    const exclude = config.exclude || [];

    return buckets
        .filter(item => !exclude.includes(item.key))
        .map(item => ({
            value: item.key,
            label: item.key,
            count: item.doc_count,
        }));
}
