export default function addMatchingStrategyCalls(Client) {
    Client.prototype.getMatchingStrategyDescription = function() {
        return this.get("/hrmatching/matchingstrategies/description").then(
            ({response}) => response
        );
    };

    Client.prototype.getDefaultMatchingStrategy = function(type) {
        return this.get(`/hrmatching/matchingstrategies/defaults/${type}`).then(
            ({response}) => response
        );
    };

    Client.prototype.getMatchingStrategies = function() {
        return this.get("/hrmatching/matchingstrategies").then(({response}) => response);
    };

    Client.prototype.addOrUpdateMatchingStrategy = function(name, strategy) {
        return this.put(`/hrmatching/matchingstrategies/${encodeURIComponent(name)}`, strategy).then(
            ({response}) => response
        );
    };

    Client.prototype.deleteMatchingStrategy = function(name) {
        return this.delete(`/hrmatching/matchingstrategies/${encodeURIComponent(name)}`).then(({response}) => response);
    };
}
