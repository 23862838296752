import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage, injectIntl} from "react-intl";
import {messageExists} from "~/util/ui-translations";

class QuietFormattedMessageRaw extends React.PureComponent {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        // Rest of passed props are forwarded to FormattedMessage
    };

    render() {
        const {intl, ...forwardedProps} = this.props;

        if (forwardedProps.id && !messageExists(intl, forwardedProps.id)) {
            return forwardedProps.defaultMessage || forwardedProps.id;
        }

        return <FormattedMessage {...forwardedProps} />;
    }
}

const QuietFormattedMessage = injectIntl(QuietFormattedMessageRaw, {forwardRef: true});
FormattedMessage.Quiet = QuietFormattedMessage;
export default QuietFormattedMessage;
