import Validators from "~/config/validation/validators/Validators";
import ScoreType from "~/enums/ScoreType";
import ExpansionType from "~/enums/ExpansionType";

export default Validators.shape({
    description: "Configuration for an index used by a matching page",
    shape: {
        name: Validators.string({
            description: "Name of the index",
        }),
        label: Validators.string({
            description: "Label to display in the UI for the index",
        }),
        scoreType: Validators.enum({
            description: "Score type to use for the API call (`be.thematchbox.lucene.queries.ScoreType`)",
            enum: ScoreType,
        }),
        expansionType: Validators.enum({
            description: "Expansion type to use for the API call (`be.thematchbox.api.ontology.ExpansionType`)",
            enum: ExpansionType,
            defaultValue: undefined,
        }).allowsUndefined,
        filters: Validators.arrayOf({
            description: "Filters to display in the UI. See filters example in development variant.",
            items: Validators.string({
                description: "Key of the filters in the filters configuration",
                // TODO: Custom validation
            }),
        }),
        sortModeGroup: Validators.string({
            description: "Name of the group of sort options to display in the UI. See sort example in development variant.",
            defaultValue: undefined,
            // TODO: Custom validation to check group name
        }).allowsUndefined,
        allowSelection: Validators.boolean({
            description: "Allows the user to select interesting results for further use",
            defaultValue: false,
        }),
        // // Available statuses when making a selection
        // selectionStatuses: [],
        selectionStatusDefault: Validators.any({
            description: "Default selection status to show in the UI",
            defaultValue: null,
            // TODO: Custom validation to check if it's a valid selection status
        }).allowsNull,
        allowComparison: Validators.boolean({
            description: "Allows the user to compare candidates",
            defaultValue: false,
        }),
        allowDownload: Validators.boolean({
            description: "Allows downloading results",
            defaultValue: false,
        }),
        exportType: Validators.string({
            description: "Download export type",
            defaultValue: "csv",
        }),
        exportSize: Validators.integer({
            description: "Download export size. If undefined, the server chooses a size.",
            defaultValue: undefined,
        }).allowsUndefined,
        showMatchButton: Validators.boolean({
            description: "Show a button for each candidate to match against jobs or other candidates",
            defaultValue: false,
        }),
        // // Additional columns to display in the results table.
        // extraColumns: [
        //     /* {
        //         // Label to display in the table header.
        //         label: "Naam",
        //         // Propery of the document to display in the result row.
        //         property: "extraProperties.fullName",
        //         // CSS value for the width of the column (optional).
        //         width: "100rem",
        //     }, */
        // ],
        detailsTitleProperty: Validators.string({
            description: "The property to display as the title for a candidate's details dialog",
            defaultValue: undefined,
        }).allowsUndefined,
        comparisonSubtitleProperty: Validators.string({
            description: "The property to display above the normal title in the comparison dialog",
            defaultValue: undefined,
        }).allowsUndefined,
        // // A black/white list of extra properties to display in the result details dialog.
        // extraProperties: {},
        // // A list of extra properties that are phone numbers, currently doesn't support deep phone numbers
        // extraPropertyPhoneNumbers: [],
        handleVisitExternal: Validators.func({
            description: "If provided, a \"visit external\" button is shown. Must be a function that accepts an ID",
            defaultValue: undefined,
        }).allowsUndefined,
        // // Default custom filters value. Set to undefined to disable.
        // defaultCustomFilters: undefined,
        createNoteCallback: Validators.func({
            description: "If set, the create note dialog is available, and this callback is called with the note details. MUST return a Promise",
            defaultValue: undefined,
        }).allowsUndefined,
        // // If set, the create note dialog shows a select that allows the user to choose a note type
        // noteTypes: undefined,
        defaultNoteType: Validators.any({
            description: "If set, the create note dialog selects this type by default",
            defaultValue: undefined,
        }).allowsUndefined,
        externalDetailsTabUrlFetcher: Validators.func({
            description: "Endpoint to fetch the external URL for an entity",
            defaultValue: undefined,
        }).allowsUndefined,
        externalDetailsTabDefaultActive: Validators.boolean({
            description: "Whether the external tab should be displayed by default",
            defaultValue: false,
        }),
        apiConversionPreProcessor: Validators.func({
            description: "Receives the API object (job or candidate) and returns a modified object, which will then be converted to the UI's internal representation",
            defaultValue: undefined,
            // TODO: We might need a better system for this, currently use by the Carerix UI to handle translations of CRDataNodes
        }).allowsUndefined,
        matchInformationGetters: Validators.shape({
            description: "Getters that return specific information for match that the UI needs",
            shape: {
                companyId: Validators.func({
                    description: "Required for companiesForCandidate indication (yellow chain)",
                    defaultValue: undefined,
                }).allowsUndefined,
            }
        }),
        selectAllLimit: Validators.integer({
            description: "Allows selecting all results for a search if greater than 0, up to the specified amount of results. Maximum value is 1000.",
            defaultValue: 0,
            // TODO: Validate maximum value
        }),
        openExternalExistingSelection: Validators.func({
            description: "A function to open existing selection details externally",
            defaultValue: undefined,
        }).allowsUndefined,
    },
});
