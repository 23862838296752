import React from "react";
import PropTypes from "prop-types";
import Foco from "react-foco";
import c from "classnames";
import ListGroup from "react-bootstrap/ListGroup";
import Weight from "~/components/Weight";
import styles from "./styles.module.scss";
import Checkbox from "~/components/Checkbox";
import {FormattedMessage, injectIntl} from "react-intl";

class EnumValue extends React.PureComponent {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        value: PropTypes.shape({
            id: PropTypes.string.isRequired,
            weight: PropTypes.number.isRequired,
            required: PropTypes.bool.isRequired,
            label: PropTypes.string.isRequired,
        }).isRequired,
        placeholder: PropTypes.string.isRequired,
        isPlaceholderDisabled: PropTypes.bool.isRequired,
        enumValues: PropTypes.array.isRequired,
        enumName: PropTypes.string.isRequired,
        isEditing: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        onDelete: PropTypes.func,
        innerRef: PropTypes.object,
    };

    static defaultProps = {
        placeholder: "",
    };

    componentDidUpdate(prevProps) {
        if (prevProps.isEditing && !this.props.isEditing) {
            this.normalizeValue();
        }
    }

    componentWillUnmount() {
        this.normalizeValue();
    }

    render() {
        const {value, isEditing} = this.props;

        return (
            <ListGroup.Item className={styles.listItem}>
                <Foco
                    onFocusOutside={this.normalizeValue}
                    onClickOutside={this.normalizeValue}
                    className={styles.foco}
                    component="div"
                >
                    <Checkbox
                        checked={value.required}
                        disabled={!isEditing}
                        color="purple"
                        onChange={this.handleRequiredChange}
                    />
                    {this.renderLabel()}
                    <Weight
                        value={value.weight}
                        isEditing={isEditing}
                        color="purple"
                        className="ml-auto"
                        onChange={this.handleWeightChange}
                    />
                </Foco>
            </ListGroup.Item>
        );
    }

    renderLabel() {
        const {isEditing} = this.props;
        return isEditing ? this.renderLabelEdit() : this.renderLabelDisplay();
    }

    renderLabelDisplay() {
        const {value, enumName} = this.props;

        return (
            <span className={styles.description}>
                <FormattedMessage id={`${enumName}.${value.label}`} />
            </span>
        );
    }

    renderLabelEdit() {
        const {intl, value, placeholder, isPlaceholderDisabled, enumValues, enumName} = this.props;

        const options = [];

        // Placeholder option
        options.push(
            <option
                key="||placeholder||"
                value=""
                disabled={isPlaceholderDisabled}
                className={styles.placeholderOption}
            >
                {placeholder}
            </option>
        );

        // Enum options
        for (const enumValue of enumValues) {
            options.push(
                <option key={enumValue} value={enumValue} className={styles.enumOption}>
                    {intl.formatMessage({id: `${enumName}.${enumValue}`})}
                </option>
            );
        }

        return (
            <div className={c(styles.selectWrapper, {placeholder: value.label === ""})}>
                <select
                    value={value.label}
                    onChange={this.handleLabelChange}
                    className={c(styles.valueSelect, {placeholder: value.label === ""})}
                >
                    {options}
                </select>
            </div>
        );
    }

    handleLabelChange = e => {
        const {value, onChange, onDelete} = this.props;

        if (e.target.value !== "") {
            onChange(value.id, {...value, label: e.target.value});
        } else {
            onDelete(value.id);
        }
    };

    normalizeValue = () => {
        //
    };

    handleWeightChange = nextWeight => {
        const {value, onChange} = this.props;

        onChange(value.id, {
            ...value,
            weight: nextWeight,
        });
    };

    handleRequiredChange = nextRequired => {
        const {value, onChange} = this.props;

        onChange(value.id, {
            ...value,
            required: nextRequired,
        });
    };
}

export default injectIntl(EnumValue, {forwardRef: true})