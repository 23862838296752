import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import {renderEditorForSchema} from "~/components/SchemaJsonEditor/util";
import styles from "./styles.module.scss";

export default class SchemaJsonEditor extends React.PureComponent {
    static propTypes = {
        schema: PropTypes.object.isRequired,
        value: PropTypes.any,
        className: PropTypes.string,
        onChange: PropTypes.func.isRequired,
    };

    render() {
        const {schema, value, className} = this.props;

        return (
            <div className={c(styles.main, className)}>
                <div className="content">
                    {renderEditorForSchema(schema, {
                        value,
                        onChange: this.handleChange,
                    })}
                </div>
            </div>
        );
    }

    handleChange = (key, value) => {
        this.props.onChange(value);
    };
}
