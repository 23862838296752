import {connect} from "react-redux";
import JobList from "~/pages/JobList";
import {fetchJobs, setPage} from "~/actions/jobs";

function mapStateToProps(state) {
    return {
        allJobs: state.jobs.all,
        jobOrder: state.jobs.order,
        page: state.jobs.page,
        pageSize: state.jobs.pageSize,
        count: state.jobs.count,
        isFetching: state.jobs.isFetching,
    };
}

const mapDispatchToProps = {
    fetchJobs,
    setPage,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JobList);
