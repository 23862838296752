import {connect} from "react-redux";
import StandaloneMatchDetails from "~/pages/StandaloneMatchDetails";
import {setSourceAndTarget} from "~/actions/standalone-match-details";

function mapStateToProps(state) {
    return {
        sourceIndex: state.standaloneMatchDetails.sourceIndex,
        sourceId: state.standaloneMatchDetails.sourceId,
        targetIndex: state.standaloneMatchDetails.targetIndex,
        targetId: state.standaloneMatchDetails.targetId,
        result: state.standaloneMatchDetails.result,
        error: state.standaloneMatchDetails.error,
    };
}

const mapDispatchToProps = {
    setSourceAndTarget,
};

export default connect(mapStateToProps, mapDispatchToProps)(StandaloneMatchDetails);
