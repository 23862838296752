import {MATCH_FAILURE, MATCH_SUCCESS, START_MATCH} from "~/actions/standalone-match-details";

const INITIAL_STATE = {
    sourceIndex: undefined,
    sourceId: undefined,
    targetIndex: undefined,
    targetId: undefined,
    result: undefined,
    error: undefined,
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case START_MATCH:
            return startMatch(
                state,
                action.sourceIndex,
                action.sourceId,
                action.targetIndex,
                action.targetId
            );

        case MATCH_SUCCESS:
            return matchSuccess(state, action.result);

        case MATCH_FAILURE:
            return matchFailure(state, action.error);

        default:
            return state;
    }
}

function startMatch(state, sourceIndex, sourceId, targetIndex, targetId) {
    return {
        ...state,
        sourceIndex,
        sourceId,
        targetIndex,
        targetId,
        result: undefined,
        error: undefined,
    };
}

function matchSuccess(state, result) {
    return {
        ...state,
        result,
    };
}

function matchFailure(state, error) {
    return {
        ...state,
        error,
    };
}