import React from "react";
import PropTypes from "prop-types";
import MatchingPage from "~/components/MatchingPage";
import {
    setDefaultMatchSourceEntityConfiguration,
    setSelectionConfiguration,
    toBaseMatchingConfiguration,
} from "~/util/matching-config";
import ProfileType from "~/enums/ProfileType";

export default class ProfileToJobs extends React.PureComponent {
    static MATCHING_CONTEXT_ID = "profile-to-jobs";

    static propTypes = {
        match: PropTypes.object.isRequired, // react-router
        configuration: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        candidateMatchingStrategy: PropTypes.string,
        language: PropTypes.string.isRequired,
        updateLanguage: PropTypes.func.isRequired,
        newMatch: PropTypes.func.isRequired,
        updateMatchForIndex: PropTypes.func.isRequired,
        requestPage: PropTypes.func.isRequired,
        makeSelection: PropTypes.func.isRequired,
        setMatchSourceEntity: PropTypes.func.isRequired,
        setQuery: PropTypes.func.isRequired,
        setMatchingStrategy: PropTypes.func.isRequired,
        setNextRequest: PropTypes.func.isRequired,
        setIndexNextRequest: PropTypes.func.isRequired,
        clearNextRequest: PropTypes.func.isRequired,
        setSelection: PropTypes.func.isRequired,
        downloadMatchResults: PropTypes.func.isRequired,
        setSortMode: PropTypes.func.isRequired,
        setSelectionStatus: PropTypes.func.isRequired,
        selectAllFromApi: PropTypes.func.isRequired,
    };

    render() {
        const {
            match,
            configuration,
            state,
            language,
            updateLanguage,
            newMatch,
            updateMatchForIndex,
            requestPage,
            makeSelection,
            setMatchSourceEntity,
            setQuery,
            setMatchingStrategy,
            setNextRequest,
            setIndexNextRequest,
            clearNextRequest,
            resetNextRequest,
            setSelection,
            downloadMatchResults,
            setSortMode,
            setSelectionStatus,
            updateUiState,
            selectAllFromApi,
        } = this.props;

        return (
            <MatchingPage
                configuration={configuration}
                state={state}
                matchSourceEntityQueryParameters={match.params.id ? match.params : undefined}
                matchSourceEntityDefaultType={ProfileType.CANDIDATE}
                language={language}
                newMatch={newMatch}
                updateMatchForIndex={updateMatchForIndex}
                requestPage={requestPage}
                makeSelection={makeSelection}
                selectAllFromApi={selectAllFromApi}
                onMatchSourceEntityChange={setMatchSourceEntity}
                onLanguageChange={updateLanguage}
                onQueryChange={setQuery}
                onMatchingStrategyChange={setMatchingStrategy}
                onNextRequestChange={setNextRequest}
                onIndexNextRequestChange={setIndexNextRequest}
                onClearNextRequest={clearNextRequest}
                onResetNextRequest={resetNextRequest}
                onSelectionChange={setSelection}
                onDownload={downloadMatchResults}
                onSortModeChange={setSortMode}
                onSelectionStatusChange={setSelectionStatus}
                onUiStateUpdate={updateUiState}
                onMatchToOthers={this.handleMatchToOthers}
            />
        );
    }

    handleMatchToOthers = (type, index, id) => {
        const {history} = this.props;

        switch (type) {
            case "jobs":
                history.push(`/profile-to-jobs/job/${index}/${id}`);
                break;

            case "candidates":
                history.push(`/profile-to-candidates/job/${index}/${id}`);
                break;

            default:
                return;
        }
    };

    static getConfiguration() {
        return state => {
            const configuration = toBaseMatchingConfiguration(
                "features.profileToJobs",
                ProfileType.JOB
            );

            setDefaultMatchSourceEntityConfiguration(configuration, state);

            setSelectionConfiguration(configuration, state, ProfileToJobs.MATCHING_CONTEXT_ID);

            return configuration;
        };
    }
}
