import React from "react";
import PropTypes from "prop-types";
import BaseBreadcrumbs from "~/components/Breadcrumbs";
import {injectIntl} from "react-intl";

class JobToCandidatesBreadcrumbs extends React.PureComponent {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        furthestStep: PropTypes.number.isRequired,
    };

    render() {
        const {intl, furthestStep} = this.props;

        const breadcrumbs = [
            {
                label: intl.formatMessage({id: "jobToCandidates.breadcrumbs.input"}),
                link: "/job-to-candidates/input",
                disabled: furthestStep < 1,
            },
            {
                label: intl.formatMessage({id: "jobToCandidates.breadcrumbs.match"}),
                link: "/job-to-candidates/match",
                disabled: furthestStep < 3,
            },
        ];

        return <BaseBreadcrumbs breadcrumbs={breadcrumbs} />;
    }
}

export default injectIntl(JobToCandidatesBreadcrumbs, {forwardRef: true});
