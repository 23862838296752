import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import {renderEditorForSchema} from "~/components/SchemaJsonEditor/util";
import KeyCell from "~/components/SchemaJsonEditor/KeyCell";
import {createEmptyForSchema} from "~/util/schema";
import ExpandButton from "../ExpandButton/ExpandButton";
import styles from "./styles.module.scss";
import {injectIntl} from "react-intl";

class ObjectEditor extends React.PureComponent {
    static propTypes = {
        intl: PropTypes.object.isRequired,
        schema: PropTypes.object.isRequired,
        theKey: PropTypes.any,
        value: PropTypes.object,
        onChange: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            isExpanded: true,
        };

        this.nextReactKey = 0;
        this.propertyNameToReactKey = {};

        this.addPropertyKeyCellRef = React.createRef();
        this.newlyAddedPropertyName = undefined;
        this.newlyAddedPropertyKeyCellRef = React.createRef();
    }

    getReactKey(propertyName) {
        if (!this.propertyNameToReactKey[propertyName]) {
            this.propertyNameToReactKey[propertyName] = String(this.nextReactKey);
            this.nextReactKey =
                this.nextReactKey === Number.MAX_SAFE_INTEGER ? 0 : this.nextReactKey + 1;
        }

        return this.propertyNameToReactKey[propertyName];
    }

    propertyNameChanged(prevName, nextName) {
        this.propertyNameToReactKey[nextName] = this.getReactKey(prevName);
        delete this.propertyNameToReactKey[prevName];
    }

    propertyNameRemoved(prevName) {
        delete this.propertyNameToReactKey[prevName];
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.newlyAddedPropertyKeyCellRef.current) {
            this.newlyAddedPropertyKeyCellRef.current.focus();
            this.newlyAddedPropertyName = undefined;
        }
    }

    render() {
        const {isExpanded} = this.state;

        return (
            <React.Fragment>
                {this.renderHeader()}
                {isExpanded && (
                    <div className={styles.children}>
                        {this.renderProperties()}
                        {this.renderAdditionalProperties()}
                        {this.renderAddProperty()}
                    </div>
                )}
            </React.Fragment>
        );
    }

    renderHeader() {
        const {theKey, renderKey} = this.props;
        const {isExpanded} = this.state;

        if (theKey === undefined) {
            return null;
        }

        return (
            <div className={c(styles.editor, styles.noHover)}>
                {renderKey(
                    theKey,
                    <ExpandButton value={isExpanded} onChange={this.handleExpandedChange} />
                )}
                <div className={c(styles.value)} />
            </div>
        );
    }

    renderProperties() {
        const {schema, value} = this.props;
        const properties = schema.properties || {};
        const editors = [];

        for (const propertyName in properties) {
            const propertySchema = properties[propertyName];

            editors.push(
                renderEditorForSchema(propertySchema, {
                    key: propertyName,
                    theKey: propertyName,
                    value: value[propertyName],
                    renderKey: this.renderPropertyKey,
                    onChange: this.handlePropertyChange,
                })
            );
        }

        return editors;
    }

    renderPropertyKey = (key, childContent) => {
        return (
            <KeyCell value={key}>
                {childContent || <div className={styles.icon}>•</div>}
            </KeyCell>
        );
    };

    renderAdditionalProperties() {
        const {schema, value} = this.props;

        if (!schema.additionalProperties) {
            return null;
        }

        const normalProperties = schema.properties || {};
        const normalPropertyNames = Object.keys(normalProperties);
        const editors = [];

        for (const propertyName in value) {
            if (!value.hasOwnProperty(propertyName)) continue;
            if (normalPropertyNames.includes(propertyName)) continue;

            editors.push(
                renderEditorForSchema(schema.additionalProperties, {
                    key: this.getReactKey(propertyName),
                    theKey: propertyName,
                    value: value[propertyName],
                    renderKey: this.renderAdditionalPropertyKey,
                    onChange: this.handlePropertyChange,
                })
            );
        }

        return editors;
    }

    renderAdditionalPropertyKey = (key, childContent) => {
        const ref =
            this.newlyAddedPropertyName === key ? this.newlyAddedPropertyKeyCellRef : undefined;

        return (
            <KeyCell
                value={key}
                isEditable={true}
                onChange={this.handlePropertyKeyChange.bind(this, key)}
                ref={ref}
            >
                {childContent}
            </KeyCell>
        );
    };

    renderAddProperty() {
        const {schema, theKey, intl} = this.props;

        if (!schema.additionalProperties) {
            return null;
        }

        return (
            <div className={c(styles.editor, styles.noHover)}>
                <KeyCell
                    value=""
                    placeholder={intl.formatMessage({id: "schemaJsonEditor.objectPlaceholder"}, {parent: theKey})}
                    isEditable={true}
                    onChange={this.handleCreateAdditionalProperty}
                    ref={this.addPropertyKeyCellRef}
                >
                    <div className={styles.icon}>＋</div>
                </KeyCell>
                <div className={styles.value} />
            </div>
        );
    }

    handleExpandedChange = isExpanded => {
        this.setState({isExpanded});
    };

    handlePropertyChange = (key, value) => {
        const {theKey, value: objectValue, onChange} = this.props;
        onChange(theKey, {...objectValue, [key]: value});
    };

    handlePropertyKeyChange = (prevKey, nextKey) => {
        const {theKey, value, onChange} = this.props;

        if (value[nextKey] !== undefined) {
            return;
        }

        const nextValue = {...value};

        if (nextKey !== "") {
            nextValue[nextKey] = nextValue[prevKey];
            delete nextValue[prevKey];
            this.propertyNameChanged(prevKey, nextKey);
        } else {
            delete nextValue[prevKey];
            this.propertyNameRemoved(prevKey);
            this.addPropertyKeyCellRef.current.focus();
        }

        onChange(theKey, nextValue);
    };

    handleCreateAdditionalProperty = key => {
        const {schema, theKey, value, onChange} = this.props;
        this.newlyAddedPropertyName = key;
        onChange(theKey, {...value, [key]: createEmptyForSchema(schema.additionalProperties)});
    };
}

export default injectIntl(ObjectEditor, {forwardRef: true});