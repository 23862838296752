import React from "react";
import PropTypes from "prop-types";
import ListGroup from "react-bootstrap/ListGroup";
import styles from "./styles.module.scss";
import {FormattedMessage} from "react-intl";

export default class Suggestion extends React.PureComponent {
    static props = {
        value: PropTypes.string.isRequired,
        withLabel: PropTypes.bool.isRequired,
        onAccept: PropTypes.func.isRequired,
    };

    render() {
        const {value, withLabel} = this.props;

        return (
            <ListGroup.Item className={styles.suggestion} onClick={this.handleAccept}>
                <span title={value}>{value}</span>
                <i className="fas fa-check" />
                {withLabel && (
                    <span className="basic-label">
                        <FormattedMessage id="suggestions.suggestion" />
                    </span>
                )}
            </ListGroup.Item>
        );
    }

    handleAccept = () => {
        const {value, onAccept} = this.props;
        onAccept(value);
    };
}
