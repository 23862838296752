import {connect} from "react-redux";
import ProfileToJobs from "~/pages/ProfileToJobs";
import {updateLanguage} from "~/actions/ui";
import {actionsForMatchingContext} from "~/actions/matching";
import {getConfiguration, getState} from "~/util/matching-context";

function mapStateToProps(state) {
    return {
        language: state.ui.language,
        configuration: getConfiguration(state, ProfileToJobs.MATCHING_CONTEXT_ID),
        state: getState(state, ProfileToJobs.MATCHING_CONTEXT_ID),
    };
}

const mapDispatchToProps = {
    updateLanguage,
    ...actionsForMatchingContext(ProfileToJobs.MATCHING_CONTEXT_ID),
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileToJobs);
