import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import Button from "react-bootstrap/Button";
import styles from "./styles.module.scss";

export default class ProcessButton extends React.PureComponent {
    static propTypes = {
        label: PropTypes.any,
        processingLabel: PropTypes.any,
        processing: PropTypes.bool.isRequired,
        size: PropTypes.string,
    };

    render() {
        const {label, processingLabel, processing, className, size, ...buttonProps} = this.props;

        return (
            <div className={c(className, styles.container)}>
                <Button
                    {...buttonProps}
                    className={c(styles.button, {[styles.hidden]: processing})}
                    size={size}
                >
                    {label}
                </Button>
                <Button
                    {...buttonProps}
                    disabled={true}
                    className={c(styles.button, {[styles.hidden]: !processing})}
                    size={size}
                >
                    <span>
                        <i className="fas fa-spinner fa-pulse" />{" "}
                    </span>
                    {processingLabel !== undefined ? processingLabel : label}
                </Button>
            </div>
        );
    }
}
