import React from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import SimplePagination from "~/components/SimplePagination";
import Job from "./Job";
import styles from "./job-list.module.scss";
import SimplePage from "~/components/SimplePage";
import {FormattedMessage} from "react-intl";
import config from "~/config";

/**
 * This class expects to be remounted when the ID parameter in match changes!
 * It only loads data once.
 */
export default class JobList extends React.PureComponent {
    static propTypes = {
        allJobs: PropTypes.objectOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
                description: PropTypes.string.isRequired,
            })
        ).isRequired,
        jobOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
        page: PropTypes.number.isRequired,
        pageSize: PropTypes.number.isRequired,
        count: PropTypes.number,
        isFetching: PropTypes.bool.isRequired,
        history: PropTypes.object.isRequired,
        fetchJobs: PropTypes.func.isRequired,
        setPage: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const {count, page, pageSize, fetchJobs} = this.props;

        if (count === null) {
            fetchJobs(page, pageSize);
        }
    }

    render() {
        const {page, pageSize, count} = this.props;

        return (
            <SimplePage fullWidth>
                <Row>
                    <Col>
                        <Table size="sm" className={styles.table}>
                            <thead>
                                <tr>
                                    <th className={styles.jobFunctionColumn}>
                                        <FormattedMessage id="jobList.jobFunctionColumn" />
                                    </th>
                                    <th className={styles.hardSkillsColumn}>
                                        <FormattedMessage id="jobList.hardSkillsColumn" />
                                    </th>
                                    <th className={styles.locationsColumn}>
                                        <FormattedMessage id="jobList.locationColumn" />
                                    </th>
                                    <th className={styles.actionsColumn}/>
                                </tr>
                            </thead>
                            <tbody>{this.renderJobs()}</tbody>
                        </Table>
                        <SimplePagination
                            page={page}
                            totalItems={count || 0}
                            itemsPerPage={pageSize}
                            onChange={this.handlePageChange}
                        />
                    </Col>
                </Row>
            </SimplePage>
        );
    }

    renderJobs() {
        const {allJobs, jobOrder, pageSize} = this.props;

        const result = [];
        const jobIndex = config("features.jobList.jobIndex");

        for (let i = 0; i < pageSize; i++) {
            const job = allJobs[jobOrder[i]];

            if (job === undefined) {
                break;
            }

            if (config("ui.filterJobsDemoMode", false)) {
                if (!job.description || job.description.length < 200) {
                    continue;
                }
            }

            result.push(<Job key={`job.${job.id}`} indexName={jobIndex} job={job} />);
        }

        return result;
    }

    handlePageChange = page => {
        const {pageSize, fetchJobs, setPage} = this.props;
        fetchJobs(page * pageSize, pageSize);
        setPage(page);
    };
}
