import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import {injectIntl} from "react-intl";
import styles from "./styles.module.scss";
import BackendSelection from "~/util/BackendSelection";

class ResultAnnotation extends React.PureComponent {
    static annotationRegistry = {};

    static propTypes = {
        intl: PropTypes.object.isRequired,
        entityId: PropTypes.any.isRequired,
        companyId: PropTypes.any,
        existingSelection: PropTypes.instanceOf(BackendSelection),
        annotationKey: PropTypes.string,
        openExternalExistingSelection: PropTypes.func,
    };

    static registerAnnotation(key, getIcon) {
        ResultAnnotation.annotationRegistry[key] = getIcon;
    }

    render() {
        const {entityId, companyId, existingSelection, annotationKey, openExternalExistingSelection} = this.props;
        const selectionType = existingSelection.getSelectionType(entityId, companyId);

        return (
            <div
                title={this.getTitle(selectionType)}
                className={c({
                    [styles.hidden]: !selectionType && !annotationKey,
                    [styles.externalLink]: openExternalExistingSelection !== undefined,
                })}
                onClick={this.handleClick}
            >
                {this.getIcon(selectionType, annotationKey)}
            </div>
        );
    }

    getIcon(selectionType, annotationKey) {
        switch (selectionType) {
            case "candidatesForJob":
            case "jobsForCandidate":
                return <i className={c("fas", "fa-link", this.getColorClass(selectionType))} />

            case "candidatesForCompany":
            case "companiesForCandidate":
                return <i className={c("fas", "fa-paperclip", this.getColorClass(selectionType))}/>

            default:
                if (annotationKey !== undefined && ResultAnnotation.annotationRegistry[annotationKey] !== undefined) {
                    return ResultAnnotation.annotationRegistry[annotationKey]();
                } else {
                    return null;
                }
        }
    }

    getColorClass(selectionType) {
        switch (selectionType) {
            case "candidatesForJob":
            case "jobsForCandidate":
                return styles.colorDirectLink;

            default:
                return styles.colorIndirectLink;
        }
    }

    getTitle(selectionType) {
        const {intl} = this.props;
        return selectionType
            ? intl.formatMessage({id: `existingSelection.${selectionType}`})
            : undefined;
    }

    handleClick = e => {
        const {existingSelection, openExternalExistingSelection, entityId, companyId} = this.props;

        if (openExternalExistingSelection === undefined) {
            return;
        }

        e.stopPropagation();

        const externalDetails = existingSelection.getExternalDetails(entityId, companyId);

        if (externalDetails !== undefined) {
            openExternalExistingSelection(externalDetails);
        }
    };
}

const InjectedResultAnnotation = injectIntl(ResultAnnotation, {forwardRef: true});
InjectedResultAnnotation.registerAnnotation = ResultAnnotation.registerAnnotation;

export default InjectedResultAnnotation;
