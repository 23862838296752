import objectPath from "object-path";
import {createSelector} from "reselect";
import {
    createObjectOptionsSelector,
    createOptionsSelector,
    createSortedUniqueValueSelector,
} from "~/selectors/util";

const feedbackIdsSelector = state => state.monitoring.feedbackIds;
const allStrategiesSelector = state => state.monitoring.allStrategies;
const allProfilesSelector = state => state.monitoring.allProfiles;
const matchResultsSelector = state => state.monitoring.matchResults;
const profilesOrderSelector = state => state.monitoring.profilesOrder;

export const feedbackKeysSelector = createSortedUniqueValueSelector(feedbackIdsSelector, "key");

export const feedbackToTypesSelector = createSortedUniqueValueSelector(feedbackIdsSelector, "type");

export const feedbackKeysOptionsSelector = createOptionsSelector(
    feedbackKeysSelector,
    item => item,
    item => item
);

export const feedbackToTypesOptionsSelector = createOptionsSelector(
    feedbackToTypesSelector,
    item => item,
    item => item
);

export const strategyOptionsSelector = createObjectOptionsSelector(
    allStrategiesSelector,
    (item, key) => key,
    (item, key) => key
);

export const fullProfilesSelector = createSelector(
    allProfilesSelector,
    profilesOrderSelector,
    matchResultsSelector,
    (allProfiles, profilesOrder, matchResults) => {
        const result = {};

        for (const id in allProfiles) {
            if (!allProfiles.hasOwnProperty(id)) continue;
            result[id] = fullProfile(allProfiles[id], matchResults[id]);
        }

        return result;
    }
);

export const orderedFullProfilesSelector = createSelector(
    fullProfilesSelector,
    profilesOrderSelector,
    matchResultsSelector,
    (fullProfiles, profilesOrder) => {
        return profilesOrder
            .filter(id => fullProfiles[id] !== undefined)
            .map(id => fullProfiles[id]);
    }
);

function fullProfile(profile, matchResult) {
    if (matchResult === undefined) {
        return profile;
    }

    const mactchesById = {};
    for (const match of matchResult.matches) mactchesById[match.id] = match;

    return {
        ...profile,
        stats: {
            hits: matchResult.hits,
            falsePositives: matchResult.falsePositives,
            missed: matchResult.missed,
            precision: matchResult.precision,
            recall: matchResult.recall,
        },
        matchFeedback: profile.matchFeedback.map(feedback => ({
            ...feedback,
            score: objectPath.get(mactchesById, [feedback.matchedProfileId, "score"]),
            resultStatus: objectPath.get(mactchesById, [feedback.matchedProfileId, "resultStatus"]),
        })),
    };
}
