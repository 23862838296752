import React from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import JobPropType from "~/prop-types/job";
import JobEditor from "~/components/JobEditor";
import SimplePage from "~/components/SimplePage";
import {siblingPath} from "~/util/paths";
import JobToCandidatesBreadcrumbs from "~/containers/components/JobToCandidatesBreadcrumbs";
import styles from "./styles.module.scss";
import {FormattedMessage} from "react-intl";
import config from "~/config";

export default class JobToCandidatesInput extends React.PureComponent {
    static propTypes = {
        job: JobPropType.isRequired,
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        language: PropTypes.string.isRequired,
        updateJob: PropTypes.func.isRequired,
        parseJob: PropTypes.func.isRequired,
        setFurthestStep: PropTypes.func.isRequired,
    };

    render() {
        const {job, updateJob, language} = this.props;

        return (
            <SimplePage fullHeight={true} subMenu={() => <JobToCandidatesBreadcrumbs />}>
                <Row className="h-100">
                    <Col className={styles.mainColumn}>
                        <JobEditor
                            job={job}
                            showMatchProfileEditor={false}
                            descriptionExplanation="jobEditor.descriptionExplanation"
                            isEditing={true}
                            hideTitle={true}
                            language={language}
                            index={config("features.jobToCandidates.indices.0.name")}
                            className={styles.jobEditor}
                            onChange={updateJob}
                        />
                        <div className={styles.buttons}>
                            <button
                                className="btn btn-success"
                                type="button"
                                onClick={this.handleParseJobClick}
                            >
                                <FormattedMessage id="action.parseJob" />
                            </button>
                        </div>
                    </Col>
                </Row>
            </SimplePage>
        );
    }

    handleParseJobClick = () => {
        const {job, parseJob} = this.props;
        parseJob(job);
        this.redirectToVerification();
    };

    redirectToVerification() {
        const {match, history, setFurthestStep} = this.props;
        setFurthestStep(3);
        history.push(siblingPath(match.path, "match"));
    }
}
