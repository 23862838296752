import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import {NavLink} from "react-router-dom";
import styles from "./styles.module.scss";

export default class Breadcrumbs extends React.PureComponent {
    static propTypes = {
        breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string.isRequired,
            link: PropTypes.string.isRequired,
            disabled: PropTypes.bool,
        })).isRequired,
        className: PropTypes.string,
    };

    render() {
        const {className} = this.props;
        return <div className={c(styles.menu, className)}>{this.renderLinks()}</div>
    }

    renderLinks() {
        const {breadcrumbs} = this.props;

        return breadcrumbs.map(breadcrumb => (
            <NavLink key={breadcrumb.link} to={breadcrumb.link} className={c(styles.link, {disabled: breadcrumb.disabled})} activeClassName={styles.active}>
                {breadcrumb.label}
            </NavLink>
        ))
    }
}