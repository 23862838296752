import config from "~/config";

export function getInitialFilterState(filters) {
    const result = {};

    for (const filter of filters) {
        const defaultValue = config(`filters.${filter}.defaultValue`, undefined);

        if (defaultValue === undefined) {
            continue;
        }

        const enabled = config(`filters.${filter}.defaultEnabled`, true);
        const values = config(`filters.${filter}.values`, undefined);
        let valueToUse = defaultValue;

        if (Array.isArray(values)) {
            for (const value of values) {
                if (value.id === defaultValue) {
                    valueToUse = value.value;
                    break;
                }
            }
        }

        result[filter] = {enabled, value: valueToUse}
    }

    return result;
}
