import React from "react";
import PropTypes from "prop-types";
import JobPropType from "~/prop-types/job";
import Title from "./Title";
import Description from "./Description";
import MatchProfileEditor from "~/components/MatchProfileEditor";
import styles from "./styles.module.scss";
import {FormattedMessage} from "react-intl";

export default class JobEditor extends React.PureComponent {
    static propTypes = {
        job: JobPropType.isRequired,
        isEditing: PropTypes.bool.isRequired,
        hideTitle: PropTypes.bool.isRequired,
        language: PropTypes.string.isRequired,
        descriptionExplanation: PropTypes.string,
        index: PropTypes.string.isRequired,
        showMatchProfileEditor: PropTypes.bool.isRequired,
        className: PropTypes.string,
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isEditing: true,
        showMatchProfileEditor: true,
        hideTitle: false,
    };

    constructor(props) {
        super(props);

        this.titleRef = React.createRef();
        this.descriptionRef = React.createRef();
    }

    focusTitle() {
        this.focusRef(this.titleRef);
    }

    focusDescription() {
        this.focusRef(this.descriptionRef);
    }

    focusRef(ref) {
        if (ref.current) {
            ref.current.focus();
        }
    }

    render() {
        const {
            className,
            job,
            isEditing,
            hideTitle,
            descriptionExplanation,
            showMatchProfileEditor,
            language,
            index,
        } = this.props;

        return (
            <div className={className}>
                <div className={styles.main}>
                    <div className={styles.titleAndDescription}>
                        {!hideTitle && (
                            <Title
                                value={job.title}
                                isEditing={isEditing}
                                onChange={this.handleTitleChange}
                                ref={this.titleRef}
                            />
                        )}
                        <Description
                            value={job.description}
                            descriptionExplanation={descriptionExplanation}
                            isEditing={isEditing}
                            onChange={this.handleDescriptionChange}
                            ref={this.descriptionRef}
                        />
                    </div>
                    {showMatchProfileEditor && (
                        <div className={styles.profileEditorWrapper}>
                            <h5>
                                <FormattedMessage id="jobEditor.matchProfile" />
                            </h5>
                            <MatchProfileEditor
                                profile={job.matchProfile}
                                isEditing={isEditing}
                                language={language}
                                index={index}
                                onChange={this.handleMatchProfileChange}
                                className={styles.profileEditor}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }

    handleJobPropertyChange(property, value) {
        const {job, onChange} = this.props;
        onChange({...job, [property]: value});
    }

    handleTitleChange = title => {
        this.handleJobPropertyChange("title", title);
    };

    handleDescriptionChange = description => {
        this.handleJobPropertyChange("description", description);
    };

    handleMatchProfileChange = matchProfile => {
        this.handleJobPropertyChange("matchProfile", matchProfile);
    };
}
